import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyDataSvg from "../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import InfoSvg from "../../assets/svg/ManageData/info.svg";
import OrderCancelSvg from "../../assets/svg/order-cancel.svg";
import OrderCompletedSvg from "../../assets/svg/order-completed.svg";
import OrderPendingSvg from "../../assets/svg/order-pending.svg";
import OrderProcessingSvg from "../../assets/svg/order-processing.svg";
import SettingSvg from "../../assets/svg/setting.svg";
import { ERoleAccessControl, ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { CheckRoleAccessControlAdmin } from "../../helpers/roleAccessControl";
import { showError, showSuccess } from "../../helpers/toast";
import useToggle from "../../hooks/useToggle";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import useGetOrderAdmin from "../../services/order/hooks/useGetOrderAdmin";
import useGetOrderUser from "../../services/order/hooks/useGetOrderUser";
import { IOrderArchivedUser } from "../../services/order/interfaces/IOrder.interface";
import orderService from "../../services/order/order.service";
import { changeFilterArchive } from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import { IResponsePage } from "../../types/common";
import CommonComponents from "../Commons";
import CardManagementComp from "../Commons/CardManagement";
import { IItemData } from "../Commons/CardManagement/ICardManagement";
import LoadingUI from "../Commons/Loading";
import NoData from "../NoData";
import Eye from "../../assets/svg/eye.svg";

const ManagementOrderComp = () => {
  //! state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFilter = useSelector((state: RootState) => state.orderSlice.isFilter);
  const filterVal = useSelector(
    (state: RootState) => state.orderSlice.filterDrawer
  );
  const pageSize = useSelector(
    (state: RootState) => state.appCommonSlice.pageSize
  );
  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );
  const {
    data: dataOrderUSer,
    isLoading: loadingOrderUser,
    refetch: refetchOrderUser,
  } = useGetOrderUser(false, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    status_id: filterVal?.status,
    sort: filterVal?.sortBy,
    start_date: filterVal?.start_date,
    end_date: filterVal?.end_date,
  });

  const {
    data: dataOrderAdmin,
    isLoading: loadingOrderAdmin,
    refetch: refetchOrderAdmin,
  } = useGetOrderAdmin(false, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    status_id: filterVal?.status,
    sort: filterVal?.sortBy,
    start_date: filterVal?.start_date,
    end_date: filterVal?.end_date,
    email: filterVal?.email,
  });

  const [dataUsing, setDataUsing] =
    useState<IResponsePage<IOrderArchivedUser>>();

  const {
    open: openConfirmReject,
    shouldRender: shouldRenderConfirmReject,
    toggle: toggleConfirmReject,
  } = useToggle();

  const {
    open: openUpdateStatus,
    shouldRender: shouldRenderUpdateStatus,
    toggle: toggleUpdateStatus,
  } = useToggle();

  const {
    open: openConfirmAccept,
    shouldRender: shouldRenderUpdateConfirmAccept,
    toggle: toggleUpdateConfirmAccept,
  } = useToggle();

  const dataStatusOrder = useSelector(
    (state: RootState) => state.appCommonSlice.dataStatusOrder
  );

  const [idReject, setIdReject] = useState<number | null>(null);

  const roleAdmin = CheckRoleAccessControlAdmin();
  const [valueCheckCallApi, setValueCheckCallApi] = useState(false);
  //! function
  const ParseDataOrder = useCallback(() => {
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return [];
    }
    return dataUsing?.content_page?.map((item) => {
      return {
        id: item.id,
        imgSrc: MyDataSvg,
        title: item.name,
        des: {
          created_at: item?.created_at,
          description: item?.description,
          priority: item?.priority,
          status: item?.status,
        },
        is_completed_status: item?.is_completed_status,
        status: item?.status,
      };
    });
  }, [dataUsing]);

  const showInfo = (item: IItemData) => {
    navigate(`/order/management-order/${item.id}`, {
      state: {
        item: item,
      },
    });
  };

  const handleConfirmReject = async () => {
    try {
      let result;
      if (roleAdmin?.includes(ERoleAccessControl.admin)) {
        result = await orderService.ChangeStatusOrderByAdmin(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_REJECTED"
          )?.[0]?.id
        );
      } else {
        result = await orderService.ChangeStatusOrder(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_CANCELED"
          )?.[0]?.id
        );
      }
      if (result.status === ESttausCode.OK) {
        showSuccess("Sucessfully");
        if (roleAdmin?.includes(ERoleAccessControl.admin)) {
          refetchOrderAdmin();
          return;
        }

        refetchOrderUser();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleConfirmReject();
      setIdReject(null);
    }
  };

  const handleAcceptOrder = async () => {
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(idReject),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_ACCEPTED"
        )?.[0]?.id,
        {
          note: null,
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess("Successfully");

        if (roleAdmin?.includes(ERoleAccessControl.admin)) {
          refetchOrderAdmin();
          return;
        }
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleUpdateConfirmAccept();
      setIdReject(null);
    }
  };
  //! effect
  useEffect(() => {
    if (valueCheckCallApi) {
      if (roleAdmin?.includes("admin")) {
        refetchOrderAdmin();
        return;
      }
      if (roleAdmin?.includes("user")) {
        refetchOrderUser();
      }
    }
  }, [roleAdmin, pageNumber, JSON.stringify(filterVal), valueCheckCallApi]);
  useEffect(() => {
    //! to check call api once time when navigate => because use both state redux filter
    if (!filterVal?.status) {
      setValueCheckCallApi(true);
    }
  }, [filterVal]);

  useEffect(() => {
    if (dataOrderAdmin || dataOrderUSer) {
      setDataUsing(dataOrderAdmin?.data ?? dataOrderUSer?.data);
    }
  }, [loadingOrderUser, loadingOrderAdmin, dataOrderAdmin, dataOrderUSer]);

  useEffect(() => {
    return () => {
      dispatch(
        // @ts-ignore
        changeFilterArchive({
          status: null,
        })
      );
    };
  }, []);
  //! render
  const renderItemOrder = useCallback(() => {
    if (loadingOrderUser || loadingOrderAdmin) {
      return <LoadingUI />;
    }
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return <NoData />;
    }
    return ParseDataOrder()?.map((item: any, index) => {
      const time = moment(item?.des?.created_at).format("DD/MM/YYYY HH:mm");
      const dataItem = {
        ...item,
        des: (
          <div className="flex gap-1">
            <span
              className="w-fit"
              style={{ color: item?.des?.status?.description }}
            >
              {item?.des?.status?.value}
            </span>

            {item?.des?.created_at && (
              <span className="before:content-['|'] w-fit">{` ${time}`}</span>
            )}
            {item?.des?.priority && (
              <div className="before:content-['|'] w-fit">
                <span
                  style={{
                    color: item?.des?.priority?.description,
                  }}
                >
                  {` ${item?.des?.priority.value}`}
                </span>
              </div>
            )}
            {item?.des?.description && (
              <span
                className="before:content-['|'] flex-1 text-one-line"
                title={item?.des?.description}
              >
                {` ${item?.des?.description}`}
              </span>
            )}
          </div>
        ),
        // imgSrc:
        //   item?.status === "PROCESSING"
        //     ? OrderProcessingSvg
        //     : item?.status === "CANCELED" || item?.status === "REJECTED"
        //     ? OrderCancelSvg
        //     : item?.status === "COMPLETED"
        //     ? OrderCompletedSvg
        //     : OrderPendingSvg,
      };

      return (
        <CardManagementComp
          key={index}
          data={dataItem}
          actions={[
            item?.status?.value === "CLOSED" && (
              <CommonComponents.TooltipUI title="Result Order">
                <div>
                  <img
                    onClick={(event: any) => {
                      event?.stopPropagation();
                      navigate(`/resultOrder/${item?.id}`);
                    }}
                    src={Eye}
                    alt="eye"
                  />
                </div>
              </CommonComponents.TooltipUI>
            ),
            <CommonComponents.TooltipUI title="Information">
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  navigate(`./${item?.id}`);
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
          onClick={() => {
            showInfo(item);
          }}
        />
      );
    });
  }, [dataUsing, loadingOrderAdmin, loadingOrderUser]);

  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {renderItemOrder()}
      </div>

      {shouldRenderConfirmReject && (
        <CommonComponents.ModalUI
          toggle={toggleConfirmReject}
          title="Confirm"
          open={openConfirmReject}
          handleConfirm={handleConfirmReject}
        >
          <p>Are you sure you want to reject this order?</p>
        </CommonComponents.ModalUI>
      )}

      {shouldRenderUpdateConfirmAccept && (
        <CommonComponents.ModalUI
          toggle={toggleUpdateConfirmAccept}
          title="Accept Order"
          open={openConfirmAccept}
          handleConfirm={handleAcceptOrder}
          // hiddenFooter={true}
        >
          <p>Are you sure you want to accept this order?</p>
        </CommonComponents.ModalUI>
      )}

      {shouldRenderUpdateStatus && (
        <CommonComponents.ModalUI
          toggle={toggleUpdateStatus}
          title="Confirm"
          open={openUpdateStatus}
          handleConfirm={handleConfirmReject}
          hiddenFooter={true}
        >
          <p>Note</p>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default ManagementOrderComp;
