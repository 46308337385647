import dayjs from "dayjs";
import { LocalStorageKey } from "../constants/localStorageKeys";
import { SearchType } from "../enums";
import { IProviderConfig } from "../services/providerConfigs/providerConfigInterface";
import { ISearchImageFilter } from "../services/searchImages/searchImageFilterInterface";
import { StaticConfig } from "../types/common";
import { useDispatch } from "react-redux";
import {
  setGroups,
  setPublicConfig,
  setWhoAmI,
} from "../store/reducers/appCommonSlice";
import { toast } from "react-toastify";
import axios from "axios";

export const loadStaticConfigs = async (props?: any) => {
  const data = await fetch("/config.json");
  const jsonObject: any = await data.json();

  props?.dispatch(setPublicConfig(jsonObject));

  if (!jsonObject) {
    return;
  }

  axios
    .get(`${jsonObject?.REACT_APP_API_KARATOS}/o/sessions/whoami`, {
      // @ts-ignore
      withCredentials: !jsonObject?.REACT_APP_SKIP_CREDENTIALS ?? true,
    })
    .then((data) => {
      props?.dispatch(setWhoAmI(data.data));
    });
  axios
    .get(`${jsonObject?.REACT_APP_API_BASE_URL}/api/v1/groups`)
    .then((data) => {
      props?.dispatch(setGroups(data.data ?? []));
    });

  // props?.dispatch(setGroups([]));

  localStorage.setItem(
    LocalStorageKey.STATIC_CONFIG,
    JSON.stringify(jsonObject)
  );

  return jsonObject;
};

export const getStaticConfigs = async () => {
  const jsonString = localStorage.getItem(LocalStorageKey.STATIC_CONFIG);

  if (!jsonString) {
    return await loadStaticConfigs();
  }

  try {
    return JSON.parse(jsonString) as StaticConfig;
  } catch (error) {}

  return;
};

export const getApiUrl = async (url: string) => {
  const staticConfigs = await getStaticConfigs();
  const _url = (staticConfigs?.REACT_APP_API_BASE_URL ?? "") + url;

  return _url;
};

export const getProviderByType = (
  type: SearchType,
  providers: IProviderConfig[]
) => {
  return providers.find((p) => {
    return p.name?.toLocaleLowerCase() === type.toLocaleLowerCase();
  });
};

export const updateSearchImageFilter = (
  filter: ISearchImageFilter,
  property: string,
  value: any | undefined
) => {
  const _filter = JSON.parse(JSON.stringify(filter)) as ISearchImageFilter;
  if (property === "collection") {
    if (value?.length < 1) {
      return _filter;
    }

    let args = [
      {
        property: property,
      },
    ];
    value.map((item: any) => {
      args.push(item);
    });
    _filter.filter?.args?.push({
      args,
    });
    return _filter;
  }
  // find object
  const _arg_idx =
    _filter.filter?.args?.findIndex((arg) => {
      return (
        arg.args?.length &&
        arg.args.length >= 2 &&
        arg.args[0].property === property
      );
    }) ?? -1;

  // remove if value is undefined
  if (_arg_idx > -1 && !value) {
    _filter.filter?.args?.splice(_arg_idx, 1);
    return _filter;
  }

  // do nothing if value is undefined
  if (_arg_idx === -1 && !value) {
    return _filter;
  }

  const _arg = _filter.filter?.args
    ? _filter.filter?.args[_arg_idx]
    : undefined;

  // update value if alread existed
  if (_arg?.args?.length && _arg.args.length >= 2) {
    _arg.args[1] = value;
    return _filter;
  }

  // else create new one
  if (!_filter.filter) {
    _filter.filter = {
      args: [],
    };
  }

  if (!_filter.filter.args) {
    _filter.filter.args = [];
  }

  // push new value
  _filter.filter.args.push({
    args: [
      {
        property: property,
      },
      value,
    ],
  });
  return _filter;
};

export const ConvertTimeStamp = (time: string, format = "DD/MM/YYYY HH:mm") => {
  const result = time ? dayjs(time) : "";

  if (!!result && result.format(format) !== "Invalid Date") {
    return result.format(format);
  }
  return "";
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return str; // Kiểm tra chuỗi rỗng hoặc null
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getErrorMsg = (error: any) => {
  if (error?.response?.data?.error?.message) {
    return error.response.data.error.message;
  }
  if (error?.response?.data?.[0]) {
    return error?.response?.data?.[0];
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  }

  return `Something wrong!`;
};

export const ZoomLens = (): void => {
  const magnifierLens: HTMLDivElement = document.createElement("div");
  magnifierLens.classList.add("magnifier-lens");
  document.body.appendChild(magnifierLens);

  function setupMagnifier(imageElement: HTMLImageElement | null) {
    if (!imageElement) return;

    let magnificationFactor = 3;
    let magnifierSize = 200;

    magnifierLens.style.cssText = `
      background-repeat: no-repeat;
      pointer-events: none;
      position: absolute;
      border: 1px solid #000;
      border-radius: 50%;
      width: ${magnifierSize}px;
      height: ${magnifierSize}px;
      visibility: hidden;
      box-shadow: 0 0 5px #000;
      cursor: crosshair;
    `;

    function updateMagnifierSize(deltaY: number) {
      magnifierSize += deltaY > 0 ? -20 : 20;
      magnifierSize = Math.max(100, Math.min(magnifierSize, 400));
      magnificationFactor = 2 + ((magnifierSize - 100) * (5 - 2)) / (400 - 100);

      magnifierLens.style.width = `${magnifierSize}px`;
      magnifierLens.style.height = `${magnifierSize}px`;

      updateLensMagnification();
    }

    function updateLensMagnification() {
      if (!imageElement) return;

      const bounds = imageElement.getBoundingClientRect();
      const bgPosX =
        -(magnifierLens.offsetLeft - bounds.left) * magnificationFactor -
        magnifierLens.offsetWidth / 2;
      const bgPosY =
        -(magnifierLens.offsetTop - bounds.top) * magnificationFactor -
        magnifierLens.offsetHeight / 2;

      magnifierLens.style.backgroundSize = `${
        imageElement.width * magnificationFactor
      }px ${imageElement.height * magnificationFactor}px`;
      magnifierLens.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;

      // Trigger reflow
      // magnifierLens.offsetHeight;
    }

    imageElement.addEventListener("mousemove", (e: MouseEvent) => {
      magnifierLens.style.visibility = "visible";
      const bounds = imageElement.getBoundingClientRect();
      const mouseX = e.clientX - bounds.left;
      const mouseY = e.clientY - bounds.top;

      const lensX = mouseX - magnifierLens.offsetWidth / 2;
      const lensY = mouseY - magnifierLens.offsetHeight / 2;

      magnifierLens.style.left = `${
        bounds.left + window.pageXOffset + lensX
      }px`;
      magnifierLens.style.top = `${bounds.top + window.pageYOffset + lensY}px`;

      const bgPosX = -(
        mouseX * magnificationFactor -
        magnifierLens.offsetWidth / 2
      );
      const bgPosY = -(
        mouseY * magnificationFactor -
        magnifierLens.offsetHeight / 2
      );

      magnifierLens.style.backgroundImage = `url('${imageElement.src}')`;
      magnifierLens.style.backgroundSize = `${
        imageElement.width * magnificationFactor
      }px ${imageElement.height * magnificationFactor}px`;
      magnifierLens.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;

      imageElement.style.cursor = "none";
    });

    imageElement.addEventListener("wheel", (e: WheelEvent) => {
      e.preventDefault();
      updateMagnifierSize(e.deltaY);
    });

    imageElement.addEventListener("mouseleave", () => {
      magnifierLens.style.visibility = "hidden";
      imageElement.style.cursor = "default";
    });
  }

  const imageElement = document.getElementById(
    "magnifiable-image"
  ) as HTMLImageElement | null;
  if (!imageElement) {
    return;
  }
  setupMagnifier(imageElement);
};
