import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { SearchType } from "../../enums";
import { search } from "../../services/mapServices";
import { IBaseMapSelected } from "../../services/basemap/interfaces/IBasemap.interface";

interface IMapState {
  sourceId: string;
  maplibreGL: any;
  draw: any;
  searchResultTotal?: any;
  typeSearch: string;
  searchResults?: any;
  filterSource: {
    planet: {};
    capella: {};
    oneatlas: {};
  };
  selectedImage: any; //use for show info,
  activeFeature: any;
  features: any;
  sourceData?: any;
  commonFilter?: any;
  selected?: any; // ddeer lam gi cung chua nho ra
  flagFilter?: any; // flagFilter cua Cuong de check filter tren header
  clickFilter?: any;
  idSearch?: string;
  maplibreGLConfirmOrder?: any; //
  baseMapSelected?: Partial<IBaseMapSelected>;
}

const initialState: IMapState = {
  sourceId: uuidv4(),
  draw: undefined,
  maplibreGL: undefined,
  maplibreGLConfirmOrder: undefined,
  searchResultTotal: {
    planet: 0,
    oneAtlas: 0,
    capella: 0,
    geohub: 0,
  },

  searchResults: {
    planet: [],
    oneAtlas: [],
    capella: [],
    geohub: [],
  },

  filterSource: {
    planet: {},
    capella: {},
    oneatlas: {},
  },

  typeSearch: SearchType.PLANET,
  selectedImage: {},
  activeFeature: null,
  features: [],
  sourceData: {
    type: "FeatureCollection",
    features: [],
  },

  commonFilter: {
    sources: {},
    resolution: [0, 100],
    incidenceAngle: [0, 100],
    cloudCover: [0, 100],
    timeFrom: undefined,
    timeTo: undefined,
  },
  selected: [],
  flagFilter: false,
  clickFilter: false,
  idSearch: "",

  baseMapSelected: {
    url: "https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}",
  },
};

export const searchAsync = createAsyncThunk("map/search", async (payload) => {
  try {
    const response = await search(payload);
    return response;
  } catch (e) {
    return {};
  }
});

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setDraw: (state, action) => {
      state.draw = action?.payload;
    },

    changeMaplibreGLConfirmOrder: (state, action) => {
      state.maplibreGLConfirmOrder = action?.payload;
    },

    changeMaplibreGL: (state, action) => {
      state.maplibreGL = action?.payload;
    },

    changeSearchResultTotal: (state, action) => {
      state.searchResultTotal = action?.payload;
    },

    changeTypeSearch: (state, action) => {
      state.typeSearch = action?.payload;
    },

    changeSearchResults: (state, action) => {
      state.searchResults = action?.payload;
    },
    setFilterSource: (state, action) => {
      state.filterSource = action.payload;
    },

    changeSelectedImage: (state, action) => {
      state.selectedImage = action?.payload;
    },

    addFeature: (state, action) => {
      let a = state.features;
      // @ts-ignore
      state.features.push({
        ...action?.payload,
        name: "Polygon " + (a.length + 1),
      });
    },
    updateFeature: (state, action) => {
      const features: Object[] = state.features;
      // @ts-ignore
      let index = features?.findIndex((x) => x["id"] == action?.payload?.id);
      // @ts-ignore
      state.features[index] = {
        ...action?.payload,
        name: state.features[index]?.name,
      };
      // @ts-ignore
      if (state.activeFeature && state.activeFeature?.id == action?.payload?.id)
        state.activeFeature = {
          ...action?.payload,
          name: state.features[index]?.name,
        };
    },
    removeFeature: (state, action) => {
      const features: Object[] = state.features;
      // @ts-ignore
      let index = features?.findIndex((x) => x?.id == action?.payload?.id);
      if (index >= 0) state.features.splice(index, 1);
    },
    clearFeatures: (state) => {
      state.features = [];
      state.activeFeature = null;
    },

    changeActiveFeature: (state, action) => {
      let a = state.features;
      state.activeFeature = {
        ...action.payload,
        name: action.payload?.name ?? "Polygon " + a.length,
      };
    },

    setSourceData: (state, action) => {
      state.sourceData = action.payload;
    },

    setCommonFilter: (state, action) => {
      state.commonFilter = action.payload;
    },

    setSelected: (state, action) => {
      state.selected = action.payload;
    },

    setFlagFilter: (state, action) => {
      state.flagFilter = action.payload;
    },
    setClickFilter: (state, action) => {
      state.clickFilter = action.payload;
    },

    setSearchID: (state, action) => {
      state.idSearch = action.payload;
    },

    setBasemapSelected: (state, action) => {
      state.baseMapSelected = action.payload;
    },
  },
});

export const {
  setDraw,
  changeMaplibreGL,
  changeSearchResultTotal,
  changeTypeSearch,
  changeSearchResults,
  setFilterSource,
  changeSelectedImage,

  addFeature,
  updateFeature,
  removeFeature,
  clearFeatures,
  changeActiveFeature,

  setSelected,
  setSourceData,
  setCommonFilter,
  setFlagFilter,
  setClickFilter,
  setSearchID,
  setBasemapSelected,

  changeMaplibreGLConfirmOrder,
} = mapSlice.actions;

export default mapSlice.reducer;
