import React, { useEffect, useState } from "react";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import CommonComponents from "../../../components/Commons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import { Form, Steps } from "antd";
import CheckInfoOrderComp from "../../../components/ManageOrder/AdminOrderToProvider/CheckInfoOrder";
import {
  getOrderDetailByAdminAsync,
  getTaskingSchemaAsync,
  postNotiOrderAsync,
  sendPriceToUserAsync,
  sendToProviderAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import useToggle from "../../../hooks/useToggle";
import { showError, showSuccess } from "../../../helpers/toast";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import DeliveryComp from "../../../components/ManageOrder/AdminOrderToProvider/Delivery";
import {
  convertDynamicData,
  convertToNestedFormat,
} from "../../../components/ManageOrder/AdminOrderToProvider/ultis";
import ConfirmOrderComp from "../../../components/ManageOrder/AdminOrderToProvider/ConfirmOrder";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const AdminOrderToProviderPage = () => {
  const navigate = useNavigate();
  const { idOrder, idDetailOrder } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();

  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  const dataStatusOrder = useSelector(
    (state: RootState) => state.appCommonSlice.dataStatusOrder
  );

  const [policy, setPolicy] = useState(true);

  const [current, setCurrent] = useState(0);
  const [arrAOI, setAOI] = useState<any>([]);
  const [dataCheckInfo, setDataCheckInfo] = useState<any>(undefined);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [schemaDelivery, setSchemaDelivery] = useState(undefined);
  // "wait" | "process" | "finish" | "error"
  const [statusStep, setStatusStep] = useState({
    step1: undefined,
    step2: undefined,
    step3: undefined,
  });

  const [fieldRequireStep2, setFieldRequireStep2] = useState<any>([]);

  const {
    open: openPolicy,
    toggle: togglePolicy,
    shouldRender: shouldRenderPolicy,
  } = useToggle();

  const {
    open: openPolicySend,
    toggle: togglePolicySend,
    shouldRender: shouldRenderPolicySend,
  } = useToggle();

  const onChange = (value: number) => {
    setCurrent(value);
  };
  const handleStep = (type: "next" | "prev") => {
    if (type === "next") {
      setCurrent(current + 1);
    } else {
      setCurrent(current - 1);
    }
  };

  const getData = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getOrderDetailByAdminAsync({ idDetailOrder: idDetailOrder })
    ).unwrap();

    setAOI(res?.data?.data?.images ?? []);

    setDataCheckInfo(res?.data?.data);

    if (location?.state?.item?.provider?.name?.toLowerCase() === "oneatlas") {
      // @ts-ignore
      let resDelivery = await dispatch(
        // @ts-ignore
        getTaskingSchemaAsync({
          idProvider: res?.data?.data?.provider?.id,
          type: "ORDER_ARCHIVE_ONEATLAS_NOTIFICATIONS",
        })
      ).unwrap();

      setSchemaDelivery(resDelivery?.data?.data?.ui_schema);

      if (res?.data?.data?.is_submited) {
        setCurrent(2);
        form.setFieldsValue({
          properties: {
            // @ts-ignore
            ...convertToNestedFormat(res?.data?.data?.request_order),
          },
          price: res?.data?.data?.actual_price?.total_price,
          unit: res?.data?.data?.actual_price?.unit,
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      form.submit();
      const status2 = fieldRequireStep2?.every((item: any) => !!item);
      if (!status2) {
        setStatusStep((prev: any) => ({
          ...prev,
          step2: status2 ? "finish" : "error",
        }));
        showError("Please fill in all required fields in Step 2");
        return;
      }
      let values = form.getFieldsValue();
      let dataNoti = convertDynamicData(values?.properties);

      // @ts-ignore
      const res = await dispatch(
        // @ts-ignore
        postNotiOrderAsync({
          idDetailOrder: idDetailOrder,
          ...dataNoti,
        })
      ).unwrap();

      if (
        res?.status === ESttausCode.OK ||
        res?.status === ESttausCode.CREATED
      ) {
        showSuccess("Order successfully");

        // getData();
        // setCurrent(2);

        return navigate(`/order/management-order/${idOrder}`);
      } else {
        showError(res?.message ?? "Order failed");
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      togglePolicy();
      setLoadingSubmit(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoadingSubmit(true);
      form.submit();
      let values = form.getFieldsValue();
      const status2 = fieldRequireStep2?.every((item: any) => !!item);
      if (!status2) {
        setStatusStep((prev: any) => ({
          ...prev,
          step2: status2 ? "finish" : "error",
        }));
        showError("Please fill in all required fields in Step 2");
        return;
      }

      //  let dataNoti = convertDynamicData(values?.properties);

      // @ts-ignore
      // const res = await dispatch(
      //   // @ts-ignore
      //   sendPriceToUserAsync({
      //     idDetailOrder: idDetailOrder,
      //     actualUnit: values?.unit,
      //     actualMetadataPrice: "string",
      //     actual_total_price: Number(values?.price),
      //   })
      // ).unwrap();
      // @ts-ignore
      const resSendProvider = await dispatch(
        // @ts-ignore
        sendToProviderAsync({
          idDetailOrder: idDetailOrder,
          // idStatus: dataStatusOrder?.filter(
          //   (itemF: any) => itemF?.value === "COMPLETED"
          // )?.[0]?.id,
        })
      ).unwrap();

      if (
        // (res?.status === ESttausCode.OK ||
        //   res?.status === ESttausCode.CREATED) &&
        resSendProvider?.status === ESttausCode.OK ||
        resSendProvider?.status === ESttausCode.CREATED
      ) {
        showSuccess("Order successfully");

        getData();
        setCurrent(0);
      } else {
        showError(resSendProvider?.message ?? "Order failed");
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      togglePolicySend();
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    dispatch(changeLayoutName("/AdminOrderToProviderPage"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: "Existing Image Orders",
          path: "order/management-order",
        },
        {
          title: `${location?.state?.data?.name}`,
          path: `${location?.state?.data?.id}`,
        },
        {
          title: `Send to provider ${location?.state?.item?.provider?.name}`,
        },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    getData();
  }, [idDetailOrder]);

  useEffect(() => {
    if (!!statusStep.step2) {
      const status2 = fieldRequireStep2?.every((item: any) => !!item);
      setStatusStep((prev: any) => ({
        ...prev,
        step2: status2 ? "finish" : "error",
      }));
    }
  }, [fieldRequireStep2]);

  return (
    <ContentLayoutManagement
      header={
        <div className="flex gap-2 justify-end mr-6">
          <CommonComponents.ButtonUI
            commonType="standard"
            className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
            onClick={() => navigate("/order/management-order")}
          >
            Cancel
          </CommonComponents.ButtonUI>
          {!dataCheckInfo?.is_submited && (
            <CommonComponents.ButtonUI
              className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
              onClick={() => togglePolicy()}
            >
              Send request quotation
            </CommonComponents.ButtonUI>
          )}

          {dataCheckInfo?.is_submited && !dataCheckInfo?.is_sent && (
            <CommonComponents.ButtonUI
              className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
              onClick={() => togglePolicySend()}
              disabled={!form.getFieldValue("price")}
            >
              Send
            </CommonComponents.ButtonUI>
          )}

          {!dataCheckInfo?.is_image_download_mode_auto && // is_image_download_mode_auto là để check result hãng trả về là link get qua api hay là link file dowload
            dataCheckInfo?.is_sent && (
              <CommonComponents.ButtonUI
                // commonType="standard"
                className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
                onClick={() => {
                  navigate(
                    `/order/management-order/${idOrder}/${idDetailOrder}/upload-data`,
                    {
                      state: location.state,
                    }
                  );
                }}
              >
                Upload data
              </CommonComponents.ButtonUI>
            )}
        </div>
      }
    >
      <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
        <div
          className="w-2/3 div-info-confirm-order text-white h-full  pr-2 spin-order flex"
          style={{ minHeight: "calc(100vh - 56px - 56px - 90px)" }}
        >
          <div className="flex flex-col w-full">
            <span className="mb-8">
              Status:{" "}
              <span style={{ color: dataCheckInfo?.status?.description }}>
                {dataCheckInfo?.status?.value}
              </span>
            </span>
            <div className="flex items-center gap-3 mb-8">
              <CommonComponents.ButtonUI
                commonType="standard"
                className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
                icon={<ArrowLeftOutlined />}
                iconPosition="start"
                disabled={current === 0}
                onClick={() => {
                  handleStep("prev");
                }}
              ></CommonComponents.ButtonUI>
              <Steps
                current={current}
                onChange={onChange}
                items={
                  location?.state?.item?.provider?.name?.toLowerCase() ===
                  "oneatlas"
                    ? [
                        {
                          title: "Production",
                        },
                        {
                          title: "Delivery",
                          status: statusStep.step2,
                        },
                        {
                          title: "Confirm",
                        },
                      ]
                    : [
                        {
                          title: "Delivery",
                        },
                        {
                          title: "Confirm",
                        },
                      ]
                }
              />
              <CommonComponents.ButtonUI
                commonType="standard"
                className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                disabled={
                  location?.state?.item?.provider?.name?.toLowerCase() ===
                  "oneatlas"
                    ? current >= 2
                    : current >= 1
                }
                onClick={() => {
                  handleStep("next");
                }}
              ></CommonComponents.ButtonUI>
            </div>
            <div className="overflow-y-scroll mt-8">
              <Form form={form}>
                <CheckInfoOrderComp
                  style={{
                    display: current === 0 ? "initial" : "none",
                  }}
                  data={dataCheckInfo}
                  form={form}
                  arrAOI={arrAOI}
                  setAOI={(val: any) => {
                    setAOI(val);
                  }}
                  map={map}
                  sourceId={sourceId}
                />

                <DeliveryComp
                  style={{
                    display: current === 1 ? "initial" : "none",
                  }}
                  provider={location?.state?.item?.provider}
                  schema={schemaDelivery}
                  data={dataCheckInfo?.request_order}
                  setFieldRequireStep2={setFieldRequireStep2}
                  form={form}
                  isSubmited={dataCheckInfo?.is_submited}
                />

                <ConfirmOrderComp
                  style={{
                    display: current === 2 ? "initial" : "none",
                  }}
                  data={dataCheckInfo}
                  map={map}
                  sourceId={sourceId}
                  arrAOI={arrAOI}
                  setAOI={(val: any) => {
                    setAOI(val);
                  }}
                  form={form}
                />
              </Form>
            </div>
          </div>
        </div>

        <div className="w-1/3 ">
          <MapTaskingPlugin
            showOnConfirm={true}
            activePolygon={{}}
            type="map-info-archive"
            arrPolygon={arrAOI}
          />
        </div>
      </div>

      {shouldRenderPolicy && (
        <CommonComponents.ModalUI
          open={openPolicy}
          toggle={togglePolicy}
          title="Privacy Policy & Term"
          handleConfirm={handleSubmit}
          disabled={!policy}
          loading={loadingSubmit}
        >
          <div>
            <CommonComponents.CheckboxUI
              checked={policy}
              onChange={() => {
                setPolicy(!policy);
              }}
              className="text-white"
            >
              <span>
                Check here to indicate you have read and agree to the{" "}
              </span>
              <Link
                to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                target={"_blank"}
                className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
              >
                Terms and Conditions of Geohub
              </Link>
            </CommonComponents.CheckboxUI>
          </div>
        </CommonComponents.ModalUI>
      )}

      {shouldRenderPolicySend && (
        <CommonComponents.ModalUI
          open={openPolicySend}
          toggle={togglePolicySend}
          title="Privacy Policy & Term"
          handleConfirm={handleSend}
          disabled={!policy}
          loading={loadingSubmit}
        >
          <div>
            <CommonComponents.CheckboxUI
              checked={policy}
              onChange={() => {
                setPolicy(!policy);
              }}
              className="text-white"
            >
              <span>
                Check here to indicate you have read and agree to the{" "}
              </span>
              <Link
                to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                target={"_blank"}
                className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
              >
                Terms and Conditions of Geohub
              </Link>
            </CommonComponents.CheckboxUI>
          </div>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default AdminOrderToProviderPage;
