export const antdTheme = {
  token: {},

  components: {
    Input: {
      colorBgContainer: "#23293A",
      colorBorder: "#585F77",
      colorTextPlaceholder: "#4F5569",
      colorBgContainerDisabled: "#2C3243",
      colorText: "#ffffff",
      activeBg: "#23293A",
      activeBorderColor: "#60CDFF",
      hoverBorderColor: "#585F77",
      colorTextDisabled: "#ffffff",
    },

    Dropdown: {
      colorBgElevated: "#1A2032",
      colorText: "#ffffff",
      controlItemBgHover: "#272D3C",
    },

    TextArea: {},

    DatePicker: {
      colorBgContainer: "#23293A",
      colorBorder: "#585F77",
      colorTextPlaceholder: "#4F5569",
      colorBgContainerDisabled: "#2C3243",
      colorText: "#ffffff",
      activeBg: "#23293A",
      activeBorderColor: "#60CDFF",
      hoverBorderColor: "#585F77",
      colorBgElevated: "#23293A",
      colorTextHeading: "#ffffff",
      colorTextDisabled: "#4F5569",
      colorIcon: "#4F5569",
      colorIconHover: "#ffffff",
    },

    Select: {
      colorBgContainer: "#353A4B",
      colorBorder: "",
      colorTextPlaceholder: "#4F5569",
      colorBgContainerDisabled: "#2C3243",
      colorText: "#ffffff",
      activeBg: "#23293A",
      activeBorderColor: "#60CDFF",
      hoverBorderColor: "#585F77",
      colorBgElevated: "#1A2032",
      optionActiveBg: "#272D3C",
      optionSelectedBg: "#353A4B",
    },

    Collapse: {
      headerBg: "#2E3240",
      contentBg: "#2E3240",
      borderRadiusLG: 4,
      colorTextHeading: "#ffffff",
    },
    Message: {
      colorSuccess: "#27AE60",
    },

    Steps: {
      colorSplit: "#585F77",
    },
  },
};
