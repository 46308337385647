export const apis = {
  templateConfig: {
    getAll: "/api/v1/template-configs",
    getByID: "/api/v1/template-configs", // '/{id}'
  },

  provider: {
    url: "/api/v1/providers",
    getAll: "/api/v1/providers",
    getByID: "/api/v1/providers", // '/{id}'
  },

  fieldMappingConfig: {
    getAll: "/api/v1/field-mappings", // get
    getByID: "/api/v1/field-mappings", // '/{id}'
    create: "/api/v1/field-mappings", // post
    update: "/api/v1/field-mappings", // '/{id}' put
    delete: "/api/v1/field-mappings", // '/{id}' delete
  },

  searchImage: {
    search: "/api/v1/search-images", // post
    getHistoryByUser: "/api/v1/history-searching/by-user",
  },

  authorization: {
    getResource: "/api/v1/admin/resource",
  },

  cart: {
    addImage: "/api/v1/carts/add-to-cart",
    delImage: "/api/v1/carts/remove-image-to-cart",
    getImage: "/api/v1/carts/by-user",
    getProductBunbleByItemType: "/api/v1/metakeys/product-bundle",
  },

  order: {
    create: "/tasking-flow/api/v1/order",
    getPriority: "/api/v1/metakeys?page=0&size=1000",
    userOrder: "/api/v1/orders/by-user",
    changeStatus: "/api/v1/orders/status",
    createTasking: "/api/v1/order-taskings/oneatlas/order",
    getArchiveOrderById: "/api/v1/order-archiveds",
    adminOrder: "/api/v1/orders/by-admin",
    getProductTypeId: "/api/v1/metakeys/productype-id",
    getOrderByIdUser: "/api/v1/orders",
    getOrderByIdAdmin: "/api/v1/orders",
    getSegmentByIdOrder: "/api/v1/order-taskings/oneatlas/attempts",
    getProduction: "/api/v1/metakeys/option-per-product-type",
    confirmOrderTaskingAdmin: "/api/v1/order-taskings/oneatlas/order",

    approveArchiveOrder: "/api/v1/order-archiveds/body/status",
  },
  metaKey: {
    default: "/api/v1/metakeys",
  },
  project: {
    url: "/api/v1/projects",
    getMyProjects: "/api/v1/projects",
  },
  globalConfig: {
    url: "/api/v1/global-config",
  },
  orderTasking: {
    orderUser: "/api/v1/orders/by-user",
    orderAdmin: "/api/v1/orders/by-admin",
    updateStatus: "/api/v1/order-taskings/oneatlas/order/status",
    getDetailOrderTaskingByUer: "/api/v1/orders",
    getDetailOrderTaskingByAdmin: "/api/v1/orders",
  },

  basemap: {
    url: "/api/v1/basemaps",
  },
};
