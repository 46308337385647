import { Radio, Select, Slider } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AngleSvg from "../../../assets/svg/angle.svg";
import FromToSvg from "../../../assets/svg/from-to.svg";
import IconSvg from "../../../assets/svg/icon.svg";
import { DefaultFormatDateTime } from "../../../enums";
import { convertToObject } from "../../../pages/order/ManageTaskingOrder/ultis";
import { getSegmentsByIdOrderAsync } from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import CommonComponents from "../../Commons";
import { useSearchParams } from "react-router-dom";

const FormProvider = (props: any) => {
  const dispatch = useDispatch();
  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );

  const valueCheckAOI = useSelector(
    (state: RootState) => state.mapTasking.valueCheckAOI
  );
  const [searchParams] = useSearchParams();
  const statusUrgent = searchParams?.get("urgent");
  // const [valueMission, setValueMission] = useState("ONENOW/PLEIADESNEO");

  const [valueMission, setValueMission] = useState(
    `${
      valueCheckAOI?.progTypeNames?.[0]?.includes("ONENOW")
        ? "ONENOW"
        : "ONEDAY"
    }/${valueCheckAOI?.missions?.[0]}`
  );
  const [dataSegments, setDataSegments] = useState<any>([]);
  const [heightSelect, setHeightSelect] = useState<any>(
    props?.type === "edit" ? "100px" : "32px"
  );

  console.log(valueCheckAOI, "valueCheckAOI");

  const [valStartDate, setValueStartDate] = useState<any>(
    valueCheckAOI?.acquisitionDate?.startDate
  );
  const [valEndDate, setValueEndDate] = useState<any>(
    valueCheckAOI?.acquisitionDate?.endDate
  );
  const [loadingAcquisition, setLoadingAcquisition] = useState(false);

  const renderWithType = (
    item: any,
    nameForm?: any,
    fieldRequired?: any,
    type?: any
  ) => {
    const [key, value] = item;

    if (key !== "required") {
      if (
        (value?.type === "string" || value?.type === "number") &&
        (value as any)?.enum?.length > 0
      ) {
        return (
          <CommonComponents.FormFieldUI
            name={nameForm?.concat([key])}
            initialValue={value?.enum?.[0]?.value ?? value?.enum?.[0]}
            component={
              <CommonComponents.SelectUI
                label={value?.label}
                placeholder={key}
                // required={fieldRequired?.includes(key)}
                data={value?.enum?.map((itemM: any) => ({
                  label: itemM?.label ?? itemM,
                  value: itemM?.value ?? itemM,
                }))}
                disabled={type === "edit"}
              />
            }
            rules={[
              {
                required: fieldRequired?.includes(key),
                message: "Please input here!",
              },
            ]}
          />
        );
      }
      if (value?.type === "string") {
        return (
          <CommonComponents.FormFieldUI
            name={nameForm?.concat([key])}
            component={
              <CommonComponents.InputUI
                label={value?.label}
                placeholder={key}
                // required={fieldRequired?.includes(key)}
              />
            }
            rules={[
              {
                required: fieldRequired?.includes(key),
                message: "Please input here!",
              },
            ]}
          />
        );
      }
      if (value?.type === "object") {
        return (
          <React.Fragment key={key}>
            {/* <h3>{value?.label}</h3> */}
            {Object.entries(value.properties || {}).map((child) =>
              renderWithType(child, nameForm?.concat([key]))
            )}
          </React.Fragment>
        );
      }
      if (value?.type === "array" && value.items) {
        return (
          <React.Fragment key={key}>
            {renderWithType([key, value.items], nameForm?.concat([key]))}
          </React.Fragment>
        );
      }

      if (
        value?.type === "number" &&
        typeof value?.minimum !== "undefined" &&
        typeof value?.maximum !== "undefined"
      ) {
        return (
          <>
            <CommonComponents.FormFieldUI
              label={value?.label}
              name={nameForm?.concat([key])}
              component={
                <Slider
                  range={value?.is_range ?? true}
                  max={value?.maximum}
                  min={value?.minimum}
                  disabled={type === "edit"}
                  tooltip={{
                    placement: "right",
                    open: props?.currentStep === 1 ? true : false,
                    autoAdjustOverflow: false,
                    zIndex: 5,
                  }}
                />
              }
              // rules={[{ required: true, message: "Please input here!" }]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (key === "offNadirMax") {
                      const offNadirMin = getFieldValue([
                        "properties",
                        "collectConstraints",
                        "offNadirMin",
                      ]);

                      if (offNadirMin > value) {
                        return Promise.reject([
                          "Off nadir max must be greater than off nadir min",
                        ]);
                      }
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            />
          </>
        );
      }

      if (value?.type === "boolean") {
        return (
          <>
            {/* <h3>{value?.label}</h3> */}
            <CommonComponents.FormFieldUI
              label={value?.label}
              name={nameForm?.concat([key])}
              component={
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              }
              // rules={[{ required: true, message: "Please input here!" }]}
            />
          </>
        );
      }

      if (value?.type === "datetime" && !value?.format) {
        return (
          <>
            <CommonComponents.FormFieldUI
              label={value?.label}
              name={nameForm?.concat([key])}
              initialValue={
                props?.type !== "edit"
                  ? value?.typeCheck === "before-day"
                    ? dayjs()
                    : value?.typeCheck === "after-day"
                    ? undefined
                    : undefined
                  : undefined
              }
              component={
                <CommonComponents.DatePickerUI
                  allowClear={false}
                  className="w-full"
                  placeholder={value?.label}
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  disabledDate={(val) => {
                    const today = dayjs().startOf("day");
                    const fromDateRaw =
                      props.form.getFieldValue(
                        nameForm?.concat([value?.keyCheck])
                      ) ?? null;
                    const fromDate = fromDateRaw ? dayjs(fromDateRaw) : null;

                    if (value?.typeCheck === "before-day") {
                      return val && val < today;
                    }

                    if (value?.typeCheck === "after-day") {
                      if (!fromDate || !fromDate.isValid()) {
                        return true;
                      }
                      const maxDate = fromDate
                        .startOf("day")
                        .add(value?.valueCheck || 14, "day");
                      return (
                        val && (val < fromDate.startOf("day") || val > maxDate)
                      );
                    }

                    return false;
                  }}
                />
              }
            />
          </>
        );
      }

      if (
        value?.type === "datetime" &&
        value?.format ===
          `^yyyy-MM-dd'T'HH:mm:ss.SSS'Z' yyyy-MM-dd'T'HH:mm:ss.SSS'Z'$`
      ) {
        return (
          <>
            {/* Field for "From" */}
            <CommonComponents.FormFieldUI
              label={value?.label + " From"}
              name={nameForm?.concat([key, "From"])}
              component={
                <CommonComponents.DatePickerUI
                  disabled={type === "edit"}
                  allowClear={false}
                  className="w-full"
                  defaultValue={props?.type !== "edit" ? dayjs() : undefined}
                  placeholder="From"
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  disabledDate={(val) => {
                    const today = dayjs().startOf("day");
                    const toDate = props.form.getFieldValue(
                      nameForm?.concat([key, "To"])
                    );
                    return (
                      val &&
                      (val < today ||
                        (toDate && val > dayjs(toDate).endOf("day")))
                    );
                  }}
                />
              }
            />

            {/* Field for "To" */}
            <CommonComponents.FormFieldUI
              label={value?.label + " To"}
              name={nameForm?.concat([key, "To"])}
              component={
                <CommonComponents.DatePickerUI
                  disabled={type === "edit"}
                  allowClear={false}
                  className="w-full"
                  defaultValue={
                    props?.type !== "edit" ? dayjs().add(3, "month") : undefined
                  }
                  placeholder="To"
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  disabledDate={(val) => {
                    const today = dayjs().startOf("day");
                    const fromDate = props.form.getFieldValue(
                      nameForm?.concat([key, "From"])
                    );
                    return (
                      val &&
                      (val < today ||
                        (fromDate && val < dayjs(fromDate).startOf("day")))
                    );
                  }}
                />
              }
            />
          </>
        );
      }
    }
  };

  const renderField = () => {
    const schema =
      props?.dataSchema?.ui_schema?.properties?.order_details?.items;

    let a =
      schema?.properties?.request_order?.properties?.properties?.properties || // capella
      schema?.properties?.request_order?.properties?.optionsPerProductType // oneAltas
        ?.items?.properties?.options.properties;

    let fieldRequired =
      schema?.properties?.request_order?.properties?.properties?.required || // capella
      schema?.properties?.request_order?.properties?.optionsPerProductType // oneAltas
        ?.items?.properties?.options.required;
    if (fieldRequired)
      !!props?.setFieldRequireStep2 &&
        props?.setFieldRequireStep2(fieldRequired);
    if (schema && a) {
      return Object.entries(a).map((field) =>
        renderWithType(
          field,
          schema?.properties?.request_order?.properties?.optionsPerProductType // oneAltas
            ?.items?.properties?.options.properties
            ? ["optionsPerProductType", "options"] // oneAltas
            : ["properties"], // capella
          fieldRequired
        )
      );
    }

    return <div>No schema found</div>;
  };

  const disabledDate: any = (current: any, type: any, mission?: any) => {
    const today = dayjs().startOf("day");
    if (mission?.includes("ONEDAY")) {
      return current && current < today;
    }
    if (type === "timeFrom") {
      return (
        current &&
        (current > dayjs(props.form.getFieldValue("timeTo")).endOf("day") ||
          current < today)
      );
    } else if (type === "timeTo") {
      return (
        current &&
        (current < dayjs(props.form.getFieldValue("timeFrom")).endOf("day") ||
          current < today)
      );
    }
    return false;
  };

  const renderSelectOption = (item: any) => {
    return (
      <div>
        <span className="flex gap-2">
          <img src={IconSvg} />
          {moment(item?.acquisitionStartDate).format(
            "DD/MM/YYYY HH:mm:ss"
          )}{" "}
          <img src={FromToSvg} />{" "}
          {moment(item?.acquisitionEndDate).format("DD/MM/YYYY HH:mm:ss")}
        </span>

        <p className=" flex gap-2">
          <img src={AngleSvg} />
          <span className="text-des">Incidence angle:</span>{" "}
          {item?.incidenceAngle}° - {item?.maxIncidenceAngle}°
        </p>
        <p>
          <span className="text-des">Order deadline:</span>{" "}
          {moment(item?.orderDeadline).format("DD/MM/YYYY HH:mm:ss")}
          {/* {item?.orderDeadline} */}
        </p>
      </div>
    );
  };

  const getSegments = async (pay: any) => {
    setLoadingAcquisition(true);

    // @ts-ignore
    let res = await dispatch(getSegmentsByIdOrderAsync(pay)).unwrap();

    setDataSegments(
      res?.data?.data?.progCapacities?.[0]?.progTypes?.[0]?.segments ?? []
    );
    setLoadingAcquisition(false);
  };

  useEffect(() => {
    if (
      (arrAllProviders
        ?.filter((itemF: any) => itemF?.id === orderedProvider?.[0])?.[0] // luc chon provider new order
        ?.name?.toLowerCase() === "oneatlas" ||
        props?.dataOneAtlas?.order_details?.[0]?.provider?.name?.toLowerCase() === // co provider luc edit
          "oneatlas") &&
      props?.dataSchema?.ui_schema?.properties?.mission?.type &&
      props.form
    ) {
      if (props?.type === "edit") {
        // edit thi disable mission, start/end date, acquisition day
        let propsSegment =
          props?.dataOneAtlas?.order_details?.[0]?.request_order?.items?.[0]
            ?.properties;

        setValueMission(props?.dataOneAtlas?.mission);

        props.form.setFieldValue("mission", props?.dataOneAtlas?.mission);

        if (props?.dataOneAtlas?.mission?.includes("ONEPLAN")) {
          Object.entries(
            props?.dataOneAtlas?.order_details?.[0]?.request_order?.items?.[0]
              ?.properties
          )?.map((item: any, ind: any) => {
            if (item?.[1]?.key?.includes("acqPeriod")) {
              props?.form.setFieldValue(
                ["schemaOnePlan", "acqPeriod", `From`],
                dayjs(item?.[1]?.value?.split(" ")?.[0])
              );

              props?.form.setFieldValue(
                ["schemaOnePlan", "acqPeriod", `To`],
                dayjs(item?.[1]?.value?.split(" ")?.[1])
              );
            } else {
              props?.form.setFieldValue(
                ["schemaOnePlan", `${item?.[1]?.key}`],
                item?.[1]?.value
              );
            }
          });
        } else {
          props.form.setFieldValue(
            "timeFrom",
            dayjs(
              propsSegment?.find(
                (itemF: any) => itemF?.key === "acquisitionStartDate"
              )?.value
            )
          );
          props.form.setFieldValue(
            "timeTo",
            dayjs(
              propsSegment?.find(
                (itemF: any) => itemF?.key === "acquisitionEndDate"
              )?.value
            )
          );
          props.form.setFieldValue(
            "hiddenAcquisition",
            props?.dataOneAtlas?.order_details[0].request_order.items[0]
              .properties
          );

          setDataSegments([
            {
              ...convertToObject(
                props?.dataOneAtlas?.order_details[0].request_order.items[0]
                  .properties
              ),
              id: "acquisition-01",
            },
          ]);

          props?.form.setFieldValue(
            ["production", "acquisition"],
            "acquisition-01"
          );
        }
      } else {
        props.form.setFieldValue(
          "mission",
          `${
            valueCheckAOI?.progTypeNames?.[0]?.includes("ONENOW")
              ? "ONENOW"
              : "ONEDAY"
          }/${valueCheckAOI?.missions?.[0]}`
        );
        props.form.setFieldValue(
          "timeFrom",
          valueCheckAOI?.acquisitionDate?.startDate
        );
        props.form.setFieldValue(
          "timeTo",
          valueCheckAOI?.acquisitionDate?.endDate
        );
      }
    } else if (
      props?.nameProvider &&
      props?.nameProvider === "capella" &&
      props?.form
    ) {
      props.form.setFieldValue(
        ["properties", "windowOpen"],
        dayjs(props?.data?.windowOpen)
      );
      props.form.setFieldValue(
        ["properties", "windowClose"],
        dayjs(props?.data?.windowClose)
      );
    }
  }, [
    props?.dataSchema?.ui_schema?.properties?.mission?.type,
    props?.form,
    props?.type,
    props?.data,
  ]);

  useEffect(() => {
    if (props?.type !== "edit") {
      props.form.setFieldValue(
        ["production", "acquisition"],
        dataSegments?.[0]?.id
      );
      props.form.setFieldValue("hiddenAcquisition", dataSegments?.[0]);
      setHeightSelect("100px");
    }
  }, [dataSegments]);

  useEffect(() => {
    if (
      (valStartDate || valEndDate) &&
      props?.type !== "edit" &&
      !valueMission?.includes("ONEPLAN")
    ) {
      console.log(valEndDate, "valEndDate");
      console.log(valStartDate, "valStartDate");
      const proName =
        valueMission.split("/").map((part) => part.trim())?.[0] === "ONENOW"
          ? "ONENOWATTEMPTS"
          : valueMission.split("/").map((part) => part.trim())?.[0];
      props.form.setFieldValue("timeFrom", valStartDate);
      props.form.setFieldValue("timeTo", valEndDate);

      getSegments({
        progTypeNames: [proName],
        missions: [valueMission.split("/").map((part) => part.trim())?.[1]],
        acquisitionDate: {
          startDate: valStartDate,
          endDate: valEndDate,
        },
        optical: {
          acquisitionMode: valueCheckAOI?.optical?.acquisitionMode,
          maxCloudCover: Number(valueCheckAOI?.optical?.maxCloudCover),
          maxIncidenceAngle: Number(valueCheckAOI?.optical?.maxIncidenceAngle),
        },
        aoi: props?.activePolygon,
      });
    }
  }, [valStartDate, valEndDate, valueMission]);

  return (
    <div className="w-full">
      {(arrAllProviders
        ?.filter((itemF: any) => itemF?.id === orderedProvider?.[0])?.[0]
        ?.name?.toLowerCase() === "oneatlas" ||
        props?.dataOneAtlas?.order_details?.[0]?.provider?.name?.toLowerCase() ===
          "oneatlas") &&
        props?.dataSchema?.ui_schema?.properties?.mission?.type && (
          <>
            <div className="grid grid-cols-3 gap-7">
              <CommonComponents.FormFieldUI
                name="mission"
                component={
                  <CommonComponents.SelectUI
                    disabled={props?.type === "edit" || !!statusUrgent}
                    label={
                      props?.dataSchema?.ui_schema?.properties?.mission
                        ?.label ?? "Mission"
                    }
                    placeholder={
                      props?.dataSchema?.ui_schema?.properties?.mission?.label
                    }
                    // required
                    data={props?.dataSchema?.ui_schema?.properties?.mission?.enum?.map(
                      (itemM: any) => ({
                        label: itemM?.label,
                        value: itemM?.value,
                      })
                    )}
                    onChange={(val) => {
                      setValueMission(val);
                      props.form.setFieldsValue({
                        hiddenAcquisition: {},
                        production: {
                          acquisition: undefined,
                        },
                      });

                      setHeightSelect("32px");
                      if (val?.includes("ONEDAY")) {
                        setValueStartDate(dayjs());
                        setValueEndDate(undefined);
                      } else if (val?.includes("ONENOW")) {
                        setValueEndDate(dayjs().add(3, "day"));
                        setValueStartDate(dayjs());
                      } else if (val?.includes("ONEPLAN")) {
                        setValueEndDate(undefined);
                        setValueStartDate(undefined);
                      }
                    }}
                  />
                }
              />

              {props?.type !== "edit" && !valueMission?.includes("ONEPLAN") && (
                <>
                  <CommonComponents.FormFieldUI
                    name="timeFrom"
                    // noStyle
                    component={
                      <CommonComponents.DatePickerUI
                        disabled={props?.type === "edit"}
                        label="Start date"
                        allowClear={false}
                        className="w-full"
                        onChange={(date) => {
                          setValueStartDate(date);
                        }}
                        disabledDate={(val) =>
                          disabledDate(val, "timeFrom", valueMission)
                        }
                        // defaultValue={valueCheckAOI?.acquisitionDate?.startDate}
                        format={
                          props?.type === "edit"
                            ? "DD/MM/YYYY HH:mm:ss"
                            : DefaultFormatDateTime.DATE_FORMAT
                        }
                        placeholder="From"
                      />
                    }
                  />
                  {!valueMission?.includes("ONEDAY") && (
                    <CommonComponents.FormFieldUI
                      name="timeTo"
                      // noStyle
                      component={
                        <CommonComponents.DatePickerUI
                          disabled={props?.type === "edit"}
                          label="End date"
                          allowClear={false}
                          className="w-full"
                          onChange={(date) => {
                            setValueEndDate(date);
                          }}
                          placeholder="To"
                          format={
                            props?.type === "edit"
                              ? "DD/MM/YYYY HH:mm:ss"
                              : DefaultFormatDateTime.DATE_FORMAT
                          }
                          disabledDate={(val) => disabledDate(val, "timeTo")}
                        />
                      }
                    />
                    //   )}
                    // </>
                  )}
                </>
              )}
            </div>

            {!valueMission?.includes("ONEPLAN") && (
              <>
                <CommonComponents.FormFieldUI
                  name={["production", "acquisition"]}
                  label="Acquisition day"
                  // rules={[{ required: true, message: "Please select here!" }]}
                  component={
                    <Select
                      loading={loadingAcquisition}
                      disabled={props?.type === "edit" || loadingAcquisition}
                      className={`w-full [&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white`}
                      style={{
                        borderRadius: "3px",
                        height: heightSelect,
                      }}
                      placeholder="Select your acquisition day"
                      onChange={(val, item: any) => {
                        if (val) {
                          setHeightSelect("100px");

                          props.form.setFieldsValue({
                            hiddenAcquisition: {
                              ...item?.item,
                            },
                          });
                        } else setHeightSelect("32px");
                      }}
                      defaultValue={
                        props?.type === "edit"
                          ? {
                              ...convertToObject(
                                props?.dataOneAtlas?.order_details[0]
                                  .request_order.items[0].properties
                              ),
                              id: "acquisition-01",
                            }
                          : undefined
                      }
                    >
                      {dataSegments?.map((item: any) => (
                        <Select.Option value={item?.id} item={item}>
                          {renderSelectOption(item)}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                />

                <CommonComponents.FormFieldUI
                  name={"hiddenAcquisition"}
                  label="hiddenField"
                  rules={[{ required: true, message: "Please select here!" }]}
                  style={{ display: "none" }}
                  component={null}
                />
              </>
            )}

            {valueMission?.includes("ONEPLAN") && props?.dataSchemaOnePlan && (
              <>
                <div className="grid grid-cols-4 gap-7">
                  {Object.entries(props?.dataSchemaOnePlan || {}).map(
                    (child) => {
                      return renderWithType(
                        child,
                        ["schemaOnePlan"],
                        [],
                        props?.type
                      );
                    }
                  )}
                </div>
              </>
            )}
          </>
        )}
      <div className="grid grid-cols-3 gap-7">
        {props?.dataSchema && renderField()}
      </div>
    </div>
  );
};

export default FormProvider;
