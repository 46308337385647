export function formatBytesDynamic(bytes: number): string {
  if (bytes < 0) {
    throw new Error("Bytes value must be non-negative");
  }

  const MB = 1024 * 1024; // 1 MB = 1024 * 1024 bytes
  const GB = 1024 * MB; // 1 GB = 1024 * MB

  if (bytes >= GB) {
    return `${(bytes / GB).toFixed(2)} GB`;
  } else {
    return `${(bytes / MB).toFixed(2)} MB`;
  }
}
