import React from "react";

export default function RectangleDrawIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3346 4.63317V11.3665C13.228 11.3465 13.1146 11.3332 13.0013 11.3332C12.0813 11.3332 11.3346 12.0798 11.3346 12.9998C11.3346 13.1132 11.348 13.2265 11.368 13.3332H4.63464C4.65464 13.2265 4.66797 13.1132 4.66797 12.9998C4.66797 12.0798 3.9213 11.3332 3.0013 11.3332C2.88797 11.3332 2.77464 11.3465 2.66797 11.3665V4.63317C2.77464 4.65317 2.88797 4.6665 3.0013 4.6665C3.9213 4.6665 4.66797 3.91984 4.66797 2.99984C4.66797 2.8865 4.65464 2.77317 4.63464 2.6665H11.368C11.348 2.77317 11.3346 2.8865 11.3346 2.99984C11.3346 3.91984 12.0813 4.6665 13.0013 4.6665C13.1146 4.6665 13.228 4.65317 13.3346 4.63317Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66536 3.00016C4.66536 3.92016 3.9187 4.66683 2.9987 4.66683C2.88536 4.66683 2.77203 4.6535 2.66536 4.6335C1.90536 4.48016 1.33203 3.80683 1.33203 3.00016C1.33203 2.08016 2.0787 1.3335 2.9987 1.3335C3.80536 1.3335 4.4787 1.90683 4.63203 2.66683C4.65203 2.7735 4.66536 2.88683 4.66536 3.00016Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6654 3.00016C14.6654 3.80683 14.092 4.48016 13.332 4.6335C13.2254 4.6535 13.112 4.66683 12.9987 4.66683C12.0787 4.66683 11.332 3.92016 11.332 3.00016C11.332 2.88683 11.3454 2.7735 11.3654 2.66683C11.5187 1.90683 12.192 1.3335 12.9987 1.3335C13.9187 1.3335 14.6654 2.08016 14.6654 3.00016Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66536 13.0002C4.66536 13.1135 4.65203 13.2268 4.63203 13.3335C4.4787 14.0935 3.80536 14.6668 2.9987 14.6668C2.0787 14.6668 1.33203 13.9202 1.33203 13.0002C1.33203 12.1935 1.90536 11.5202 2.66536 11.3668C2.77203 11.3468 2.88536 11.3335 2.9987 11.3335C3.9187 11.3335 4.66536 12.0802 4.66536 13.0002Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6654 13.0002C14.6654 13.9202 13.9187 14.6668 12.9987 14.6668C12.192 14.6668 11.5187 14.0935 11.3654 13.3335C11.3454 13.2268 11.332 13.1135 11.332 13.0002C11.332 12.0802 12.0787 11.3335 12.9987 11.3335C13.112 11.3335 13.2254 11.3468 13.332 11.3668C14.092 11.5202 14.6654 12.1935 14.6654 13.0002Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
