import React from "react";
import { Select, SelectProps } from "antd";
import CommonIcons from "../../../assets/icons";

interface IProps extends SelectProps {
  className?: string;
  label?: string;
  data?: any;
  disabled?: boolean;
  onSelect?: any;
  nameValue?: string;
  required?: boolean;
  nameLabel?: string;
  style?: any;
}

export default function SelectUI({
  className,
  label,
  data,
  disabled,
  onSelect,
  nameValue,
  required,
  nameLabel,
  style,
  ...res
}: IProps) {
  const defaultClas: string =
    "[&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white";

  return (
    <>
      {label && (
        <p className="mb-1 text-white text-sm">
          {label}
          {required && <span className="text-common-red500">{` *`}</span>}
        </p>
      )}

      <Select
        className={` ${className ? ` ${className}` : ""}`}
        onSelect={onSelect}
        disabled={disabled}
        style={{ borderRadius: "4px", ...style }}
        suffixIcon={
          <div className="!text-white rotate-180">
            <CommonIcons.ChevronUpIcon />
          </div>
        }
        {...res}
      >
        {data?.length > 0
          ? data?.map((item: any) => (
              <Select.Option
                value={
                  nameValue ? item?.[`${nameValue}`] : item?.value || item?.name
                }
              >
                {nameLabel ? item?.[`${nameLabel}`] : item?.label || item?.name}
              </Select.Option>
            ))
          : null}
      </Select>
    </>
  );
}
