import { Form, Steps } from "antd";
import { useEffect, useState } from "react";
import FormProvider from "./FormByProvider/FormProvider";
import Step1 from "./Step1";
import MapTaskingPlugin from "../MapTasking/Map";
import { convertToObject } from "../../pages/order/ManageTaskingOrder/ultis";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useParams, useSearchParams } from "react-router-dom";
import CommonComponents from "../Commons";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import ConfirmOrderComp from "../ManageOrder/AdminOrderToProvider/ConfirmOrder";
import { convertToGeoJSON } from "./utils/BuyerInfomation";

const OrderTaskingForm = (props: any) => {
  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );

  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );
  let { idOrder } = useParams();

  const [form] = Form.useForm();

  const [current, setCurrent] = useState(0);
  const [footprintGeoJson, setFootprintGeoJson] = useState<any>(null);

  const nameWatch = Form.useWatch("name", form);
  const priorityWatch = Form.useWatch("priority_id", form);
  const reasonWatch = Form.useWatch("reason", form);

  const propertiesValuesCapella = Form.useWatch(["properties"], form);
  const propertiesValuesOneatlas = Form.useWatch(
    ["optionsPerProductType", "options"],
    form
  );
  const propertiesValuesAcquisitionDayOneatlas = Form.useWatch(
    ["hiddenAcquisition"],
    form
  );

  const onChange = (value: number) => {
    setCurrent(value);
  };
  const handleStep = (type: "next" | "prev") => {
    if (type === "next") {
      setCurrent(current + 1);
    } else {
      setCurrent(current - 1);
    }
  };

  const valueFieldRequireStep2 = props.fieldRequireStep2?.map((field: any) => {
    const providerUsing = idOrder
      ? [props?.initialValues?.order_details?.[0]?.provider?.id]
      : orderedProvider;
    if (
      arrAllProviders
        ?.filter((itemF: any) => itemF?.id === providerUsing?.[0])?.[0]
        ?.name?.toLowerCase() === "oneatlas"
    ) {
      return propertiesValuesOneatlas?.[field];
    }
    if (
      arrAllProviders
        ?.filter((itemF: any) => itemF?.id === providerUsing?.[0])?.[0]
        ?.name?.toLowerCase() === "capella"
    ) {
      return propertiesValuesCapella?.[field];
    }
  });

  useEffect(() => {
    if (props.formRef) {
      props.formRef.current = form;
    }
  }, [form, props.formRef]);

  useEffect(() => {
    if (props?.initialValues && props?.dataSchema) {
      if (
        props?.initialValues?.order_details?.[0]?.provider?.name.toLowerCase() ===
        "capella"
      ) {
        let { windowClose, windowOpen, ...newProperties } =
          props?.initialValues?.order_details[0]?.request_order?.properties;

        form.setFieldsValue({
          ...props.initialValues,
          priority_id: props?.initialValues?.priority?.id,

          properties: {
            // ...props?.initialValues?.order_details[0]?.request_order
            //   ?.properties,
            ...newProperties,
          },
        });
      } else if (
        props?.initialValues?.order_details?.[0]?.provider?.name.toLowerCase() ===
        "oneatlas"
      ) {
        form.setFieldsValue({
          ...props.initialValues,
          priority_id: props?.initialValues?.priority?.id,

          optionsPerProductType: {
            options: {
              ...convertToObject(
                props?.initialValues?.order_details[0].request_order
                  .optionsPerProductType?.[0]?.options
              ),
            },
          },
        });
      }
    }
  }, [form, props?.initialValues, props?.dataSchema]);

  useEffect(() => {
    const itemStepClone = [...props?.itemStep];
    if (itemStepClone[0].status && itemStepClone[0].status !== "process") {
      itemStepClone[0].status =
        nameWatch && priorityWatch && reasonWatch ? "finish" : "error";
    }

    const isValidArray = valueFieldRequireStep2?.every(
      (element: any) =>
        element !== undefined && element !== null && element !== ""
    );
    if (itemStepClone[1].status && itemStepClone[1].status !== "process") {
      itemStepClone[1].status = isValidArray ? "finish" : "error";
      //! check for oneatlas
      if (
        form.getFieldValue("mission") === "ONENOW/SPOT" &&
        propertiesValuesAcquisitionDayOneatlas
      ) {
        itemStepClone[1].status =
          propertiesValuesAcquisitionDayOneatlas !== "123" ? "finish" : "error";
      }
    }

    props?.setItemStep && props?.setItemStep(itemStepClone);
  }, [
    nameWatch,
    priorityWatch,
    reasonWatch,
    JSON.stringify(valueFieldRequireStep2),
    propertiesValuesAcquisitionDayOneatlas,
  ]);

  useEffect(() => {
    if (propertiesValuesAcquisitionDayOneatlas?.footprint) {
      setFootprintGeoJson(
        convertToGeoJSON(propertiesValuesAcquisitionDayOneatlas?.footprint)
      );
    }
  }, [propertiesValuesAcquisitionDayOneatlas]);

  return (
    <Form form={form} initialValues={props?.initialValues} layout="vertical">
      <div
        className={
          "text-sm text-white mt-3 h-full flex flex-col justify-between gap-7"
        }
      >
        <div className="flex  items-center gap-3">
          <CommonComponents.ButtonUI
            commonType="standard"
            className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
            icon={<ArrowLeftOutlined />}
            iconPosition="start"
            disabled={current === 0}
            onClick={() => {
              handleStep("prev");
            }}
          ></CommonComponents.ButtonUI>

          <Steps
            current={current}
            onChange={onChange}
            direction="horizontal"
            items={props?.itemStep}
          />

          <CommonComponents.ButtonUI
            commonType="standard"
            className="!text-xs !h-7 bg-transparent disabled:!bg-transparent"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            disabled={current >= props?.itemStep?.length - 1}
            onClick={() => {
              handleStep("next");
            }}
          ></CommonComponents.ButtonUI>
        </div>

        <div
          className={
            "text-sm text-white mt-3 h-full flex flex-col justify-between overflow-y-scroll"
          }
          style={{ height: "calc(100vh - 56px - 56px - 215px)" }}
        >
          <div
            // className="!w-1/2"
            className="!flex-row gap-4 !h-full"
            style={{ display: current === 0 ? "flex" : "none" }}
          >
            <Step1
              className="!w-full"
              form={form}
              dataPriority={props?.dataPriority ?? []}
              valuesForm={props?.valuesForm}
              datProject={props?.datProject ?? []}
            />
            <MapTaskingPlugin
              className="!w-full"
              showOnConfirm={true}
              activePolygon={props?.geometry ?? {}}
            />
          </div>
          <div
            className="!w-full !flex-row gap-4 !h-full"
            style={{ display: current === 1 ? "flex" : "none" }}
          >
            <FormProvider
              className="!w-full !h-full"
              data={
                // for capella
                props?.initialValues?.order_details[0]?.request_order
                  ?.properties
              }
              form={form}
              dataSchema={props?.dataSchema}
              activePolygon={props?.geometry ?? {}}
              setFieldRequireStep2={props?.setFieldRequireStep2}
              dataOneAtlas={props?.initialValues}
              type={props?.type}
              dataSchemaOnePlan={props?.dataSchemaOnePlan}
              nameProvider={
                props?.initialValues?.order_details[0]?.provider?.name?.toLowerCase() ??
                ""
              }
              currentStep={current}
            />

            <MapTaskingPlugin
              className="!w-full !h-full"
              showOnConfirm={true}
              activePolygon={props?.geometry ?? {}}
              footprint={footprintGeoJson}
            />
          </div>

          <div
            className="!w-full !flex-row gap-4 !h-full"
            style={{ display: current === 2 ? "flex" : "none" }}
          >
            <ConfirmOrderComp
              data={form.getFieldsValue() ?? {}}
              type={"confirm-user-order"}
              style={{
                width: "100%",
                height: "100%",
                overFlow: "auto",
                overflow: "auto",
              }}
            />

            <MapTaskingPlugin
              className="!w-full !h-full"
              showOnConfirm={true}
              activePolygon={props?.geometry ?? {}}
              footprint={footprintGeoJson}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default OrderTaskingForm;
