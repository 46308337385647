import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DismissSvg from "../../assets/svg/dismiss.svg";
import FilterSvg from "../../assets/svg/filter.svg";
import RefreshSvg from "../../assets/svg/refresh.svg";
import { setPageNumber } from "../../store/reducers/appCommonSlice";
import {
  changeLeftDrawerType,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import { changeFilterArchive } from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";
import ButtonIconComp from "../MapTasking/ButtonIconComp";
import moment from "moment";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";

const DrawerFilterArchiveOrder = () => {
  //! state
  const dispatch = useDispatch();

  const metaKey = useSelector(
    (state: RootState) => state.appCommonSlice.metaKey
  );

  const filterValue = useSelector(
    (state: RootState) => state.orderSlice.filterDrawer
  );

  const [statusChangeOption, setStatusChangeOption] = useState(false);

  const [emailSearch, setEmailSearch] = useState("");
  const [filter, setFilter] = useState({
    status: filterValue?.status,
    sortBy: "desc",
    start_date: null,
    end_date: null,
    email: null,
  });
  useDebounce(
    () => {
      if (statusChangeOption) {
        handleChangeFilter("email", emailSearch);
      }
    },
    [emailSearch],
    1000
  );

  //! fuction
  const handleRefresh = () => {
    dispatch(
      // @ts-ignore
      changeFilterArchive({
        ...filterValue,
        status: null,
        sortBy: "desc",
        start_date: null,
        end_date: null,
        email: null,
      })
    );

    setFilter({
      status: null,
      sortBy: "desc",
      start_date: null,
      end_date: null,
      email: null,
    });
    setEmailSearch("");
  };

  const handleChangeFilter = (key: string, value: any) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  //! effect
  useEffect(() => {
    if (statusChangeOption) {
      dispatch(
        // @ts-ignore
        changeFilterArchive({
          ...filterValue,
          status: filter.status,
          sortBy: filter.sortBy,
          start_date: filter.start_date,
          end_date: filter.end_date,
          email: filter.email,
        })
      );

      dispatch(setPageNumber(0));
      setStatusChangeOption(false);
    }
  }, [filter]);
  //! render
  return (
    <div className="h-full p-3 w-[460px]">
      <div className="sticky -top-3 z-10 bg-common-ink100 ">
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <img src={FilterSvg} className="!w-4" />
            <div>Filter</div>
          </div>

          <div className="flex items-center gap-2">
            {/* <ButtonIconComp
              item={{
                description: "Apply",
                icon: ApplySvg,
                title: "Apply",
              }}
              onClick={() => {
                handleFilter();
              }}
              className="!h-7  !w-[80px] !min-w-[80px] !rounded !bg-[#272D3C] div-one-center"
            /> */}
            <ButtonIconComp
              item={{
                description: "Refresh",
                icon: RefreshSvg,
              }}
              onClick={handleRefresh}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
            />
            <ButtonIconComp
              item={{
                description: "Close",
                icon: DismissSvg,
              }}
              onClick={() => {
                dispatch(changeShowDrawer(false));
                dispatch(changeLeftDrawerType(""));
              }}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C] div-one-center"
            />
          </div>
        </div>
      </div>

      <div
        className="flex flex-col gap-4 overflow-x-auto"
        style={{ height: "calc(100% - 100px)" }}
      >
        <div>
          <CommonComponents.SelectUI
            className="div-dropdown"
            label="Status"
            value={filter.status}
            options={[
              { value: null, label: "All" },
              ...metaKey
                ?.filter((itemF: any) => itemF?.key === "ORDER_STATUS")
                ?.sort((a: any, b: any) => a.priority_view - b.priority_view)
                ?.map((itemM: any) => ({
                  value: itemM?.id,
                  label: itemM?.value,
                })),
            ]}
            onChange={(val: any) => {
              handleChangeFilter("status", val);

              setStatusChangeOption(true);
            }}
          />
        </div>

        <div>
          <CommonComponents.SelectUI
            className="div-dropdown"
            label="Sort by (Time)"
            value={filter.sortBy}
            options={[
              {
                value: "asc",
                label: "Ascending",
              },
              {
                value: "desc",
                label: "Descending",
              },
            ]}
            onChange={(val) => {
              handleChangeFilter("sortBy", val);
              setStatusChangeOption(true);
            }}
          />
        </div>

        <div>
          <CommonComponents.InputUI
            label="Email"
            placeholder="Enter email to search"
            allowClear
            onChange={(event: any) => {
              setEmailSearch(event.target.value);
              setStatusChangeOption(true);
            }}
          />
        </div>

        <div className="flex gap-3">
          <div className="basis-1/2">
            <CommonComponents.DatePickerUI
              label="Start time"
              placeholder="Select start date"
              allowClear={false}
              className="w-full"
              value={
                !filter.start_date ? null : dayjs(filter.start_date * 1000)
              }
              onChange={(val: any, dateString: string | string[]) => {
                const timestamp =
                  moment(dateString, "DD/MM/YYYY HH:mm:ss")
                    .startOf("day")
                    .valueOf() / 1000;

                handleChangeFilter("start_date", timestamp);
                setStatusChangeOption(true);
              }}
            />
          </div>
          <div className="basis-1/2">
            <CommonComponents.DatePickerUI
              label="End time"
              placeholder="Select end date"
              allowClear={false}
              className="w-full"
              value={!filter.end_date ? null : dayjs(filter.end_date * 1000)}
              onChange={(val: any, dateString: string | string[]) => {
                const timestamp = Math.floor(
                  moment(dateString, "DD/MM/YYYY HH:mm:ss")
                    .endOf("day")
                    .valueOf() / 1000
                );
                handleChangeFilter("end_date", timestamp);
                setStatusChangeOption(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilterArchiveOrder;
