import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  approveArchiveOrder,
  approveOrder,
  confirmOrderTaskingAdmin,
  createOrder,
  createTaskingOrder,
  getArchiveOrderById,
  getDetailOrderTaskingByAdmin,
  getDetailOrderTaskingByUer,
  getMetakeys,
  getOrderById,
  getOrderByIdUser,
  getOrderDetailByAdmin,
  getOrderDetailByUser,
  getPriority,
  getProduction,
  getProductTypeId,
  getSegmentsByIdOrder,
  getTaskingSchema,
  postNotiOrder,
  sendPriceToUser,
  sendQuotationToUser,
  sendToProvider,
  submitOrder,
  updateArchiveOrder,
  updateArchiveOrderByAdmin,
  updateStatusTaskingOrder,
  uploadData,
} from "../../services/orderServices";
import { showError } from "../../helpers/toast";
import { getErrorMsg } from "../../helpers/common";

interface IOrderState {
  showDrawerListImages: boolean;
  showOrderTasking?: boolean;
  valuesForm?: any;
  orderedProvider?: any;
  polygonOrderTasking?: any;
  providerIdOrder?: any;

  filterDrawer?: {
    status: any;
    sortBy: "asc" | "desc";
    start_date?: number;
    end_date?: number;
    email?: string;
  };
  isFilter: any;
}

const initialState: IOrderState = {
  showDrawerListImages: false,
  showOrderTasking: false,
  valuesForm: {},
  orderedProvider: [],
  polygonOrderTasking: {},
  providerIdOrder: [],

  filterDrawer: {
    status: null,
    sortBy: "desc",
  },
  isFilter: false,
};

export const getOrderDetailByUserAsync = createAsyncThunk(
  "order/getOrderDetailByUser",
  async (payload: any) => {
    try {
      const response = await getOrderDetailByUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getOrderDetailByAdminAsync = createAsyncThunk(
  "order/getOrderDetailByAdmin",
  async (payload: any) => {
    try {
      const response = await getOrderDetailByAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getProductTypeIdAsync = createAsyncThunk(
  "order/getProductTypeId",
  async (payload: any) => {
    try {
      const response = await getProductTypeId(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);
export const approveArchiveOrderAsync = createAsyncThunk(
  "order/approveArchiveOrder",
  async (payload: any) => {
    try {
      const response = await approveArchiveOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const updateArchiveOrderAsync = createAsyncThunk(
  "order/updateArchiveOrder",
  async (payload: any) => {
    try {
      const response = await updateArchiveOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const updateArchiveOrderByAdminAsync = createAsyncThunk(
  "order/updateArchiveOrder",
  async (payload: any) => {
    try {
      const response = await updateArchiveOrderByAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getDetailOrderTaskingByUerAsync = createAsyncThunk(
  "order/getDetailOrderTaskingByUer",
  async (payload: any) => {
    try {
      const response = await getDetailOrderTaskingByUer(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getDetailOrderTaskingByAdminAsync = createAsyncThunk(
  "order/getDetailOrderTaskingByAdmin",
  async (payload: any) => {
    try {
      const response = await getDetailOrderTaskingByAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getOrderByIdUserAsync = createAsyncThunk(
  "order/getOrderByIdUser",
  async (payload: any) => {
    try {
      const response = await getOrderByIdUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getOrderByIdAsync = createAsyncThunk(
  "order/getOrderById",
  async (payload: any) => {
    try {
      const response = await getOrderById(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getSegmentsByIdOrderAsync = createAsyncThunk(
  "order/getSegmentsByIdOrder",
  async (payload: any) => {
    try {
      const response = await getSegmentsByIdOrder(payload);
      return response;
    } catch (e: any) {
      // showError(getErrorMsg(e));

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const confirmOrderTaskingAdminAsync = createAsyncThunk(
  "order/confirmOrderTaskingAdmin",
  async (payload) => {
    try {
      const response = await confirmOrderTaskingAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getProductionAsync = createAsyncThunk(
  "order/getProduction",
  async () => {
    try {
      const response = await getProduction();
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const approveOrderAsync = createAsyncThunk(
  "order/approveOrder",
  async (payload: any) => {
    try {
      const response = await approveOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const submitOrderAsync = createAsyncThunk(
  "order/submitOrder",
  async (payload: any) => {
    try {
      const response = await submitOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);
export const createOrderAsync = createAsyncThunk(
  "order/createOrder",
  async (payload: any) => {
    try {
      const response = await createOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const createTaskingOrderAsync = createAsyncThunk(
  "order/createTaskingOrder",
  async (payload: any) => {
    try {
      const response = await createTaskingOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getArchiveOrderByIdAsync = createAsyncThunk(
  "order/getArchiveOrderById",
  async (id) => {
    try {
      const response = await getArchiveOrderById(id);
      return response;
    } catch (e) {
      console.log(e, "error");

      return {};
    }
  }
);

export const getPriorityAsync = createAsyncThunk(
  "exploredataset/getPriority",
  async () => {
    try {
      const response = await getPriority();
      return response;
    } catch (e) {
      console.log(e, "error");

      return {};
    }
  }
);

export const getMetakeysAsync = createAsyncThunk(
  "order/getMetakeys",
  async (payload: any) => {
    try {
      const response = await getMetakeys(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const updateStatusTaskingOrderAsync = createAsyncThunk(
  "order/updateStatusTaskingOrder",
  async (payload: any) => {
    try {
      const response = await updateStatusTaskingOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getTaskingSchemaAsync = createAsyncThunk(
  "order/getTaskingSchema",
  async (payload: any) => {
    try {
      const response = await getTaskingSchema(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const postNotiOrderAsync = createAsyncThunk(
  "order/postNotiOrder",
  async (payload: any) => {
    try {
      const response = await postNotiOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const sendQuotationToUserAsync = createAsyncThunk(
  "order/sendQuotationToUser",
  async (payload: any) => {
    try {
      const response = await sendQuotationToUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const sendPriceToUserAsync = createAsyncThunk(
  "order/sendPriceToUser",
  async (payload: any) => {
    try {
      const response = await sendPriceToUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const sendToProviderAsync = createAsyncThunk(
  "order/sendToProvider",
  async (payload: any) => {
    try {
      const response = await sendToProvider(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const uploadDataAsync = createAsyncThunk(
  "order/uploadData",
  async (payload: any) => {
    try {
      const response = await uploadData(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    changeShowDrawerListImages: (state, action: PayloadAction<boolean>) => {
      state.showDrawerListImages = action.payload;
    },

    changeShowOrderTasking: (state, action: PayloadAction<boolean>) => {
      state.showOrderTasking = action.payload;
    },
    setValuesForm: (state, action: any) => {
      state.valuesForm = action.payload;
    },

    setOrderedProvider: (state, action: any) => {
      state.orderedProvider = action.payload;
    },
    setPolygonOrderTasking: (state, action: any) => {
      state.polygonOrderTasking = action.payload;
    },
    setProviderIdOrder: (state, action: any) => {
      state.providerIdOrder = action.payload;
    },

    setIsFilter: (state, action: any) => {
      state.isFilter = action.payload;
    },

    changeFilterArchive: (state, action: any) => {
      state.filterDrawer = action.payload;
    },
  },
});

export const {
  changeShowDrawerListImages,
  changeShowOrderTasking,
  setValuesForm,
  setOrderedProvider,
  setPolygonOrderTasking,
  setProviderIdOrder,
  setIsFilter,
  changeFilterArchive,
} = orderSlice.actions;

export default orderSlice.reducer;
