import CameraOutlined from "@ant-design/icons/lib/icons/CameraOutlined";
import { Checkbox, Divider, MenuProps, Spin, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios, { all } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartSvg from "../../assets/svg/cart.svg";
import CloudSvg from "../../assets/svg/cloud.svg";
import CloseSvg from "../../assets/svg/dismiss.svg";
import FootSvg from "../../assets/svg/foot.svg";
import ImageDefault from "../../assets/svg/item-iamge-default.png";
import PositionSvg from "../../assets/svg/position.svg";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import { changeLeftDrawerType } from "../../store/reducers/drawerSlice";
import {
  searchAsync,
  setFlagSearch,
  setProviderId,
  setSelected,
} from "../../store/reducers/mapTaskingSlice";
import {
  resetState,
  setDataImages,
  setDataImagesProvider,
  setLoadingProvider,
} from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";
import ButtonIconComp from "./components/ButtonIcon";
import { RenderMoreDesItem } from "./components/ultis";
import { onMouseEnterItem, onMouseLeaveItem, panToAndExtend } from "./ultis";

import Icon1 from "../../assets/svg/icon-1.svg";
import Icon2 from "../../assets/svg/icon-2.svg";
import Icon3 from "../../assets/svg/icon-3.svg";
import Icon4 from "../../assets/svg/icon-4.svg";
import Icon5 from "../../assets/svg/icon-5.svg";
import ViewMapSvg from "../../assets/svg/view-map.svg";
import { ESttausCode, ETypeImage } from "../../enums";
import useToggle from "../../hooks/useToggle";
import {
  addImgIntoCartAsync,
  delImgIntoCartAsync,
  setArrIdImage,
  setImagesRecentSelected,
} from "../../store/reducers/cartSlice";
import CommonComponents from "../Commons";
import ImageInformation from "./ImageInformation";

// Chienbv add here 28/11/2024 11:00. Func for add image to map
import { bbox } from "@turf/bbox";
import { v4 as uuidv4 } from "uuid";
import { showError, showSuccess } from "../../helpers/toast";
import LoadingUI from "../Commons/Loading";
// End Chienbv add here 28/11/2024 11:00. Func for add image to map

const items: MenuProps["items"] = [];

const DrawerListImagesSeacrhComp = () => {
  const dispatch = useDispatch();

  const [clickChangeProvider, setClickChangeProvider] =
    useState<boolean>(false);

  const [arrImgViewOnMap, setArrImgViewOnMap] = useState<any>([]);

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  let activePolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  let typeImage = useSelector((state: RootState) => state.mapTasking.typeImage);
  let page = useSelector((state: RootState) => state.search.page);
  let dataImages = useSelector((state: RootState) => state.search.dataImages);
  let searchState = useSelector((state: RootState) => state.search);
  let providerId = useSelector(
    (state: RootState) => state.mapTasking.providerId
  );

  let recentImageSelected = useSelector(
    (state: RootState) => state.cartSlice.imagesRecentSelected
  );

  let arrProviders = useSelector(
    (state: RootState) => state.mapTasking.arrProviders
  );

  let flagSearch = useSelector(
    (state: RootState) => state.mapTasking.flagSearch
  );

  let commonFilter = useSelector(
    (state: RootState) => state.mapTasking.commonFilter
  );

  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  let selected = useSelector((state: RootState) => state.mapTasking.selected);
  const { arrIdImage } = useSelector((state: RootState) => state.cartSlice);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openConfirmRemoveCart,
    toggle: toggleConfirmRemoveCart,
    shouldRender: shouldRenderConfirmRemoveCart,
  } = useToggle();

  const [imageInfo, setImageInfo] = useState(null);
  const [imagesSelected, setImagesSelected] = useState<{
    acction?: "add" | "remove";
    title?: string;
    subTitle?: string;
    images?: any[];
  }>({});

  // @ts-ignore
  let loading = searchState[`loading_provider_${providerId}`];

  const searchAllProviders = async () => {
    dispatch(setLoadingGlobal(true));

    const searchPromises = arrProviders.map(async (provider: any) => {
      dispatch(setLoadingProvider({ id: provider.id, value: false }));
      const pay = {
        // aois: features?.map((itemM: any) => itemM.geometry),
        aois: [activePolygon?.geometry],
        date_time: [
          commonFilter?.timeFrom.valueOf(),
          commonFilter?.timeTo.valueOf(),
        ],
        cloud_cover: commonFilter?.cloudCover,
        // resolution: commonFilter?.resolution,
        resolution: commonFilter?.resolution
          .split(",")
          .map((item: any) => parseInt(item.trim(), 10)),
        incidence_angle: commonFilter?.incidenceAngle,
        page_number: page,
        page_size: 50,
        provider_id: provider.id,
        image_type: typeImage,
      };
      try {
        // @ts-ignore
        const resSearch = await dispatch(searchAsync(pay)).unwrap();

        dispatch(
          setDataImagesProvider({
            id: provider.id,
            data: resSearch?.data?.data?.features,
            page: resSearch?.data?.data?.total_record ? page + 1 : page,
            nextLink: !resSearch?.data?.data?.total_record
              ? resSearch?.data?.data?.links?.filter(
                  (itemF: any) => itemF?.rel === "next"
                )?.[0]?.href
              : undefined,
            firstLink: !resSearch?.data?.data?.total_record
              ? resSearch?.data?.data?.links?.filter(
                  (itemF: any) => itemF?.rel === "first"
                )?.[0]?.href
              : undefined,
            total:
              resSearch?.data?.data?.total_record ||
              resSearch?.data?.data?.features?.length,
          })
        );

        if (provider.id === providerId) {
          dispatch(setDataImages(resSearch?.data?.data?.features));
        }

        return {
          id: provider.id,
          total:
            resSearch?.data?.data?.total_record ||
            resSearch?.data?.data?.features?.length,
          listImages: resSearch?.data?.data?.features || [],
          [`loading_${provider.id}`]: false,
        };
      } catch (error) {
        dispatch(setLoadingProvider({ id: provider.id, value: true }));
        return {
          id: provider.id,
          total: 0,
          listImages: [],
          [`loading_${provider.id}`]: false,
        };
      }
    });
    const results = await Promise.all(searchPromises);
    dispatch(setFlagSearch(false));
    dispatch(setLoadingGlobal(false));
  };

  const search = async (props?: any) => {
    dispatch(setLoadingGlobal(true));

    let pay = {
      // aois: features?.map((itemM: any) => itemM.geometry),
      aois: [activePolygon?.geometry],
      date_time: [
        commonFilter?.timeFrom.valueOf(),
        commonFilter?.timeTo.valueOf(),
      ],
      cloud_cover: commonFilter?.cloudCover,
      // resolution: commonFilter?.resolution,
      resolution: commonFilter?.resolution
        .split(",")
        .map((item: any) => parseInt(item.trim(), 10)),
      incidence_angle: commonFilter?.incidenceAngle,
      // @ts-ignore
      page_number: searchState[`page_${providerId}`],
      page_size: 50,
      provider_id: providerId,
      image_type: typeImage,
    };

    try {
      // @ts-ignore
      let resSearch = await dispatch(searchAsync(pay)).unwrap();

      dispatch(
        setDataImagesProvider({
          id: providerId,
          data: dataImages.concat(resSearch?.data?.data?.features),
          // @ts-ignore
          page: searchState[`page_${providerId}`] + 1,
          nextLink: undefined,
          firstLink: undefined,
          total: resSearch?.data?.data?.total_record,
        })
      );

      dispatch(
        setDataImages(dataImages.concat(resSearch?.data?.data?.features ?? []))
      );

      dispatch(setLoadingGlobal(false));
    } catch (error) {
      dispatch(setLoadingGlobal(false));
    }
  };
  const handleInfoImage = (image: any) => {
    setImageInfo(image);
    toggle();
  };
  const handleAddCart = async (itemImage: string[], e?: any) => {
    dispatch(setLoadingGlobal(true));
    dispatch(setLoadingProvider({ id: providerId, value: false }));
    // setSearchingImages(true);
    e?.stopPropagation();
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      addImgIntoCartAsync({
        image_ids: itemImage,
      })
    ).unwrap();

    if (res?.status === ESttausCode.OK || res?.status === ESttausCode.CREATED) {
      showSuccess("Add image to cart successfully");

      let arr = arrIdImage;

      let result = arr.concat(itemImage);
      let recentSelected = recentImageSelected.concat(itemImage);

      dispatch(setArrIdImage(result));
      dispatch(setImagesRecentSelected(recentSelected));
      setSelectedImages([]);
      setSelectAll(false);
    } else {
      showError(res?.response?.data?.message ?? "Add image to cart failed");
    }

    dispatch(setLoadingGlobal(false));
    dispatch(setLoadingProvider({ id: providerId, value: true }));

    // setSearchingImages(false);
  };

  const deleteMultiImages = async (arr: any) => {
    dispatch(setLoadingGlobal(true));
    dispatch(setLoadingProvider({ id: providerId, value: false }));

    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      delImgIntoCartAsync({
        // imageIds: arr.map((itemM: any) => itemM.id),
        image_ids: arr,
      })
    ).unwrap();

    if (res?.status === ESttausCode.OK) {
      showSuccess("Delete image from cart successfully");

      let arrIdImageClone = arrIdImage;

      let result = arrIdImageClone.filter(
        (itemFilter: any) => !arr.some((image: any) => image === itemFilter)
      );
      let resultRecentImages = recentImageSelected.filter(
        (itemFilter: any) => !arr.some((image: any) => image === itemFilter)
      );

      dispatch(setArrIdImage(result));
      dispatch(setImagesRecentSelected(resultRecentImages));
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      setSelectedImages([]);
      setSelectAll(false);
    } else {
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      showError("Delete image from cart failed");
    }

    dispatch(setLoadingGlobal(false));
  };
  const renderHeaderDrawer = () => {
    let elementsMatch = selectedImages?.filter((value) =>
      arrIdImage?.includes(value)
    );

    const handleActionCart = () => {
      if (elementsMatch.length === selectedImages.length) {
        // setImagesSelected({
        //   title: "Remove images selected",
        //   subTitle: "Are you sure you want to remove all images selected ?",
        //   images: selectedImages,
        //   acction: "remove",
        // });
        // toggleConfirmRemoveCart();
        showError("Images already in cart");

        return;
      } else {
        let imageAdd: any = [];
        selectedImages.forEach((element) => {
          if (!elementsMatch.includes(element)) {
            imageAdd.push(element);
          }
        });
        setImagesSelected({
          title: "Add images selected",
          subTitle: "Are you sure you want to add all images selected ?",
          images: imageAdd,
          acction: "add",
        });
        toggleConfirmRemoveCart();
      }
    };
    return (
      <div className="flex justify-between items-center py-1">
        <div className="flex items-center gap-3 text-sm font-normal text-common-white">
          <div className="scale-125">
            <CameraOutlined size={16} />
          </div>

          <div className="flex items-center gap-3">
            <Checkbox
              checked={selectAll}
              onChange={onSelectAll}
              indeterminate={
                selectedImages.length > 0 &&
                selectedImages.length < dataImages.length
              }
              className="text-sm text-common-white font-normal w-fit"
            >
              All
            </Checkbox>
          </div>
        </div>

        <div className="flex items-center gap-2">
          {/* <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="btn-action-item !py-2 !px-[10px] !rounded hover:!bg-[#272D3C] !bg-[#272D3C]"
          >
            <Button
              icon={<CommonIcons.ArrowDownIcon />}
              iconPosition="end"
              className={`${buttonTheme.className.standard} !text-xs !h-7 `}
            >
              <img className="!w-4" src={SortSvg} />
            </Button>
          </Dropdown> */}
          <ButtonIconComp
            item={{
              description: "View all on map",
              icon: FootSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            disabled={selectedImages?.length === 0}
            onClick={showAllInMap}
          />

          <ButtonIconComp
            item={{
              description:
                // elementsMatch.length === selectedImages.length
                //   ? "Delete all from cart" :
                "Add all to cart",
              icon: CartSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            onClick={(e: any) => {
              handleActionCart();
            }}
            disabled={selectedImages?.length <= 0}
          />

          <ButtonIconComp
            item={{
              description: "Close",
              icon: CloseSvg,
            }}
            onClick={() => {
              dispatch(resetState());
              // dispatch(changeLeftDrawerTypeBefore("Search results"));
              dispatch(changeLeftDrawerType("Filter"));
              // dispatch(resetLeftDrawer());
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          />
        </div>
      </div>
    );
  };

  const onShowInMap = (e: any, item: any) => {
    dispatch(setLoadingGlobal(true));
    e?.stopPropagation();
    let selectedCopy: Array<any> =
      selected && selected.length ? selected.slice() : [];
    let index = selectedCopy?.findIndex((x) => x.id === item?.id);

    if (index < 0) {
      selectedCopy.push(item);
      // addImage2Map(item);
    } else {
      selectedCopy.splice(index, 1);
      // removeImageFromMap(item);
    }

    dispatch(setSelected(selectedCopy));
    dispatch(setLoadingGlobal(false));
  };

  const viewImageOnMap = (e: any, item: any) => {
    dispatch(setLoadingGlobal(true));
    e?.stopPropagation();
    let arrImgViewOnMapCopy: Array<any> =
      arrImgViewOnMap && arrImgViewOnMap.length ? arrImgViewOnMap.slice() : [];
    let index = arrImgViewOnMap?.findIndex((x: any) => x.id === item?.id);

    if (index < 0) {
      arrImgViewOnMapCopy.push(item);
      addImage2Map(item);
    } else {
      arrImgViewOnMapCopy.splice(index, 1);
      removeImageFromMap(item);
    }

    setArrImgViewOnMap(arrImgViewOnMapCopy);

    dispatch(setLoadingGlobal(false));
  };

  // Chienbv add here 28/11/2024 11:00. Handle add image to map

  const addImage2Map = (item: any) => {
    let layer: any = map.getLayer(item?.id);
    if (layer) {
      map.setLayoutProperty(item?.id, "visibility", "visible");
      return;
    }
    let itemCopy = JSON.parse(JSON.stringify(item)); // Deep copy
    let bboxFeature = itemCopy?.bbox || null;
    if (!bboxFeature) {
      let geojson: any = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: item?.geometry,
          },
        ],
      };

      /**
       * https://turfjs.org/docs/api/bbox
       * import { bbox } from "@turf/bbox";
       * or import * as turf from "@turf/turf"; const result = turf.bbox(...);
       */

      bboxFeature = bbox(geojson);
      itemCopy.bbox = bboxFeature;
    }
    let providerName: string =
      arrProviders
        ?.filter((itemF: any) => itemF?.id === providerId)?.[0]
        ?.name.toLowerCase() || "";

    switch (providerName) {
      case "capella":
        addCapellaImage2Map(itemCopy);
        break;
      case "planet":
        addPlanetImage2Map(itemCopy);
        break;
      case "oneatlas":
        addOneatlasImage2Map(itemCopy);
        break;
      default:
        break;
    }
  };

  const getImage = async (thumbnailUrl: string) => {
    // if thumbnailUrl is not 401 or CORS is no need.
    const response = await axios.get(thumbnailUrl, { responseType: "blob" });

    if (response?.status === 200) {
      let contentType =
        response.headers && response.headers["content-type"]
          ? response.headers["content-type"]
          : "image/jpg";
      let blob = new Blob([response.data], {
        type: contentType,
      });

      if (blob.size != 0) {
        //@ts-ignore
        if (!window.navigator?.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          return window.URL.createObjectURL(blob);
        } else {
          // BLOB FOR EXPLORER 11
          //@ts-ignore
          return window.navigator.msSaveOrOpenBlob(blob, contentType);
        }
      }
    }
    return thumbnailUrl;
  };

  const addCapellaImage2Map = async (item: any) => {
    let bboxFeature = item?.bbox;
    let thumbnailUrl =
      item?.links?.filter((item: any) => item?.rel === "thumbnail")?.[0]
        ?.href || null;
    if (!thumbnailUrl) return;
    // backend call api to get image. because capella need token
    let imageUrl: any = await getImage(thumbnailUrl);
    // let imageUrl: any = thumbnailUrl;
    let newCoordinates: Array<any> | any = await getNewCoordinates(
      bboxFeature,
      imageUrl
    );
    addLayerImage(item?.id ?? uuidv4(), imageUrl, newCoordinates);
  };
  const addPlanetImage2Map = async (item: any) => {
    let bboxFeature = item?.bbox;
    let thumbnailUrl =
      item?.links?.filter((item: any) => item?.rel === "thumbnail")?.[0]
        ?.href || null;
    if (!thumbnailUrl) return;
    let imageUrl: any = await getImage(thumbnailUrl);
    let newCoordinates: Array<any> | any = await getNewCoordinates(
      bboxFeature,
      imageUrl
    );
    addLayerImage(item?.id ?? uuidv4(), imageUrl, newCoordinates);
  };
  const addOneatlasImage2Map = async (item: any) => {
    let bboxFeature = item?.bbox;
    let thumbnailUrl =
      item?.links?.filter((item: any) => item?.rel === "thumbnail")?.[0]
        ?.href || null;

    if (!thumbnailUrl) return;
    let imageUrl: any = await getImage(thumbnailUrl);
    let coordinates = [];
    coordinates.push([bboxFeature[0], bboxFeature[3]]); // top left
    coordinates.push([bboxFeature[2], bboxFeature[3]]); // top right
    coordinates.push([bboxFeature[2], bboxFeature[1]]); // bottom right
    coordinates.push([bboxFeature[0], bboxFeature[1]]); // bottom left

    addLayerImage(item?.id ?? uuidv4(), imageUrl, coordinates);
  };

  const addLayerImage = (
    id: string,
    imageUrl: any,
    coordinates: Array<any>
  ) => {
    map.addSource("source_" + id, {
      type: "image",
      url: imageUrl,
      coordinates: coordinates,
    });

    map.addLayer({
      id: id,
      type: "raster",
      source: "source_" + id,
    });
  };

  /**
   * image with size 256 x 256. Bbox of feature is not cover the image
   * @param coordinates
   * @param thumbnailUrl
   * @returns
   */
  const getNewCoordinates = (bbox: Array<any>, thumbnailUrl: string) => {
    return new Promise(function (resolve, reject) {
      let coordinates = [
        [bbox[0], bbox[3]],
        [bbox[2], bbox[3]],
        [bbox[2], bbox[1]],
        [bbox[0], bbox[1]],
      ];

      // Giả sử Bbox là 1 hình chữ nhật với 2 cạnh là AB và AD. Với AB theo trục X(longitude) và AD theo trục Y(latitude)
      // Tính toán khoảng trống transparent để + hoặc - thêm độ dài tọa độ tương ứng

      let distance_AB = Math.abs(coordinates[0][0] - coordinates[1][0]);
      let distance_AD = Math.abs(coordinates[0][1] - coordinates[3][1]);
      // let img1 = new Image();
      // img1.src = thumbnailUrl;
      // img1.onload = function () {
      //   alert("image is loaded");
      // };
      try {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = thumbnailUrl;
        img.setAttribute("crossOrigin", "");
        img.onerror = function (err) {};
        img.onload = function () {
          var canvas2 = document.createElement("canvas");
          // Get the 2D context of the canvas
          let context = canvas2.getContext && canvas2.getContext("2d");
          let width = 0;
          let height = 0;
          height = canvas2.height =
            img.naturalHeight || img.offsetHeight || img.height;
          width = canvas2.width =
            img.naturalWidth || img.offsetWidth || img.width;

          if (!context) reject(null);

          // Draw the image to the canvas
          //@ts-ignore
          context.drawImage(img, 0, 0);
          let pixels2 = [];

          let isBreak = false;
          // Get the data of the image

          for (let i = 0; i < width; i++) {
            for (let j = 0; j < height; j++) {
              //@ts-ignore
              let data = context.getImageData(i, j, 1, 1);
              if (
                ((data.data[0] != 0 || data.data[1]) != 0 ||
                  data.data[2] != 0) &&
                data.data[3] > 0
              ) {
                // Left
                pixels2.push([i, j]);
                isBreak = true;
                break;
              }
            }
            if (isBreak) break;
          }
          isBreak = false;
          for (let i = 0; i < height; i++) {
            for (let j = 0; j < width; j++) {
              //@ts-ignore
              let data = context.getImageData(j, i, 1, 1);
              if (
                ((data.data[0] != 0 || data.data[1]) != 0 ||
                  data.data[2] != 0) &&
                data.data[3] > 0
              ) {
                //Top
                pixels2.push([j, i]);
                isBreak = true;
                break;
              }
            }
            if (isBreak) break;
          }
          isBreak = false;
          for (let i = width - 1; i >= 0; --i) {
            for (let j = 0; j < height; j++) {
              //@ts-ignore
              let data = context.getImageData(i, j, 1, 1);
              if (
                ((data.data[0] != 0 || data.data[1]) != 0 ||
                  data.data[2] != 0) &&
                data.data[3] > 0
              ) {
                //Right
                pixels2.push([i, j]);
                isBreak = true;
                break;
              }
            }
            if (isBreak) break;
          }
          isBreak = false;
          for (let i = height - 1; i >= 0; --i) {
            for (let j = 0; j < width; j++) {
              //@ts-ignore
              let data = context.getImageData(j, i, 1, 1);
              if (
                ((data.data[0] != 0 || data.data[1]) != 0 ||
                  data.data[2] != 0) &&
                data.data[3] > 0
              ) {
                //Bottom;
                pixels2.push([j, i]);
                isBreak = true;
                break;
              }
            }
            if (isBreak) break;
          }

          let left_2_A = pixels2[0][0];
          let right_2_B = pixels2[2][0];

          let top_2_A = pixels2[1][1];
          let bottom_2_D = pixels2[3][1];
          //Tinh do dai cua 1 don vi = chieu rong / kich thuoc pixel cua anh.
          // AB là chieu rong , AD chieu cao
          let pixel_AB = distance_AB / (width - left_2_A - (width - right_2_B));

          let pixel_AD =
            distance_AD / (height - top_2_A - (height - bottom_2_D));
          // Calculator new coordinates
          // + hoac - khoang transparent cua anh
          coordinates[0][0] = coordinates[0][0] - pixel_AB * pixels2[0][0];
          coordinates[3][0] = coordinates[3][0] - pixel_AB * pixels2[0][0];

          coordinates[0][1] = coordinates[0][1] + pixel_AD * pixels2[1][1];
          coordinates[1][1] = coordinates[1][1] + pixel_AD * pixels2[1][1];

          coordinates[1][0] =
            coordinates[1][0] + pixel_AB * (width - pixels2[2][0]);
          coordinates[2][0] =
            coordinates[2][0] + pixel_AB * (width - pixels2[2][0]);

          coordinates[2][1] =
            coordinates[2][1] - pixel_AD * (height - pixels2[3][1]);
          coordinates[3][1] =
            coordinates[3][1] - pixel_AD * (height - pixels2[3][1]);

          resolve(coordinates);
        };
      } catch (error) {
        resolve(null);
      }
    });
  };

  const removeImageFromMap = (item: any) => {
    let layer: any = map.getLayer(item?.id);
    if (layer) {
      map.setLayoutProperty(item?.id, "visibility", "none");
    }

    // can use removeLayer() to remove layer
    //if (map.getLayer(item?.id)) map.removeLayer(item?.id);
  };

  // End Chienbv add here 28/11/2024 11:00. Handle add image to map

  const renderActionItem = (item?: any) => {
    let providerName = arrProviders
      ?.filter((itemF: any) => itemF?.id === providerId)?.[0]
      ?.name.toLowerCase();
    let imageInCard = arrIdImage?.find(
      (itemImage: any) => itemImage === item?.properties[`${providerName}:id`]
    );
    const arrAction = [
      {
        id: 1,
        description: "Show on map",
        icon: FootSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          onShowInMap(e, item);
        },
      },
      {
        id: 4,
        description: "View on map",
        icon: ViewMapSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          viewImageOnMap(e, item);
        },
      },
      {
        id: 2,
        description: imageInCard ? "Delete from cart" : "Add to cart",
        icon: CartSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          // deleteMultiImages([item?.properties?.[`${providerName}:id`]])
          if (imageInCard) {
            toggleConfirmRemoveCart();
            setImagesSelected({
              title: "Remove image",
              subTitle: "Are you sure you want to remove this image ?",
              images: [item?.properties?.[`${providerName}:id`]],
              acction: "remove",
            });
          } else {
            toggleConfirmRemoveCart();
            setImagesSelected({
              title: "Add image",
              subTitle: "Are you sure you want to add this image ?",
              images: [item?.properties?.[`${providerName}:id`]],
              acction: "add",
            });
            // handleAddCart(e, [item?.properties?.[`${providerName}:id`]]);
          }
        },
      },
      {
        id: 3,
        description: "Pan to",
        icon: PositionSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          panToAndExtend(e, item, map);
        },
      },
    ];

    return (
      <div className="flex gap-2">
        {arrAction?.map((itemM: any, ind: any) => {
          let classImage = "";
          switch (itemM?.id) {
            case 1:
              classImage = selected.map((x: any) => x?.id)?.includes(item?.id)
                ? " btn-action-active"
                : "";
              break;

            case 4:
              classImage = arrImgViewOnMap
                .map((x: any) => x?.id)
                ?.includes(item?.id)
                ? " btn-action-active"
                : "";
              break;

            case 2:
              classImage = !!imageInCard ? " btn-action-active" : "";
              break;
            default:
              break;
          }
          return (
            <ButtonIconComp
              key={ind}
              item={{ description: itemM?.description, icon: itemM?.icon }}
              onClick={(e: any) => itemM?.onClick(e)}
              className={classImage}
            />
          );
        })}
      </div>
    );
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    const providerActive = arrProviders?.find(
      (item: any) => item?.id === providerId
    );
    const _imgs: any[] = [];
    setSelectAll(value);

    if (value) {
      _imgs.push(
        ...dataImages?.map((img: any) => {
          return `${
            img?.properties?.[`${providerActive?.name.toLowerCase()}:id`]
          }`;
        })
      );
    }

    setSelectedImages(_imgs);
  };

  const onSelectImage = (e: any, value: any, item: any) => {
    const providerActive = arrProviders?.find(
      (item: any) => item?.id === providerId
    );
    const _imgs = JSON.parse(JSON.stringify(selectedImages)) as string[];

    if (!item.id) {
      return;
    }

    if (
      _imgs.includes(
        `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
      )
    ) {
      setSelectedImages(
        _imgs.filter((img) => {
          return (
            img !==
            `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
          );
        })
      );
      return;
    }

    _imgs.push(
      `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
    );

    setSelectedImages(_imgs);
  };
  const renderListImage = () => {
    return dataImages?.map((item: any, ind: any) => {
      let urlImage = item?.links?.filter(
        (item: any) => item?.rel === "thumbnail"
      )?.[0]?.href;

      let providerName = arrProviders
        ?.filter((itemF: any) => itemF?.id === providerId)?.[0]
        ?.name.toLowerCase();

      return (
        <div
          key={ind}
          className="div-item relative"
          onMouseLeave={() => onMouseLeaveItem(item, map, sourceId)}
          onMouseEnter={() => onMouseEnterItem(item, map, sourceId)}
        >
          <img
            src={urlImage}
            loading="lazy"
            onError={(e: any) => {
              e.target.src = ImageDefault;
            }}
            className="!max-h-[310px]"
            // onLoad={(e: any) => {
            //   e.target.src = urlImage;
            // }}
          />
          <Checkbox
            className="text-sm text-common-white font-normal w-fit absolute top-1 left-[5px] z-10"
            checked={selectedImages.includes(
              `${item?.properties?.[`${providerName}:id`]}`
            )}
            onClick={(e: any) => e?.stopPropagation()}
            onChange={(e: any) => {
              onSelectImage(e, e.target.value, item);
            }}
          />
          <div
            className="div-des-item relative"
            onClick={(e: any) => {
              handleInfoImage(item);
            }}
          >
            <span className="text-title-item">{item?.id}</span>
            <div className="flex justify-between items-center text-des-item mt-2">
              <span>
                {moment(item?.properties.datetime).format(
                  "HH:mm:ss DD/MM/YYYY"
                )}
              </span>

              {item?.properties["eo:cloud_cover"] && (
                <span className="flex sub-des">
                  {item?.properties["eo:cloud_cover"] &&
                    (item?.properties["eo:cloud_cover"].toFixed(1) ?? 0)}{" "}
                  % <img src={CloudSvg} className="ml-1" />
                </span>
              )}
            </div>
            <div className="div-des-item-more mt-2 mb-3">
              {RenderMoreDesItem({
                data: [
                  typeImage !== ETypeImage.SAR
                    ? {
                        icon: CloudSvg,
                        val: item?.properties["eo:cloud_cover"]
                          ? item?.properties["eo:cloud_cover"]?.toFixed(1)
                          : 0,
                        label: "eo:cloud_cover",
                      }
                    : null,
                  {
                    icon: Icon1,
                    val: item?.properties?.platform,
                    label: "Platform",
                  },
                  {
                    icon: Icon2,
                    val: item?.properties?.gsd,
                    label: "gsd",
                  },
                  {
                    icon: Icon3,
                    val: item?.properties["view:off_nadir"].toFixed(1),
                    label: "view:off_nadir",
                  },
                  {
                    icon: Icon4,
                    val: item?.properties["view:sun_azimuth"].toFixed(1),
                    label: "view:sun_azimuth",
                  },
                  {
                    icon: Icon5,
                    val: item?.properties["sar:polarizations"],
                    label: "sar:polarizations",
                  },
                ],
              })}
            </div>

            <div className="div-action-item absolute bottom-4">
              {renderActionItem(item)}
            </div>
          </div>
        </div>
      );
    });
  };
  const handleChangeProvider = (item: any) => {
    //  @ts-ignore
    if (searchState[`loading_provider_${item.id}`]) {
      const targetDiv: any = document.getElementById("resultList");
      if (targetDiv) targetDiv.scrollIntoView({ behavior: "smooth" });
      dispatch(setLoadingProvider({ id: item.id, value: false }));
      dispatch(setProviderId(item?.id));
      setClickChangeProvider(true);

      dispatch(
        //  @ts-ignore
        setDataImages(searchState[`data_images_${item.id}`])
      );

      dispatch(setLoadingProvider({ id: item.id, value: true }));
      dispatch(setSelected([]));
      setClickChangeProvider(false);
      return;
    }
  };

  const renderProvider = () => {
    let a = arrProviders?.map((item: any, ind: any) => {
      let url = item?.metadatas?.filter(
        (itemF: any) => itemF?.key === "LOGO"
      )?.[0]?.value;

      return (
        <Tooltip title={item?.name}>
          <Spin
            style={{
              //  @ts-ignore
              cursor: searchState[`loading_provider_${item.id}`]
                ? "default"
                : "no-drop",
            }}
            //  @ts-ignore
            // spinning={!searchState[`loading_provider_${item.id}`]}
            spinning={!searchState[`loading_provider_${item.id}`]}
            // indicator={<LoadingUI />}
          >
            <div
              key={ind}
              className={
                "h-[68px] w-[68px] min-h-[68px] div-one-center !justify-center !max-h-[68px] " +
                (providerId === item?.id ? "active-provider-item" : "")
              }
              style={{
                //  @ts-ignore
                cursor: searchState[`loading_provider_${item.id}`]
                  ? "pointer"
                  : "no-drop",
              }}
              onClick={() => {
                handleChangeProvider(item);
              }}
            >
              <div
                className={"div-provider-item relative"}
                style={{
                  background: `url(${url})`,
                  backgroundSize: "cover",
                  //  @ts-ignore
                  cursor: searchState[`loading_provider_${item.id}`]
                    ? "pointer"
                    : "no-drop",
                }}
              >
                {(item?.total > 0 ||
                  // @ts-ignore
                  searchState[`total_images_${item.id}`] > 0) && (
                  <div
                    className="div-total absolute bottom-0 div-one-center"
                    style={{
                      //  @ts-ignore
                      cursor: searchState[`loading_provider_${item.id}`]
                        ? "pointer"
                        : "no-drop",
                    }}
                  >
                    {/* @ts-ignore */}
                    {searchState[`total_images_${item.id}`] ?? item?.total}
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </Tooltip>
      );
    });

    return a;
  };

  const showAllInMap = () => {
    const arr = dataImages?.filter((itemF: any) => {
      let id: any;

      for (const key in itemF?.properties) {
        if (key.endsWith(":id")) {
          id = itemF?.properties[key];
          break;
        }
      }

      return selectedImages?.includes(id);
    });

    dispatch(setSelected(arr));
  };

  const getDataInmagsByNextLink = async (link: any) => {
    if (!link) return;

    try {
      dispatch(setLoadingGlobal(true));

      const res = await axios.get(link);

      const newArr = [...dataImages, ...(res?.data?.data?.features || [])];
      const links = res?.data?.data?.links;
      dispatch(setDataImages(newArr));

      dispatch(
        setDataImagesProvider({
          id: providerId,
          data: newArr,
          page: 1,
          nextLink: links?.find((item: any) => item?.rel === "next")?.href,
          firstLink: links?.find((item: any) => item?.rel === "first")?.href,
          total: newArr?.length,
        })
      );
    } catch (error) {
      dispatch(setLoadingProvider({ id: providerId, value: true }));
    } finally {
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      dispatch(setLoadingGlobal(false));
    }
  };

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Kiểm tra nếu đã cuộn xuống cuối danh sách

    if (clickChangeProvider) return;
    if (scrollTop + clientHeight >= scrollHeight && scrollTop > 0) {
      // @ts-ignore
      let nextLink = searchState[`next_link_${providerId}`];
      // @ts-ignore
      let firstLink = searchState[`first_link_${providerId}`];

      if (nextLink && nextLink !== "" && nextLink !== firstLink) {
        dispatch(setLoadingGlobal(true));
        dispatch(setLoadingProvider({ id: providerId, value: false }));
        getDataInmagsByNextLink(nextLink);
      } else {
        // @ts-ignore
        let total = searchState[`total_images_${providerId}`];

        if (total > dataImages.length) {
          dispatch(setLoadingProvider({ id: providerId, value: false }));
          dispatch(setLoadingGlobal(true));
          search();
        }
      }
    }
  };

  useEffect(() => {
    if (flagSearch) {
      searchAllProviders();
    }
  }, [flagSearch]);

  useEffect(() => {
    setSelectedImages([]);
    setSelectAll(false);
    dispatch(setSelected([]));
  }, [typeImage, providerId]);

  return (
    <div className="h-full p-3 pt-0 overflow-hidden !w-[460px]">
      <div className="w-full sticky top-0 z-10 bg-common-ink100 py-3 h-full">
        {renderHeaderDrawer()}

        <Divider className="bg-common-line my-2" />

        <div
          className="flex gap-2 mt-4 justify-between"
          style={{ height: `calc(100% - 36px)` }}
        >
          <div
            className="overflow-auto relative h-full pr-2 !overflow-x-hidden div-show"
            onScroll={handleScroll}
            style={{
              overflowY: !loading ? "hidden" : "unset",
            }}
          >
            <div id="resultList" />

            <Spin className="div-spin h-full" spinning={!loading}>
              <div className="relative div-list-image h-full gap-y-4">
                {dataImages?.length === 0 && loading ? (
                  <div className="py-5 text-center text-common-white">
                    No search results found!
                  </div>
                ) : null}
                {dataImages?.length > 0 && renderListImage()}
              </div>
            </Spin>
          </div>
          <div className="div-list-provider !justify-start div-one-center parent">
            <div className="child gap-3">{renderProvider()}</div>
          </div>
        </div>
      </div>

      {shouldRender && (
        <CommonComponents.DrawerUI open={open} placement="right" width={460}>
          <ImageInformation hidden={false} item={imageInfo} onClose={toggle} />
        </CommonComponents.DrawerUI>
      )}

      {shouldRenderConfirmRemoveCart && (
        <CommonComponents.ModalUI
          open={openConfirmRemoveCart}
          toggle={toggleConfirmRemoveCart}
          handleConfirm={() => {
            if (imagesSelected && imagesSelected.acction === "remove") {
              deleteMultiImages(imagesSelected.images);
              toggleConfirmRemoveCart();
            } else {
              handleAddCart(imagesSelected.images as any);
              toggleConfirmRemoveCart();
            }
          }}
          title={imagesSelected.title}
        >
          <div className="text-common-white">{imagesSelected.subTitle}</div>
        </CommonComponents.ModalUI>
      )}
    </div>
  );
};

export default DrawerListImagesSeacrhComp;
