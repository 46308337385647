export const checkImageUploadS3 = (obj: any) => {
  const orderDetails = obj?.order_details ?? [];

  if (orderDetails.length > 0) {
    for (const detail of orderDetails) {
      // if (detail?.is_image_to_s3 === false || detail?.is_image_to_s3 === null) {
      //   return false;
      // }
      if (
        detail?.is_image_to_s3 === true &&
        detail?.is_image_download_mode_auto === false
      ) {
        // manual
        return false;
      }
    }

    return true;
  }
};
