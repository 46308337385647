import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/MapTasking/Footer";
import { resetDrawMode } from "../../components/Map/ultis";
import Header from "../../components/MapTasking/Header";
import MapTaskingPlugin from "../../components/MapTasking/Map";
import { DrawType } from "../../enums";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  resetAllDrawer,
} from "../../store/reducers/drawerSlice";
import {
  clearFeatures,
  setDrawType,
  setSelected,
  setShowRecenter,
} from "../../store/reducers/mapTaskingSlice";
import { resetState } from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";
import { LeftDrawerType } from "../../types/common";
import * as turf from "@turf/turf";
import ButtonIconComp from "../../components/MapTasking/components/ButtonIcon";
import ReCenterSvg from "../../assets/svg/re-center.svg";
import { changeLayoutName } from "../../store/reducers/layoutSlice";
import { Spin } from "antd";
import CommonComponents from "../../components/Commons";
import LoadingUI from "../../components/Commons/Loading";

const ExploreOrderTaskingPage = () => {
  const dispatch = useDispatch();
  const drawType = useSelector((state: RootState) => state.mapTasking.drawType);

  const leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );

  const bottomDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.bottomDrawerType
  );

  const clickFilter = useSelector(
    (state: RootState) => state.mapTasking.clickFilter
  );

  const map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  const draw = useSelector((state: RootState) => state.mapTasking.draw);
  const activePolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );
  const showRecenter = useSelector(
    (state: RootState) => state.mapTasking.showRecenter
  );

  const isSearching = useSelector(
    (state: RootState) => state.search.isSearching
  );

  const [loading, setLoading] = useState(false);
  const onChangeLeftDrawerType = (type: LeftDrawerType) => {
    if (leftDrawerType === type && !isSearching) {
      dispatch(changeLeftDrawerType(""));
      return;
    } else if (leftDrawerType === type && isSearching) {
      dispatch(changeLeftDrawerType("Search results"));
      return;
    }
    dispatch(changeLeftDrawerType(type));
  };

  const onChangeDrawType = (type: DrawType) => {
    if (drawType === type) {
      return;
    }

    if (type === DrawType.UPLOAD && leftDrawerType !== "Upload") {
      onChangeLeftDrawerType("Upload");
    }

    if (type === DrawType.NONE && leftDrawerType === "Upload") {
      onChangeLeftDrawerType("Upload");
    }

    let mode = "";
    switch (type) {
      case DrawType.RECTANGLE:
        mode = "draw_rectangle";
        break;

      case DrawType.POLYGON:
        mode = "draw_polygon";
        break;

      default:
        dispatch(setDrawType(undefined));
        // setDrawType(undefined);
        break;
    }

    if (!mode) {
      resetDrawMode(map, draw);
      return;
    }

    map.getCanvas().style.cursor = "crosshair";

    draw?.changeMode(mode);

    dispatch(setDrawType(type));
  };

  const onChangeBottomDrawerType = (type: any) => {
    if (bottomDrawerType === type) {
      dispatch(changeShowBottomDrawer(false));
      dispatch(changeBottomDrawerType(""));
      return;
    }
    dispatch(changeShowBottomDrawer(true));
    dispatch(changeBottomDrawerType(type));
  };

  useEffect(() => {
    dispatch(resetAllDrawer());
    dispatch(changeLayoutName("/explore-tasking"));

    return () => {
      dispatch(resetAllDrawer());
      dispatch(resetState());
      dispatch(clearFeatures());
      dispatch(setSelected([]));
      dispatch(changeLayoutName(""));
    };
  }, []);

  const handleRecenter = () => {
    if (activePolygon) {
      const polygonCenter = turf.center(activePolygon).geometry.coordinates;
      map.flyTo({ center: polygonCenter, zoom: 12 });
      dispatch(setShowRecenter(false));
    }
  };

  return (
    <ContentLayoutManagement
      pageName="Map"
      zIndex={10}
      header={
        <Header
          drawType={drawType}
          onChangeDrawType={onChangeDrawType}
          onShowFilter={() => {
            onChangeLeftDrawerType("Filter");
          }}
          clickFilter={clickFilter}
          type="map-tasking"
          loading={loading}
        />
      }
      footer={
        <Footer
          onShowPolygonTemplateList={() =>
            onChangeBottomDrawerType("Polygon template list tasking")
          }
        />
      }
    >
      <Spin spinning={loading} indicator={<LoadingUI />}>
        <div className="h-full w-full relative map-tasking">
          <div className={`relative !h-screen`}>
            <MapTaskingPlugin
              showOnConfirm={false}
              type="map-tasking"
              loading={loading}
              setLoading={(val: any) => setLoading(val)}
            />

            {showRecenter && (
              <ButtonIconComp
                item={{
                  description: "Re-center AOI",
                  icon: ReCenterSvg,
                  title: "Re-center AOI",
                }}
                className="absolute !bg-[#fff] bottom-2 z-[999] !h-8 !w-[30px] !min-w-[30px] !rounded flex items-center left-[50%]"
                // disabled={selectedImages?.length === 0}
                onClick={() => {
                  handleRecenter();
                }}
                colorText="black"
              />
            )}
          </div>
        </div>
      </Spin>
    </ContentLayoutManagement>
  );
};

export default ExploreOrderTaskingPage;
