import DefaultImg from "../../assets/images/default.png";
import { ConvertTimeStamp } from "../../helpers/common";
import ButtonIconComp from "../MapTasking/ButtonIconComp";
import ShowOnMapSvg from "../../assets/svg/foot.svg";

const CardItemConfirmComp = (props: any) => {
  return (
    <div
      onClick={() => {
        if (props?.onClick()) {
          props?.onClick();
        }
      }}
      onMouseLeave={() => {
        props?.onMouseLeaveItem && props?.onMouseLeaveItem();
      }}
      onMouseEnter={() => {
        props?.onMouseEnterItem && props?.onMouseEnterItem();
      }}
      style={props?.style}
      className="w-full flex cursor-pointer items-center p-3 bg-[#2C3243]  hover:!bg-[#3E4353] gap-3 !h-[80px] !rounded-[3px] mb-2"
    >
      <div className="grow flex items-center gap-3">
        <div
          className="flex-none !w-[52px]  bg-black rounded relative"
          style={{ border: "1px solid #FFFFFF33" }}
        >
          <img
            src={
              props?.item?.feature?.links?.filter(
                (itemF: any) => itemF?.rel === "thumbnail"
              )?.[0]?.href
            }
            alt=""
            className="!w-[52px] !h-[52px] object-contain"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = DefaultImg;
            }}
            loading="lazy"
          />
        </div>

        <div className="grow h-full flex flex-col">
          <div className="text-base font-semibold text-common-white break-words break-all !mb-1 line-clamp-1">
            {props?.item?.feature?.id}
          </div>

          <div className="flex justify-between items-center text-sm font-normal text-common-white">
            <div className="text-one-line">
              {props?.item?.feature?.properties?.datetime && (
                <>
                  {ConvertTimeStamp(props?.item?.feature?.properties?.datetime)}{" "}
                  |{" "}
                </>
              )}{" "}
              {props?.item?.feature?.properties["eo:cloud_cover"] && (
                <span>
                  {props?.item?.feature?.properties["eo:cloud_cover"].toFixed(
                    2
                  )}{" "}
                  %
                </span>
              )}
            </div>
          </div>
        </div>

        <div>
          <ButtonIconComp
            key={1}
            item={{ description: "Show on map", icon: ShowOnMapSvg }}
            onClick={() => {
              if (props?.onClick()) {
                props?.onClick();
              }
            }}
            className={props?.selected ? "btn-action-active" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default CardItemConfirmComp;
