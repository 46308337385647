import React, { useEffect, useState } from "react";
import CommonIcons from "../../assets/icons";
import CommonComponents from "../Commons";
import { Divider, Tabs, TabsProps } from "antd";
import CommonImages from "../../assets/images";
import Assets from "../Map/ImageInformation/Assets";
import Metadata from "../Map/ImageInformation/Metadata";
import { ZoomLens } from "../../helpers/common";

interface IProps {
  hidden?: boolean;
  item?: any;
  forceRender?: () => void;
  onClose?: () => void;
}

function getThumbnail(links: any[]): string {
  if (!links || !links.length) {
    return "";
  }

  const t = links.find((l) => {
    return l.rel === "thumbnail";
  });

  return t?.href ?? "";
}

export default function ImageInformation({
  hidden = true,
  item,
  forceRender,
  onClose,
}: IProps) {
  const [renderKey, setRenderKey] = useState<number>(0);

  useEffect(() => {
    setRenderKey(renderKey + 1);
  }, [item]);

  const onChange = (key: string) => {};

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Metadata",
      children: <Metadata item={item} />,
    },
    {
      key: "2",
      label: "Assets",
      children: <Assets assets={item?.assets} />,
    },
  ];

  useEffect(() => {
    ZoomLens();
  });
  return (
    <div
      key={renderKey}
      hidden={hidden}
      className="w-[460px] h-full p-3 overflow-x-auto"
    >
      <div className="flex justify-between items-center py-1 sticky -top-3 z-10 bg-common-ink100">
        <div className="flex items-center gap-2 text-sm font-normal text-common-white">
          <div className="scale-125">
            <CommonIcons.InfoCircleIcon />
          </div>
          <div>Information</div>
        </div>

        <div className="flex items-center gap-2">
          <CommonComponents.ButtonUI
            icon={<CommonIcons.DismissIcon />}
            commonType="standard"
            className="!text-xs !h-7"
            onClick={onClose}
          ></CommonComponents.ButtonUI>
        </div>
      </div>

      <Divider className="border-common-line !mt-1 !mb-2" />

      <div className="flex flex-col gap-1">
        <div className="w-full h-[200px] bg-black flex justify-center items-center relative">
          <img
            className="max-w-full max-h-full"
            src={getThumbnail(item?.links)}
            alt="Magnifiable Image"
            id="magnifiable-image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = CommonImages.DefaultSearchRestultImage;
            }}
          />
        </div>
        <div className="text-sm text-white font-medium mt-2 break-words">
          {item?.id}
        </div>

        <Tabs
          className="tabs-cus [&_div.ant-tabs-nav]:!m-0 [&_div.ant-tabs-nav-list]:!grid [&_div.ant-tabs-nav-list]:!grid-cols-2 [&_div.ant-tabs-tab]:flex [&_div.ant-tabs-tab]:justify-center"
          defaultActiveKey="1"
          items={tabItems}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
