import { Divider, Form, Radio, Slider } from "antd";
import CommonIcons from "../../assets/icons";
import ButtonIconComp from "./components/ButtonIcon";

import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../assets/svg/dismiss.svg";
import { DefaultFormatDateTime, ETypeImage } from "../../enums";
import {
  changeLeftDrawerType,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeTypeImage,
  setArrProviders,
  setCommonFilter,
  setFlagSearch,
  setProviderId,
  setSelected,
} from "../../store/reducers/mapTaskingSlice";
import { activeLoadingProvider } from "../../store/reducers/searchSlice";
import { ChangeActionActiveRight } from "../../store/reducers/statusAction";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";
import { dataResolution } from "./components/data";

const DrawerFilterSearchComp = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  let commonFilter = useSelector(
    (state: RootState) => state.mapTasking.commonFilter
  );
  const typeImage = useSelector(
    (state: RootState) => state.mapTasking.typeImage
  );

  const arrImageTypes = useSelector((state: RootState) =>
    state.appCommonSlice.metaKey?.filter(
      (itemF: any) => itemF?.key === "IMAGE_TYPE_SEARCH"
    )
  );

  const allProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  const isSearching = useSelector(
    (state: RootState) => state.search.isSearching
  );

  const handleChangeCommonFilter = (key: string, value: any) => {
    dispatch(setCommonFilter({ ...commonFilter, [key]: value }));
  };
  const checkTypeImageIncludeProvider = (typeImage: string) => {
    let listProviderMatch = allProviders?.filter((itemF: any) =>
      itemF?.metadatas?.some(
        (itemMF: any) =>
          itemMF?.name === "IMAGE_TYPE_SEARCH" &&
          itemF?.enabled_archive &&
          itemMF?.value === typeImage
      )
    );
    return listProviderMatch;
  };

  const disabledDate: any = (current: any, type: any) => {
    if (type === "timeFrom") {
      return (
        current && current > dayjs(form.getFieldValue("timeTo")).endOf("day")
      );
    } else {
      return (
        current && current < dayjs(form.getFieldValue("timeFrom")).endOf("day")
      );
    }
  };

  useEffect(() => {
    dispatch(ChangeActionActiveRight("Filter"));

    let a = allProviders?.filter((itemF: any) =>
      itemF?.metadatas?.some(
        (itemMF: any) =>
          itemMF?.name === "IMAGE_TYPE_SEARCH" &&
          itemF?.enabled_archive &&
          itemMF?.value === typeImage
      )
    );
    dispatch(setArrProviders(a));

    return () => {
      dispatch(ChangeActionActiveRight(""));
    };
  }, []);
  const renderHeaderDrawer = () => {
    return (
      <div className="flex justify-between items-center py-1">
        <div className="flex items-center gap-3 text-sm font-normal text-common-white">
          <div className="scale-125">
            <CommonIcons.FilterIcon />
          </div>{" "}
          Filter
        </div>

        <div className="flex items-center gap-2">
          {/* <ButtonIconComp
            item={{
              description: "View all in map",
              icon: ApplySvg,
              title: "Apply",
            }}
            className="!h-7 !px-3 !rounded !bg-[#272D3C]"
            onClick={handleApply}
          /> */}

          <ButtonIconComp
            item={{
              description: "Close",
              icon: CloseSvg,
            }}
            onClick={() => {
              if (isSearching) {
                dispatch(changeLeftDrawerType("Search results"));
              } else {
                dispatch(resetLeftDrawer());
              }
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Form
        form={form}
        initialValues={{
          ...commonFilter,
          timeFrom:
            commonFilter.timeFrom && dayjs(commonFilter.timeFrom).isValid()
              ? dayjs(commonFilter.timeFrom)
              : undefined,
          timeTo:
            commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
              ? dayjs(commonFilter.timeTo)
              : undefined,
        }}
        layout="vertical"
      >
        <div className="w-full">
          <div className="text-sm text-common-white">Time</div>

          <div className="grid grid-cols-2 gap-3 pt-3 w-full">
            <CommonComponents.FormFieldUI
              name="timeFrom"
              noStyle
              component={
                <CommonComponents.DatePickerUI
                  allowClear={false}
                  className="w-full"
                  onChange={(date) => {
                    handleChangeCommonFilter("timeFrom", date?.valueOf());
                  }}
                  disabledDate={(val) => disabledDate(val, "timeFrom")}
                  defaultValue={
                    commonFilter.timeFrom &&
                    dayjs(commonFilter.timeFrom).isValid()
                      ? dayjs(commonFilter.timeFrom)
                      : undefined
                  }
                  value={
                    commonFilter.timeFrom &&
                    dayjs(commonFilter.timeFrom).isValid()
                      ? dayjs(commonFilter.timeFrom)
                      : undefined
                  }
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  placeholder="From"
                />
              }
            />

            <CommonComponents.FormFieldUI
              name="timeTo"
              noStyle
              component={
                <CommonComponents.DatePickerUI
                  allowClear={false}
                  className="w-full"
                  maxDate={dayjs()}
                  onChange={(date) => {
                    handleChangeCommonFilter("timeTo", date?.valueOf());
                  }}
                  defaultValue={
                    commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
                      ? dayjs(commonFilter.timeTo)
                      : undefined
                  }
                  value={
                    commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
                      ? dayjs(commonFilter.timeTo)
                      : undefined
                  }
                  placeholder="To"
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  disabledDate={(val) => disabledDate(val, "timeTo")}
                />
              }
            />
          </div>
        </div>
        <Divider className="bg-common-line my-4" />

        <div>
          <div className="text-sm text-common-white font-medium">
            Resolution
          </div>
          <div className="grid grid-cols-1 gap-3 pt-3">
            <Radio.Group
              className="flex flex-col gap-3"
              value={commonFilter.resolution}
              onChange={(e) => {
                // setValueRadio(e.target.value);
                handleChangeCommonFilter("resolution", e.target.value);
              }}
            >
              {dataResolution?.map((itemM: any, ind: any) => (
                <Radio value={itemM?.value} key={ind}>
                  <p className="mt-2"> {itemM?.title}</p>
                  <p>
                    Area size:
                    {itemM?.areaSize?.[0] && itemM?.areaSize?.[0] + ` km²`}
                    {itemM?.areaSize?.[1]
                      ? `- ${itemM?.areaSize?.[1]} km²`
                      : ""}
                  </p>
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>

        <Divider className="bg-common-line my-4" />

        {typeImage !== ETypeImage.SAR && (
          <>
            <div>
              <div className="text-sm text-common-white">
                Cloud cover [0-100] (%)
              </div>
              <div className="grid grid-cols-1 gap-3 p-3">
                <CommonComponents.FormFieldUI
                  name="cloudCover"
                  noStyle
                  component={
                    <Slider
                      range
                      defaultValue={commonFilter.cloudCover}
                      value={commonFilter.cloudCover}
                      onChangeComplete={(value) => {
                        handleChangeCommonFilter("cloudCover", value);
                      }}
                    />
                  }
                />
              </div>
            </div>
            <Divider className="bg-common-line mt-[10px] mb-[10px]" />
          </>
        )}

        <div>
          <div className="text-sm text-common-white font-medium">
            Incidence angle [0-90](°)
          </div>
          <div className="grid grid-cols-1 gap-3 p-3">
            <CommonComponents.FormFieldUI
              name="incidenceAngle"
              noStyle
              component={
                <Slider
                  range
                  defaultValue={commonFilter.incidenceAngle}
                  value={commonFilter.incidenceAngle}
                  min={0}
                  max={90}
                  onChangeComplete={(value) => {
                    handleChangeCommonFilter("incidenceAngle", value);
                  }}
                />
              }
            />
          </div>
        </div>
      </Form>
    );
  };

  const renderSelectTypeImage = () => {
    return arrImageTypes?.map((item: any, ind: any) => {
      const providerMatch = checkTypeImageIncludeProvider(item?.value);
      return (
        <CommonComponents.TooltipUI
          title={
            providerMatch?.length === 0
              ? "There is currently no provider that offers this type of photo."
              : ""
          }
        >
          <div
            className={
              "div-one-center !h-[120px] !w-[102px] rounded px-2 py-1 div-type cursor-pointer" +
              (item?.name === typeImage ? " active" : "") +
              (providerMatch?.length === 0 ? " invisible" : " ")
            }
            onClick={() => {
              if (providerMatch?.length === 0) {
                return;
              } else {
                dispatch(changeTypeImage(item?.value));
                dispatch(activeLoadingProvider());
                dispatch(setFlagSearch(true));

                let a = allProviders?.filter((itemF: any) =>
                  itemF?.metadatas?.some(
                    (itemMF: any) =>
                      itemMF?.name === "IMAGE_TYPE_SEARCH" &&
                      itemF?.enabled_archive &&
                      itemMF?.value === item?.value
                  )
                );
                dispatch(setSelected([]));

                dispatch(setArrProviders(a));
                dispatch(setProviderId(a?.[0]?.id));
              }
            }}
          >
            <div
              className={
                "div-type-image-search div-one-center !justify-center" +
                (providerMatch?.length === 0 ? " !cursor-no-drop" : "")
              }
            >
              <img src={item?.logo} style={{ width: "32px", height: "30px" }} />
            </div>

            <p style={{ color: "white", fontSize: "14px" }}>{item?.name}</p>
          </div>
        </CommonComponents.TooltipUI>
      );
    });
  };

  return (
    <div className="h-full p-3 pt-0 overflow-hidden !w-[460px]">
      <div className="w-full  bg-common-ink100 py-3 h-full ">
        <div className="sticky  top-0 z-10"> {renderHeaderDrawer()}</div>

        <div className="overflow-y-scroll h-full">
          <p
            style={{
              color: "white",
              fontSize: "14px",
              paddingBottom: "20px",
              paddingTop: "6px",
            }}
          >
            Images already captured
          </p>
          <div className="flex gap-6">{renderSelectTypeImage()}</div>
          <Divider className="bg-common-line my-4" />
          <div
            className="flex flex-col gap-2 mt-4 justify-between"
            style={{ height: `calc(100% - 110px)` }}
          >
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilterSearchComp;
