import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ApplySvg from "../../assets/svg/apply-black.svg";
import EmptySvg from "../../assets/svg/empty.svg";
import ConfirmOrderComp from "../../components/BookingImage/ConfirmOrder";
import OrderTaskingForm from "../../components/BookingImage/OrderTasking";
import CommonComponents from "../../components/Commons";
import { ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { showError, showSuccess } from "../../helpers/toast";
import useToggle from "../../hooks/useToggle";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  resetAllDrawer,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  changeShowOrderTasking,
  createTaskingOrderAsync,
  getPriorityAsync,
  getTaskingSchemaAsync,
  setOrderedProvider,
  setPolygonOrderTasking,
  setValuesForm as setValuesFormStore,
} from "../../store/reducers/orderSlice";
import { getMyProjectsAsync } from "../../store/reducers/projectSlice";
import { RootState } from "../../store/store";
import { convertToKeyValueList } from "./ManageTaskingOrder/ultis";

const ConfirmTaskingOrderPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const selectedImages = useSelector(
    (state: RootState) => state.cartSlice.selectedImagesConfirm
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.orderSlice.polygonOrderTasking
  );

  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );

  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  const [flagConfirm, setflagConfirm] = useState<any>(false);
  const [valuesForm, setValuesForm] = useState<any>({});
  const [dataPriority, setDataPriority] = useState<any>([]);
  const [arrProjects, setArrProjects] = useState<any>([]);

  const [dataSchemaOnePlan, setDataSchemaOnePlan] = useState<any>({});

  const [dataSchema, setDataSchema] = useState<any>({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fieldRequireStep2, setFieldRequireStep2] = useState([]);
  const [itemStep, setItemStep] = useState<
    { title: string; status?: "wait" | "process" | "finish" | "error" }[]
  >([
    {
      title: "Information",
    },
    {
      title: "Setting",
    },
    {
      title: "Confirm",
    },
  ]);
  const [policy, setPolicy] = useState(true);
  const {
    open: openPolicy,
    toggle: togglePolicy,
    shouldRender: shouldRenderPolicy,
  } = useToggle();

  const getSchema = async () => {
    let nameProvider = arrAllProviders?.filter(
      (itemF: any) => itemF?.id === orderedProvider?.[0]
    )?.[0]?.name;
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getTaskingSchemaAsync({
        // idProvider: arrAllProviders?.filter(
        //   (itemF: any) => itemF?.name.toLowerCase() === "capella" // sau sua lai thanh provider user chon
        // )?.[0]?.id,
        idProvider: orderedProvider?.[0],
        type: "order_tasking",
      })
    ).unwrap();

    if (nameProvider.toLowerCase() === "oneatlas") {
      // @ts-ignore
      let resSchemaOnePlan = await dispatch(
        // @ts-ignore
        getTaskingSchemaAsync({
          idProvider: orderedProvider?.[0],
          type: "ORDER_TASKING_ONEATLAS_ONE_PLAN",
        })
      ).unwrap();

      setDataSchemaOnePlan(
        resSchemaOnePlan?.data?.data?.ui_schema?.properties ?? {}
      );
    }

    setDataSchema(res?.data?.data ?? {});
  };

  const handleSubmit = async () => {
    let { production, ...newValues } = valuesForm;
    let pay = {
      ...newValues,
      aoi: selectedPolygon?.geometry,
      start_date_request: dayjs(valuesForm?.start_date_request).format(
        "DD/MM/YYYY HH:mm"
      ),
      end_date_request: valuesForm?.end_date_request
        ? dayjs(valuesForm?.end_date_request).format("DD/MM/YYYY HH:mm")
        : null,
      options: Object.entries(valuesForm?.production).map(([key, value]) => ({
        key,
        value,
        label: key,
      })),
    };

    // @ts-ignore
    const res = await dispatch(createTaskingOrderAsync(pay)).unwrap();

    if (res?.status === 201 || res?.status === 200) {
      showSuccess("Order successfully");

      dispatch(changeShowOrderTasking(false));
      dispatch(resetLeftDrawer());
      // @ts-ignore
      dispatch(setValuesFormStore({}));
      setValuesForm({});
      navigate("/order/management-tasking");
    } else {
      showError(res?.message ?? "Order failed");
    }
  };

  const renderCalssName = () => {
    let a = "";

    if (!flagConfirm && !showOrderTasking) {
      a = "div-form-small";
    } else if (
      (showOrderTasking && flagConfirm) ||
      (!showOrderTasking && flagConfirm)
    ) {
      a = "div-page-default";
    } else {
      a = "div-form-default !justify-start !pt-10";
    }
    return a;
  };

  const handleGetPriority = async () => {
    // @ts-ignore
    const res = await dispatch(getPriorityAsync()).unwrap();

    setDataPriority(
      res?.data?.data?.content_page?.filter(
        (itemF: any) => itemF?.key === "PRIORITY_LEVEL"
      ) ?? []
    );
  };

  const getDataProject = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMyProjectsAsync({ size: 1000, page: 0 })
    ).unwrap();

    setArrProjects(res?.data?.data?.content_page ?? []);
  };

  const checkStepStatus = () => {
    let values = formRef.current.getFieldsValue();
    const Step1Status =
      !!values?.name && !!values?.priority_id && !!values?.reason;

    let Step2Status = fieldRequireStep2.every(
      (itemF: any) => !!values?.properties?.[itemF]
    );
    //! check form onatlas
    if (
      arrAllProviders
        ?.filter((itemF: any) => itemF?.id === orderedProvider?.[0])?.[0]
        ?.name?.toLowerCase() === "oneatlas"
    ) {
      Step2Status = fieldRequireStep2.every(
        (itemF: any) => !!values?.optionsPerProductType?.options?.[itemF]
      );

      if (
        values?.mission === "ONENOW/SPOT" &&
        (!values?.hiddenAcquisition || values?.hiddenAcquisition === "123")
      ) {
        Step2Status = false;
      }
    }
    const itemStepClone = [...itemStep];
    itemStepClone[0].status = Step1Status ? "finish" : "error";
    itemStepClone[1].status = Step2Status ? "finish" : "error";

    setItemStep(itemStepClone);

    //! if field required not yet filled
    return Step1Status && Step2Status;
  };

  const triggerFormSubmit = async () => {
    try {
      setLoadingSubmit(true);
      if (formRef.current) {
        formRef.current.submit(); // Gọi hàm submit của form từ component cha
      }

      let idAOI = "Polygon " + uuidv4();

      // @ts-ignore
      let values = formRef.current.getFieldsValue();
      const statusPassStep = checkStepStatus();
      if (!statusPassStep) {
        showError("Please fill in all required fields");

        setLoadingSubmit(false);
        return;
      }
      let payload;

      if (
        arrAllProviders
          ?.filter((itemF: any) => itemF?.id === orderedProvider?.[0])?.[0]
          ?.name?.toLowerCase() === "oneatlas"
      ) {
        const { id, footprint, segmentKey, ...newHiddenAcquisition } =
          values?.hiddenAcquisition || {};
        let valueOnePlan;

        if (values?.mission?.includes("ONEPLAN")) {
          let from = values?.schemaOnePlan?.acqPeriod?.From;
          let to = values?.schemaOnePlan?.acqPeriod?.To;
          valueOnePlan = {
            ...values?.schemaOnePlan,
            acqPeriod: `${dayjs(from).toISOString()} ${dayjs(
              to
            ).toISOString()}`,
          };
        }
        payload = {
          name: values?.name,
          priority_id: values?.priority_id,
          description: values?.description ?? undefined,
          mission: values?.mission,
          reason: values?.reason ?? undefined,
          order_details: [
            {
              provider_id: Number(orderedProvider?.[0]),
              request_order: {
                aoi: [
                  {
                    id: idAOI,
                    name: "Polygon 1",
                    geometry: selectedPolygon?.geometry,
                  },
                ],

                items: [
                  {
                    aoiId: idAOI,
                    properties: values?.mission?.includes("ONEPLAN")
                      ? convertToKeyValueList(valueOnePlan)
                      : convertToKeyValueList(newHiddenAcquisition),
                    holdback: false,
                  },
                ],
                optionsPerProductType: [
                  {
                    options: convertToKeyValueList(
                      values?.optionsPerProductType?.options
                    ),
                  },
                ],
              },
            },
          ],
        };
      } else {
        payload = {
          name: values?.name,
          priority_id: values?.priority_id,
          description: values?.description ?? undefined,
          reason: values?.reason ?? undefined,
          // provider_id: orderedProvider,
          // geometry: selectedPolygon?.geometry,
          order_details: [
            {
              provider_id: Number(orderedProvider?.[0]),
              request_order: {
                geometry: selectedPolygon?.geometry,
                properties: {
                  ...values?.properties,
                  collectConstraints: {
                    ...values?.properties?.collectConstraints,
                    orbitalPlanes: [
                      Number(
                        values?.properties?.collectConstraints?.orbitalPlanes
                          ?.orbitalPlanes
                      ),
                    ], // hot-fix
                  },
                },
              },
            },
          ],
        };
      }

      let urlRequest = dataSchema?.url_submit;

      let res = await axios.post(urlRequest, payload);

      if (res?.status === ESttausCode.CREATED) {
        showSuccess("Order successfully");

        // @ts-ignore
        dispatch(setPolygonOrderTasking({}));
        // @ts-ignore
        dispatch(setOrderedProvider([]));

        navigate(`/order/management-tasking/${res?.data?.data?.id}`);
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      togglePolicy();
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    dispatch(changeLayoutName("/confirm-order-tasking"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-tasking")}
            >
              Tasking Orders
            </span>
          ),
        },
        { title: "Information" },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(changeShowOrderTasking(false));
    };
  }, []);

  useEffect(() => {
    if (orderedProvider?.length > 0) {
      getSchema();
    }
  }, [orderedProvider]);

  useEffect(() => {
    if (flagConfirm) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => setflagConfirm(false)}
              >
                Tasking Orders
              </span>
            ),
          },
          { title: "Confirm" },
        ])
      );
    } else if (!flagConfirm && selectedPolygon?.length > 0) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => navigate("/order/management-tasking")}
              >
                Tasking Orders
              </span>
            ),
          },
          { title: "Information" },
        ])
      );
    }
  }, [flagConfirm, selectedPolygon]);

  useEffect(() => {
    handleGetPriority();
    getDataProject();
  }, []);

  return (
    <ContentLayoutManagement
      header={
        flagConfirm ? (
          <div className="flex gap-2 !justify-end pr-6">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7 "
              onClick={() => setflagConfirm(false)}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              className="!text-xs !h-[27px]  hover:!bg-common-primary500"
              onClick={() => handleSubmit()}
            >
              Send order
            </CommonComponents.ButtonUI>
          </div>
        ) : (
          <div className="flex gap-2 !justify-end pr-6">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="!text-xs !h-7 mr-2"
              onClick={() => navigate("/explore-tasking")}
              disabled={!selectedPolygon?.id}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              htmlType="submit"
              commonType="default"
              className="!text-xs !h-[27px]  hover:!bg-common-primary500"
              // icon={<img src={ApplySvg} />}
              disabled={!selectedPolygon?.id}
              onClick={() => {
                togglePolicy();
              }}
            >
              Create Order
            </CommonComponents.ButtonUI>
          </div>
        )
      }
    >
      {selectedPolygon && selectedPolygon?.id && showOrderTasking ? (
        <div className={renderCalssName()}>
          {!flagConfirm ? (
            <OrderTaskingForm
              dataSchema={dataSchema}
              dataPriority={dataPriority}
              datProject={arrProjects}
              formRef={formRef}
              geometry={selectedPolygon?.geometry}
              itemStep={itemStep}
              setItemStep={setItemStep}
              setFieldRequireStep2={setFieldRequireStep2}
              dataSchemaOnePlan={dataSchemaOnePlan}
              fieldRequireStep2={fieldRequireStep2}
            />
          ) : (
            <>
              <ConfirmOrderComp
                data={valuesForm}
                onCancel={() => setflagConfirm(false)}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </div>
      ) : (
        <div className="div-one-center h-full">
          <div className="div-center-col">
            <img src={EmptySvg} />
            <p className="text-white">No selected polygon</p>
          </div>
        </div>
      )}

      {shouldRenderPolicy && (
        <CommonComponents.ModalUI
          open={openPolicy}
          toggle={togglePolicy}
          title="Privacy Policy & Term"
          handleConfirm={triggerFormSubmit}
          disabled={!policy}
          loading={loadingSubmit}
        >
          <div>
            <CommonComponents.CheckboxUI
              checked={policy}
              onChange={() => {
                setPolicy(!policy);
              }}
              className="text-white"
            >
              <span>
                Check here to indicate you have read and agree to the{" "}
              </span>
              <Link
                to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                target={"_blank"}
                className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
              >
                Terms and Conditions of Geohub
              </Link>
            </CommonComponents.CheckboxUI>
          </div>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default ConfirmTaskingOrderPage;
