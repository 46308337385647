import {
  Link,
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EProviderName, ERoleAccessControl, ESttausCode } from "../../enums";
import { useEffect, useState } from "react";
import {
  getOrderByIdAsync,
  getOrderByIdUserAsync,
} from "../../store/reducers/orderSlice";
import { Collapse, Divider, Form, Space } from "antd";
import CommonIcons from "../../assets/icons";
import Step4 from "../../components/BookingImage/Step4";
import { renderInfoOrder } from "../order/ManageTaskingOrder/ultis";
import { capitalizeFirstLetter } from "../../helpers/common";
import CommonComponents from "../../components/Commons";
import Step3 from "../../components/BookingImage/Step3";
import Step2 from "../../components/BookingImage/Step2";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";

const ResultOrder = () => {
  //! state
  const [searchParams] = useSearchParams();
  const roleAdmin = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );
  const maplibreGL = useSelector(
    (state: RootState) => state.mapTasking.maplibreGL
  );
  const typeOrder = searchParams.get("type");
  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState<any>({});
  const [arrAOI, setAOI] = useState<any>([]);

  const [itemsImages, setItemsImages] = useState<any>([]);
  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);
  //! function
  const getData = async () => {
    // dispatch(setLoadingGlobal(true));
    let res = !roleAdmin?.includes(ERoleAccessControl.admin)
      ? // @ts-ignore
        await dispatch(getOrderByIdUserAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getOrderByIdAsync(id)).unwrap();
    if (res.status === ESttausCode.OK) {
      const allImages = res?.data?.data?.order_details
        .map((item: any) => item.images)
        .flat();

      setAOI(allImages);
      setItemsInfoOder([
        {
          key: "1",
          label: "Order's Information",
          children: renderInfoOrder(
            res?.data?.data,
            roleAdmin,
            typeOrder === "tasking" ? "tasking" : null,
            true
          ),
        },
      ]);

      const newValues = {};
      form.setFieldsValue(newValues);

      setData(res?.data?.data ?? []);
    }
  };

  //! effect
  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    let a: any = [];

    if (data?.order_details?.length > 0) {
      const initialValues: any = {};
      data?.order_details?.forEach((item: any) => {
        if (item?.actual_price) {
          initialValues[item?.id] = {
            price: item?.actual_price?.total_price ?? 0,
            unit: item?.actual_price?.unit ?? "",
          };
        }
      });

      // Đặt giá trị khởi tạo cho form
      form.setFieldsValue(initialValues);

      data?.order_details?.map((item: any, index: any) => {
        if (item?.images?.length !== 0)
          a = a.concat({
            key: index,
            label: (
              <div className="flex justify-between items-center">
                <span>
                  {capitalizeFirstLetter(item?.provider?.name)} (
                  {item?.images?.length})
                </span>

                <div className="flex gap-3 items-center"></div>
              </div>
            ),
            children: (
              <>
                <Divider
                  style={{ background: "#545C77", margin: "0px 0px 20px" }}
                />
                <div className="w-full flex gap-3">
                  <div className="w-full">
                    {item?.provider?.name?.toLowerCase() === "planet" ? (
                      <Step3
                        form={form}
                        dataImages={item?.images}
                        map={map}
                        sourceId={sourceId}
                        initialValues={item?.request_order?.products}
                        readOnly
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );

                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                      />
                    ) : item?.provider?.name?.toLowerCase() === "oneatlas" ? (
                      <Step4
                        showCardImages={true}
                        form={form}
                        dataImages={item?.images}
                        total={item?.images?.length}
                        dataProvider={
                          providerConfigStore?.content_page?.filter(
                            (item: any) =>
                              item?.name.toLowerCase() ===
                              EProviderName.oneatlas
                          )?.[0]?.configs
                        }
                        map={map}
                        sourceId={sourceId}
                        initialValues={item?.request_order?.optionsPerProductType?.[0]?.options.concat(
                          {
                            key: "product_type_id",
                            value:
                              item?.request_order?.optionsPerProductType?.[0]
                                ?.productTypeId ??
                              item?.request_order?.optionsPerProductType?.[0]
                                ?.product_type_id,
                          }
                        )}
                        readOnly
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );
                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                        style={{ background: "#3C4152" }}
                      />
                    ) : (
                      <Step2
                        form={form}
                        items={item?.images}
                        dataProvider={
                          providerConfigStore?.content_page?.filter(
                            (item: any) =>
                              item?.name.toLowerCase() === EProviderName.capella
                          )?.[0]?.configs
                        }
                        map={map}
                        sourceId={sourceId}
                        onClick={(idImage: any) => {
                          setAOI((prevAOI: any) => {
                            const exists = prevAOI.some(
                              (itemF: any) => itemF.id === idImage
                            );
                            if (exists) {
                              return prevAOI.filter(
                                (itemF: any) => itemF.id !== idImage
                              );
                            } else {
                              const newItem = item?.images?.find(
                                (itemF: any) => itemF.id === idImage
                              );
                              return newItem ? [...prevAOI, newItem] : prevAOI;
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            ),
          });
      });
    }

    setItemsImages(a);
  }, [data, maplibreGL, providerConfigStore]);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              {typeOrder === "tasking"
                ? " Tasking Orders"
                : "Existing Image Orders"}
            </span>
          ),
        },
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => {
                if (typeOrder === "tasking") {
                  navigate(`/order/management-tasking/${data?.id}`);
                } else {
                  navigate(`/order/management-order/${data?.id}`);
                }
              }}
            >
              {data?.name}
            </span>
          ),
        },
        {
          title:
            typeOrder === "tasking"
              ? `Result Order ${data?.order_details?.[0]?.provider?.name}`
              : "Result Order",
        },
      ])
    );
  }, [data]);
  //! render
  return (
    <ContentLayoutManagement
      header={
        <CommonComponents.ButtonUI
          onClick={() => {
            navigate(-1);
          }}
          className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7 mr-4"
        >
          Cancel
        </CommonComponents.ButtonUI>
      }
    >
      <Form form={form}>
        <div className="relative w-full h-full p-6 bg-content-default ">
          <div className="text-base !my-3">
            <NavLink
              className="inline text-blue-400"
              style={{ textDecoration: "underline" }}
              to={"https://dev-eom.geohub.vn/main-map"}
              target="_blank"
            >
              You can access this link to see the ordered photos.
            </NavLink>
          </div>

          <div className="card-archive-order-cus">
            {typeOrder === "tasking" ? (
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsInfoOder}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            ) : (
              <div>
                {!roleAdmin?.includes(ERoleAccessControl.admin) && (
                  <div className="flex justify-between text-white p-3 bg-[#2C3243] mb-2 rounded-sm">
                    <p>Total price</p>
                    <p
                      style={{ color: "rgb(0, 209, 255)", fontWeight: "bold" }}
                    >
                      {data?.order_details?.[0]?.actual_price?.total_price}{" "}
                      {` ${data?.order_details?.[0]?.actual_price?.unit}`}
                    </p>
                  </div>
                )}

                <Collapse
                  className="bg-[#2C3243]"
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsImages}
                  onChange={() => {}}
                  defaultActiveKey={["0", "1", "2", "3"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </Form>
    </ContentLayoutManagement>
  );
};

export default ResultOrder;
