import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import InboxSvg from "../../assets/svg/FileUpload/Inbox.svg";
import FileSvg from "../../assets/svg/FileUpload/File.svg";
import DeleteFile from "../../assets/svg/FileUpload/DeleteFile.svg";
import { useDropzone } from "react-dropzone";
import { formatBytesDynamic } from "./ultis";
import CommonComponents from "../../components/Commons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadDataAsync } from "../../store/reducers/orderSlice";
import { showError, showSuccess } from "../../helpers/toast";
import { getErrorMsg } from "../../helpers/common";
import { ESttausCode } from "../../enums";
import { Spin } from "antd";
import LoadingUI from "../../components/Commons/Loading";

const extensionFile = `.txt, .log, .doc,
.docx, .ods, .odt, .sld, .qml, .xls, .xlsx, .xml, .bm, .bmp,
.dwg, .dxf, .fif, .gif, .jpg, .jpe, .jpeg, .png, .tif,
.tiff, .pbm, .odp, .ppt, .pptx, .pdf, .tar, .tgz, .rar, .gz,
.7z, .zip, .aif, .aifc, .aiff, .au, .mp3, .mpga, .wav, .afl,
.avi, .avs, .fli, .mp2, .mp4, .mpg, .ogg, .webm, .3gp, .flv,
.vdo, .glb, .pcd, .gltf`;
const acceptFile = {
  "text/plain": [".txt", ".log"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/sld": [".sld"],
  "text/x-qml": [".qml"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/xml": [".xml"],
  "image/bmp": [".bm", ".bmp"],
  "image/vnd.dwg": [".dwg"],
  "image/vnd.dxf": [".dxf"],
  "image/fif": [".fif"],
  "image/gif": [".gif"],
  "image/jpeg": [".jpg", ".jpe", ".jpeg"],
  "image/png": [".png"],
  "image/tiff": [".tif", ".tiff"],
  "image/x-portable-bitmap": [".pbm"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/pdf": [".pdf"],
  "application/x-tar": [".tar"],
  "application/x-compressed": [".tgz"],
  "application/x-rar-compressed": [".rar"],
  "application/gzip": [".gz"],
  "application/x-7z-compressed": [".7z"],
  "application/zip": [".zip"],
  "audio/x-aiff": [".aif", ".aifc", ".aiff"],
  "audio/basic": [".au"],
  "audio/mpeg": [".mp3", ".mpga"],
  "audio/wav": [".wav"],
  "audio/x-afl": [".afl"],
  "video/x-msvideo": [".avi"],
  "video/avs-video": [".avs"],
  "video/x-fli": [".fli"],
  "video/mpeg": [".mp2", ".mpg"],
  "video/mp4": [".mp4"],
  "video/ogg": [".ogg"],
  "video/webm": [".webm"],
  "video/3gpp": [".3gp"],
  "video/x-flv": [".flv"],
  "video/vdo": [".vdo"],
  "model/gltf-binary": [".glb"],
  "image/x-photo-cd": [".pcd"],
  "model/gltf+json": [".gltf"],
};
const UploadPage = () => {
  //! state
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [arrayFileUpload, setArrayFileUpload] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFile,
    onDrop(acceptedFiles) {
      setArrayFileUpload((prev: any) => [...prev, ...acceptedFiles]);
    },
  });

  const handleSubmit = async () => {
    try {
      setLoadingUpload(true);
      if (!arrayFileUpload || arrayFileUpload.length === 0) {
        return;
      }

      const formData = new FormData();
      arrayFileUpload.forEach((file: any) => {
        formData.append(`files`, file);
      });

      console.log(location);

      const payload = {
        idDetailOrder: location?.state?.data?.order_details?.[0]?.id,
        formData,
      };
      // @ts-ignore
      const res = await dispatch(uploadDataAsync(payload)).unwrap();
      if (res.status === ESttausCode.OK) {
        showSuccess("Upload file success");
        setUploadedFiles((prev: any) => [...prev, ...arrayFileUpload]);
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      setLoadingUpload(false);
    }
  };
  const handleDeleteFile = (index: number) => {
    const findIndex = arrayFileUpload.findIndex(
      (item: any, indexArray: number) => index === indexArray
    );
    const newArray = [...arrayFileUpload];
    newArray.splice(findIndex, 1);
    setArrayFileUpload(newArray);
  };

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          title: "Existing Image Orders",
          path: "order/management-order",
        },
        {
          title: `${location?.state?.data?.name}`,
          path: `${location?.state?.data?.id}`,
        },
        {
          title: `${location?.state?.data?.order_details?.[0]?.provider?.name}`,
          // path: `${location?.state?.item?.id}`,
        },
        {
          title: "Upload",
        },
      ])
    );
  }, []);

  //! render
  const CardUpload = (file: any, index: number) => {
    const isUploaded = uploadedFiles.some(
      (uploadedFile: any) =>
        uploadedFile.name === file.name && uploadedFile.size === file.size
    );

    return (
      <div
        className="flex justify-between items-center border bg-[#3C4152] border-[#4D5263] p-2 rounded-md min-h-[68px]"
        style={{
          borderBottom: isUploaded ? "1px solid #60CDFF" : "1px solid #4D5263",
        }}
        key={index}
      >
        <div className="flex gap-2">
          <div className="size-12 bg-[#262A34] rounded-md p-[14px]">
            <img src={FileSvg} alt="FileSVG" />
          </div>

          <div className="text-white flex flex-col justify-between">
            <CommonComponents.TooltipUI title={`${file?.name}`}>
              <p className="text-sm text-one-line">{file?.name}</p>
            </CommonComponents.TooltipUI>
            <div className="py-1 px-2 w-fit bg-[#474D5F] rounded-xl text-[10px]">
              {file?.type}
            </div>
          </div>
        </div>

        <div className="text-white flex flex-col justify-between items-end gap-2 h-full">
          {!isUploaded && (
            <div
              className="cursor-pointer"
              onClick={() => {
                handleDeleteFile(index);
              }}
            >
              <img src={DeleteFile} alt="DeleteFile" />
            </div>
          )}

          <div className="text-[10px] text-right">
            {formatBytesDynamic(file?.size)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContentLayoutManagement
      header={
        <div className="flex gap-2 justify-end pr-6">
          <CommonComponents.ButtonUI
            commonType="standard"
            className="btn-cancel !bg-[#FFFFFF28] hover:bg-[#3E4353] !text-xs !h-7 "
            onClick={() => navigate(-1)}
          >
            Cancel
          </CommonComponents.ButtonUI>
          <CommonComponents.ButtonUI
            className="!text-xs !h-[27px] hover:!bg-common-primary500"
            onClick={() => handleSubmit()}
            disabled={loadingUpload || arrayFileUpload.length === 0}
            // htmlType="submit"
          >
            Upload
          </CommonComponents.ButtonUI>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        <div className="h-full flex gap-4">
          <div className="bg-[#2C3243] border-0 rounded-lg p-4 basis-1/3 relative h-full">
            <div
              {...getRootProps({ className: "dropzone" })}
              className="border-2 border-[#4D5263] border-dashed rounded-lg bg-[#3C4152] py-4 px-5"
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center gap-5">
                <img src={InboxSvg} alt="IndboxSVG" />
                <div className="text-white text-center flex flex-col gap-1">
                  <p>Drag or choose file from device</p>
                  <p className="text-center text-sm text-[#FFFFFF73]">
                    Support for element extension files: {extensionFile}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#2C3243] border-0 rounded-lg basis-2/3 p-4 h-full">
            {arrayFileUpload?.length === 0 ? (
              <div className="text-center text-common-white text-lg !h-full flex flex-col items-center justify-center">
                No Selected File
              </div>
            ) : (
              <Spin spinning={loadingUpload} indicator={<LoadingUI />}>
                <div className="flex flex-col mt-4 gap-4 overflow-y-scroll h-full">
                  {arrayFileUpload?.map((file: any, index: number) =>
                    CardUpload(file, index)
                  )}
                </div>
              </Spin>
            )}
          </div>
        </div>
      </div>
    </ContentLayoutManagement>
  );
};

export default UploadPage;
