import { Checkbox, Divider, Select, Space } from "antd";
import CommonComponents from "../../Commons";
import DownSvg from "../../../assets/svg/down.svg";
import CardItemConfirmComp from "../../BookingImage/CardItemConfirm";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useWatch } from "antd/es/form/Form";
import { checkTextIsDate, parseKey } from "./ultis";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  classLable,
  classValue,
  classWrap,
} from "../../../pages/order/ManageTaskingOrder/ultis";

const CheckboxGroup = Checkbox.Group;

const optionsUnit = [
  {
    value: "VND",
    label: "VND",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
];

const plainOptions = [
  "I have read and accept the product licence",
  "I have accept terms and conditions",
];

const ConfirmOrderComp = (props: any) => {
  const [imageSelected, setImagesSelected] = useState<any>([]);
  const [dataProduction, setDataProduction] = useState<any>({});
  const [dataProperties, setDataProperties] = useState<any>({});
  const [show, setShow] = useState(true);

  const arrAllPriority = useSelector(
    (state: RootState) => state.appCommonSlice.metaKey
  );

  const renderPriceDiv = () => {
    return (
      <div className="mb-8">
        <p className="mb-4 text-white" style={{ fontSize: "16px" }}>
          Order summary
        </p>
        <div
          style={{ background: "rgba(44, 50, 67, 1)" }}
          className="p-4 gap-2 flex flex-col"
        >
          <div className="flex flex-row justify-between items-center">
            <span className="text-gray-500">Provider's price</span>
            <span style={{ color: "#00D1FF" }}>
              {props?.data?.order_cost?.total_price}{" "}
              {props?.data?.order_cost?.unit}
            </span>
          </div>

          {/* <div className="flex flex-row justify-between items-center">
            <span className="text-gray-500">Vega's price</span>
            <span>
              <CommonComponents.FormFieldUI
                noStyle
                name={["price"]}
                component={
                  <Space.Compact className="items-center">
                    <CommonComponents.InputUI
                      placeholder={`Enter Vega's price`}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please input here!",
                        },
                      ]}
                      className="w-[200px] !h-8 !mb-0 !bg-[#3E4353] !border-b-0"
                      classNameDiv="!mb-0"
                      value={watchPrice}
                      disabled={props?.data?.is_sent}
                      type="number"
                    />
                    <Divider
                      type="vertical"
                      style={{ background: "#575F77", margin: "10px 0" }}
                    />
                    <CommonComponents.FormFieldUI
                      name={["unit"]}
                      noStyle
                      component={
                        <CommonComponents.SelectUI
                          data={optionsUnit}
                          className="!w-[80px] !bg-[#3E4353] !rounded-l-none custom-select-unit"
                          disabled={props?.data?.is_sent}
                        />
                      }
                    />
                  </Space.Compact>
                }
              />
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderInfoDiv = () => {
    return (
      <div className="mb-8">
        <div
          className="flex flex-row justify-between items-center mb-4 cursor-pointer"
          onClick={() => setShow(!show)}
        >
          <p className="mb-0 text-white" style={{ fontSize: "16px" }}>
            Information Summary
          </p>
          <img src={DownSvg} alt="" className={!show ? "rotate-180" : ""} />
        </div>
        {show && (
          <>
            {props?.data?.images?.map((item: any) => (
              <CardItemConfirmComp
                key={item?.id}
                item={item}
                onMouseLeaveItem={() =>
                  onMouseLeaveItem(item?.feature, props.map, props.sourceId)
                }
                onMouseEnterItem={() =>
                  onMouseEnterItem(item?.feature, props.map, props.sourceId)
                }
                selected={imageSelected?.includes(item?.id)}
                onClick={() => {
                  props?.setAOI((prevAOI: any) => {
                    const exists = prevAOI.some(
                      (itemF: any) => itemF.id === item?.id
                    );
                    if (exists) {
                      return prevAOI.filter(
                        (itemF: any) => itemF.id !== item?.id
                      );
                    } else {
                      const newItem = props?.data?.images?.find(
                        (itemF: any) => itemF.id === item?.id
                      );
                      return newItem ? [...prevAOI, newItem] : prevAOI;
                    }
                  });
                  if (imageSelected?.includes(item?.id)) {
                    setImagesSelected(
                      imageSelected?.filter((itemF: any) => itemF !== item?.id)
                    );
                  } else {
                    setImagesSelected([...imageSelected, item?.id]);
                  }
                }}
              />
            ))}

            <div className="p-4 mt-4 !bg-[#2C3243]">
              {props?.data?.type === "TASKING" && (
                <>
                  {props?.data?.order_details[0]?.request_order?.items?.[0]?.properties?.map(
                    (itemM: any) => (
                      <div
                        key={itemM?.key}
                        className="flex items-center justify-between p-2"
                      >
                        <span className="font-medium text-[#969BAA]">
                          {parseKey(itemM?.key)}:{/* {itemM?.key}: */}
                        </span>

                        {checkTextIsDate(itemM?.value) ? (
                          <span className="text-white">
                            {moment(itemM?.value).format("DD/MM/YYYY HH:mm")}{" "}
                          </span>
                        ) : (
                          <span
                            className={`text-white  ${
                              typeof itemM?.value === "boolean" &&
                              !itemM?.value &&
                              " !text-red-500 flex gap-1"
                            } ${
                              typeof itemM?.value === "boolean" &&
                              itemM?.value &&
                              "text-green-500 flex gap-1"
                            }`}
                          >
                            {itemM?.value === false ? (
                              <CloseCircleOutlined />
                            ) : null}
                            {itemM?.value === true ? (
                              <CheckCircleOutlined />
                            ) : null}
                            {itemM?.value?.toString()}
                          </span>
                        )}
                      </div>
                    )
                  )}

                  {props?.data?.order_details?.[0]?.request_order?.optionsPerProductType?.[0]?.options?.map(
                    (itemM: any) => (
                      <div
                        key={itemM?.key}
                        className="flex items-center justify-between p-2"
                      >
                        <span className="font-medium text-[#969BAA]">
                          {parseKey(itemM?.key)}:{/* {itemM?.key}: */}
                        </span>
                        <span className="text-white">{itemM?.value}</span>
                      </div>
                    )
                  )}
                </>
              )}
              {dataProduction &&
                typeof dataProduction === "object" &&
                Object.entries(dataProduction).length > 0 && (
                  <>
                    {Object.entries(dataProduction).map(([key, value]: any) => (
                      <div
                        key={key}
                        className="flex items-center justify-between p-2"
                      >
                        <span className="font-medium text-[#969BAA]">
                          {parseKey(key)}
                        </span>
                        <span
                          className={`text-white ${
                            value == "false" && "!text-red-500 flex gap-1"
                          } ${
                            typeof value === "boolean" &&
                            value &&
                            "!text-green-500 flex gap-1"
                          }`}
                        >
                          {value === "false" ? <CloseCircleOutlined /> : null}
                          {value === "true" ? <CheckCircleOutlined /> : null}
                          {value?.toString()}
                        </span>
                      </div>
                    ))}
                  </>
                )}

              {dataProperties &&
                typeof dataProperties === "object" &&
                Object.entries(dataProperties).length > 0 && (
                  <>
                    {Object.entries(dataProperties).map(([key, value]: any) => (
                      <>
                        {key !== "End user country" && (
                          <Divider
                            style={{
                              background: "#545C77",
                              margin: "10px 0",
                            }}
                          />
                        )}

                        <div
                          key={key}
                          className={`flex items-center justify-between p-2 ${key}`}
                        >
                          <span className="font-medium text-[#969BAA]">
                            {parseKey(key)}
                          </span>
                          <span className="text-white">{value}</span>
                        </div>
                      </>
                    ))}
                  </>
                )}

              {props?.type === "confirm-user-order" &&
                Object.entries(props?.data)?.length > 0 && (
                  <>
                    {Object.entries({
                      "Order name": props?.data?.name,
                      Description: props?.data?.description,
                      Reason: props?.data?.reason,
                    })?.map(([key, value]: [string, any]) => (
                      <div key={key} className={classWrap}>
                        <span className={classLable}>{key}</span>
                        <span className={classValue}>{value}</span>
                      </div>
                    ))}

                    <div
                      key={"Priority"}
                      className="flex items-center justify-between p-2"
                    >
                      <span className="font-medium text-[#969BAA]">
                        Priority
                      </span>
                      <span
                        style={{
                          color:
                            arrAllPriority?.filter(
                              (itemF: any) =>
                                itemF?.id === props?.data?.priority_id
                            )?.[0]?.description ?? "white",
                        }}
                      >
                        {
                          arrAllPriority?.filter(
                            (itemF: any) =>
                              itemF?.id === props?.data?.priority_id
                          )?.[0]?.name
                        }
                      </span>
                    </div>

                    <Divider
                      style={{
                        background: "#545C77",

                        margin: "10px 0",
                      }}
                    />

                    {props?.data?.hiddenAcquisition &&
                      (Array.isArray(props?.data?.hiddenAcquisition)
                        ? props?.data?.hiddenAcquisition
                            .filter(
                              (itemF: any) =>
                                itemF?.key !== "footprint" &&
                                itemF?.key !== "segmentKey"
                            )
                            .map((itemF: any) => (
                              <div
                                key={itemF?.key}
                                className="flex items-center justify-between p-2"
                              >
                                <span className="font-medium text-[#969BAA]">
                                  {parseKey(itemF?.key)}
                                </span>
                                <span className="text-white">
                                  {itemF?.key === "orderDeadline" ||
                                  itemF?.key === "acquisitionStartDate" ||
                                  itemF?.key === "acquisitionEndDate"
                                    ? moment(itemF?.value)?.format(
                                        "HH:mm:ss DD/MM/YYYY"
                                      )
                                    : itemF?.value}
                                </span>
                              </div>
                            ))
                        : typeof props?.data?.hiddenAcquisition === "object" &&
                          Object.entries(props?.data?.hiddenAcquisition)
                            ?.filter(
                              ([key, value]: [string, any]) =>
                                key !== "footprint" && key !== "segmentKey"
                            )
                            .map(([key, value]: [string, any]) => (
                              <div
                                key={key}
                                className="flex items-center justify-between p-2"
                              >
                                <span className="font-medium text-[#969BAA]">
                                  {parseKey(key)}
                                </span>
                                <span className="text-white">
                                  {key === "orderDeadline" ||
                                  key === "acquisitionStartDate" ||
                                  key === "acquisitionEndDate"
                                    ? moment(value)?.format(
                                        "HH:mm:ss DD/MM/YYYY"
                                      )
                                    : value}
                                </span>
                              </div>
                            )))}

                    {props?.data?.optionsPerProductType?.options &&
                      typeof props?.data?.optionsPerProductType?.options ===
                        "object" &&
                      Object.entries(
                        props?.data?.optionsPerProductType?.options
                      ).length > 0 && (
                        <>
                          {Object.entries(
                            props?.data?.optionsPerProductType?.options
                          ).map(([key, value]: any) => (
                            <>
                              {key !== "End user country" && (
                                <Divider
                                  style={{
                                    background: "#545C77",
                                    margin: "10px 0",
                                  }}
                                />
                              )}

                              <div
                                key={key}
                                className="flex items-center justify-between p-2"
                              >
                                <span className="font-medium text-[#969BAA]">
                                  {parseKey(key)}
                                </span>
                                <span className="text-white">{value}</span>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                  </>
                )}
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    let a = props?.data?.images?.map((itemM: any) => itemM?.id);
    setImagesSelected(a);
  }, [props?.data]);

  useEffect(() => {
    let values = props?.form?.getFieldValue(["oneatlas"]);
    if (values && typeof values === "object") {
      setDataProduction(values);
    } else {
      setDataProduction({});
    }
  }, [props?.form?.getFieldValue(["oneatlas"])]);

  useEffect(() => {
    let values = props?.form?.getFieldValue(["properties"]);

    let a =
      values?.notifications?.notifications?.channels?.channels?.addressee?.to
        ?.to;

    if (values && typeof values === "object") {
      setDataProperties(
        props?.data?.type === "TASKING"
          ? {
              "Delivery type": values?.delivery?.type,
              "End user name": values?.endUsers?.endUsers?.endUserName,
              "End user country": values?.endUsers?.endUsers?.country,
              Notification: a,
              "Days before end": Number(values?.daysBeforeEnd),
            }
          : {
              "Delivery type": values?.delivery?.type,
              "End user name": values?.endUsers?.endUsers?.endUserName,
              "End user country": values?.endUsers?.endUsers?.country,
              Notification: a,
            }
      );
    } else {
      setDataProperties({});
    }
  }, [props?.form?.getFieldValue(["properties"])]);

  return (
    <div style={props?.style}>
      {props?.data?.is_submited &&
        props?.data?.actual_price?.total_price !== null &&
        props?.data?.order_cost?.total_price && <>{renderPriceDiv()}</>}

      {/* {renderPriceDiv()} */}
      {renderInfoDiv()}
      <CheckboxGroup
        className="flex flex-col gap-2 mb-5"
        options={plainOptions}
        onChange={() => {}}
        disabled={props?.data?.is_sent}
        value={plainOptions}
      />
    </div>
  );
};

export default ConfirmOrderComp;
