import httpService from "../httpService";
import { apis } from "../../constants/apis";
import { IProviderConfig } from "./providerConfigInterface";
import { IResponse, IResponsePage } from "../../types/common";
import { IFilterProvider } from "../../pages/providerBranch/ListProviderBranch";

class ProviderConfigService {
  getAll(filter?: IFilterProvider) {
    return httpService.get<IResponse<IResponsePage<IProviderConfig>>>(
      `${apis.provider.getAll}`,
      { params: filter }
    );
  }
  getDetail(id: number) {
    return httpService.get(`${apis.provider.getByID}/${id}`);
  }

  updateProvider(id: number, body: any) {
    return httpService.put(`${apis.provider.getByID}/${id}`, body);
  }
  postProvider(body: any) {
    return httpService.post(`${apis.provider.url}`, body);
  }
  providerMe(providerId: number) {
    return httpService.get(`${apis.provider.url}/me/${providerId}`);
  }
}

export default new ProviderConfigService();
