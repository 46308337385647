import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { CoordinateDisplayType, SearchType } from "../../enums";
import { getProvider, search } from "../../services/mapServices";
import dayjs from "dayjs";
import moment from "moment";

let a = uuidv4();

interface IMapState {
  sourceId: string;
  maplibreGL: any;
  draw: any;
  searchResultTotal?: any;
  typeSearch: string;
  searchResults?: any;
  filterSource: {
    planet: {};
    capella: {};
    oneatlas: {};
  };
  selectedImage: any; //use for show info,
  activeFeature: any;
  features: any;
  sourceData?: any;
  commonFilter?: any;
  selected?: any; // de draw item image in map
  flagFilter?: any; // flagFilter cua Cuong de check filter tren header
  clickFilter?: any;
  idSearch?: string;
  maplibreGLConfirmOrder?: any; //

  drawType?: any;
  coordinateDisplayType?: any;
  typeImage?: any; // for select to search image by polygon
  providerId?: any;
  flagEditPolygon?: any;
  arrProviders?: any; // provider for search
  flagSearch?: any;
  arrAllProviders?: any;

  allProvider?: any; // includes active & inactive
  showRecenter?: any;

  valueCheckAOI?: {
    progTypeNames?: any;
    missions?: any;
    acquisitionDate: {
      startDate?: any;
      endDate?: any;
    };
    optical: {
      acquisitionMode?: any;
      maxCloudCover?: any;
      maxIncidenceAngle?: any;
    };
    aoi?: any;
  };
}

const initialState: IMapState = {
  sourceId: uuidv4() + "_tasking",
  draw: undefined,
  maplibreGL: undefined,
  maplibreGLConfirmOrder: undefined,
  arrAllProviders: [],
  searchResultTotal: {
    planet: 0,
    oneAtlas: 0,
    capella: 0,
    geohub: 0,
  },

  searchResults: {
    planet: [],
    oneAtlas: [],
    capella: [],
    geohub: [],
  },

  filterSource: {
    planet: {},
    capella: {},
    oneatlas: {},
  },

  typeSearch: SearchType.PLANET,
  selectedImage: {},
  activeFeature: {
    id: a,
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [
        [
          [105.81739346827698, 21.040681100413252],
          [105.87105380813034, 21.040681100413252],
          [105.87105380813034, 20.989444975601373],
          [105.81739346827698, 20.989444975601373],
          [105.81739346827698, 21.040681100413252],
        ],
      ],
      type: "Polygon",
    },
    name: "Polygon 1",
  },
  features: [
    {
      id: a,
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [
            [105.81739346827698, 21.040681100413252],
            [105.87105380813034, 21.040681100413252],
            [105.87105380813034, 20.989444975601373],
            [105.81739346827698, 20.989444975601373],
            [105.81739346827698, 21.040681100413252],
          ],
        ],
        type: "Polygon",
      },
      name: "Polygon 1",
    },
  ],
  sourceData: {
    type: "FeatureCollection",
    features: [],
  },

  commonFilter: {
    sources: {},
    // resolution: [0, 15],
    resolution: "0, 15",
    incidenceAngle: [0, 90],
    cloudCover: [0, 100],
    // @ts-ignore
    timeFrom: dayjs().subtract(3, "month"),
    timeTo: dayjs(),
  },
  selected: [],
  flagFilter: false,
  clickFilter: false,
  idSearch: "",
  drawType: undefined,
  coordinateDisplayType: CoordinateDisplayType.DD,
  typeImage: "",
  providerId: "planet", // check sau khi search se set phan tu [0]
  flagEditPolygon: false,

  arrProviders: [],
  flagSearch: false,
  showRecenter: false,
  allProvider: [],

  valueCheckAOI: {
    progTypeNames: ["ONENOWATTEMPTS"],
    missions: ["PLEIADESNEO"],
    acquisitionDate: {
      startDate: dayjs(),
      endDate: dayjs().add(3, "days"),
    },
    optical: {
      acquisitionMode: "MONO",
      maxCloudCover: 20,
      maxIncidenceAngle: 30,
    },
  },
};

export const searchAsync = createAsyncThunk(
  "mapTasking/search",
  async (payload) => {
    try {
      const response = await search(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getProviderAsync = createAsyncThunk(
  "mapTasking/getProvider",
  async (payload) => {
    try {
      const response = await getProvider(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const mapTaskingSlice = createSlice({
  name: "mapTasking",
  initialState,
  reducers: {
    setDraw: (state, action) => {
      state.draw = action?.payload;
    },

    changeMaplibreGLConfirmOrder: (state, action) => {
      state.maplibreGLConfirmOrder = action?.payload;
    },

    changeMaplibreGL: (state, action) => {
      state.maplibreGL = action?.payload;
    },

    changeSearchResultTotal: (state, action) => {
      state.searchResultTotal = action?.payload;
    },

    changeTypeSearch: (state, action) => {
      state.typeSearch = action?.payload;
    },

    changeSearchResults: (state, action) => {
      state.searchResults = action?.payload;
    },
    setFilterSource: (state, action) => {
      state.filterSource = action.payload;
    },

    changeValueCheckAOI: (state, action) => {
      state.valueCheckAOI = action.payload;
    },
    changeSelectedImage: (state, action) => {
      state.selectedImage = action?.payload;
    },

    addFeature: (state, action) => {
      let a = state.features;
      // @ts-ignore
      state.features.push({
        ...action?.payload,
        name: "Polygon " + (a.length + 1),
      });
    },

    setArrFeatures: (state, action) => {
      state.features = action.payload;
    },
    // updateFeature: (state, action) => {
    //   const features: Object[] = state.features;
    //   // @ts-ignore
    //   let index = features?.findIndex((x) => x["id"] == action?.payload?.id);
    //   // @ts-ignore
    //   state.features[index] = {
    //     ...action?.payload,
    //     name: state.features[index]?.name,
    //   };
    //   debugger;
    //   // @ts-ignore
    //   if (state.activeFeature && state.activeFeature?.id == action?.payload?.id)
    //     state.activeFeature = {
    //       ...action?.payload,
    //       name: state.features[index]?.name,
    //     };

    //   debugger;

    //   // if (state.draw) {
    //   //   state.draw.changeMode("simple_select", { featureIds: [] });
    //   // }
    // },

    updateFeature: (state, action) => {
      const index = state.features.findIndex(
        (x: any) => x["id"] === action?.payload?.id
      );

      if (index !== -1) {
        // Cập nhật feature trong danh sách
        state.features[index] = {
          ...state.features[index],
          ...action.payload,
        };

        // Nếu `activeFeature` hiện tại cần được cập nhật
        if (
          state.activeFeature &&
          state.activeFeature.id === action.payload.id
        ) {
          state.activeFeature = {
            ...state.activeFeature,
            ...action.payload,
          };
        } else {
          state.activeFeature = {
            ...state.features[index],
            ...action.payload,
          };
        }
      }
    },

    updateFeatureArchive: (state, action) => {
      const index = state.features.findIndex(
        (x: any) => x["id"] === action?.payload?.id
      );

      if (index !== -1) {
        // Cập nhật feature trong danh sách
        state.features[index] = {
          ...state.features[index],
          ...action.payload,
        };

        // Nếu `activeFeature` hiện tại cần được cập nhật
        if (
          state.activeFeature &&
          state.activeFeature.id === action.payload.id
        ) {
          state.activeFeature = {
            ...state.activeFeature,
            ...action.payload,
          };
        }
      }
    },

    removeFeature: (state, action) => {
      const features: Object[] = state.features;
      // @ts-ignore
      let index = features?.findIndex((x) => x?.id == action?.payload?.id);
      if (index >= 0) state.features.splice(index, 1);
    },
    clearFeatures: (state) => {
      state.features = [
        {
          id: a,
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [
              [
                [105.81739346827698, 21.040681100413252],
                [105.87105380813034, 21.040681100413252],
                [105.87105380813034, 20.989444975601373],
                [105.81739346827698, 20.989444975601373],
                [105.81739346827698, 21.040681100413252],
              ],
            ],
            type: "Polygon",
          },
          name: "Polygon 1",
        },
      ];
      state.activeFeature = {
        id: a,
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [
            [
              [105.81739346827698, 21.040681100413252],
              [105.87105380813034, 21.040681100413252],
              [105.87105380813034, 20.989444975601373],
              [105.81739346827698, 20.989444975601373],
              [105.81739346827698, 21.040681100413252],
            ],
          ],
          type: "Polygon",
        },
        name: "Polygon 1",
      };
      state.selected = [];
      // state.sourceData = [];
    },

    changeActiveFeature: (state, action) => {
      let a = state?.features;
      state.activeFeature = {
        ...action.payload,
        name: action.payload?.name ?? "Polygon " + a.length,
      };
    },

    setSourceData: (state, action) => {
      state.sourceData = action.payload;
    },

    setCommonFilter: (state, action) => {
      state.commonFilter = action.payload;
    },

    setSelected: (state, action) => {
      state.selected = action.payload;
    },

    setFlagFilter: (state, action) => {
      state.flagFilter = action.payload;
    },
    setClickFilter: (state, action) => {
      state.clickFilter = action.payload;
    },

    setSearchID: (state, action) => {
      state.idSearch = action.payload;
    },

    setDrawType: (state, action) => {
      state.drawType = action.payload;
    },

    setCoordinateDisplayType: (state, action) => {
      state.coordinateDisplayType = action.payload;
    },

    changeTypeImage: (state, action) => {
      state.typeImage = action.payload;
    },

    setProviderId: (state, action) => {
      state.providerId = action.payload;
    },
    setFlagEditPolygon: (state, action) => {
      state.flagEditPolygon = action.payload;
    },

    setArrProviders: (state, action) => {
      state.arrProviders = action.payload;
    },

    setFlagSearch: (state, action) => {
      state.flagSearch = action.payload;
    },
    setArrAllProviders: (state, action) => {
      state.arrAllProviders = action.payload;
    },

    setAllProvider: (state, action) => {
      state.allProvider = action.payload;
    },

    setShowRecenter: (state, action) => {
      state.showRecenter = action.payload;
    },
  },
});

export const {
  setDraw,
  changeMaplibreGL,
  changeSearchResultTotal,
  changeTypeSearch,
  changeSearchResults,
  setFilterSource,
  changeSelectedImage,
  setArrAllProviders,
  addFeature,
  updateFeature,
  removeFeature,
  clearFeatures,
  changeActiveFeature,
  setShowRecenter,
  setSelected,
  setSourceData,
  setCommonFilter,
  setFlagFilter,
  setClickFilter,
  setSearchID,
  setDrawType,
  setCoordinateDisplayType,
  changeTypeImage,
  changeMaplibreGLConfirmOrder,
  setProviderId,
  setFlagEditPolygon,
  setArrProviders,
  setFlagSearch,
  setAllProvider,
  setArrFeatures,
  updateFeatureArchive,
  changeValueCheckAOI,
} = mapTaskingSlice.actions;

export default mapTaskingSlice.reducer;
