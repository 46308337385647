import { Dropdown, MenuProps, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonIcons from "../../assets/icons";
import LayerSvg from "../../assets/svg/layer.svg";
import { DrawType } from "../../enums";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import { setClickFilter } from "../../store/reducers/mapSlice";
import { setFlagSearch } from "../../store/reducers/mapTaskingSlice";
import {
  resetProviderData,
  resetState,
  setIsSearching,
} from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";

import CommonComponents from "../Commons";
import ButtonIconComp from "./components/ButtonIcon";

import { LoadingOutlined } from "@ant-design/icons";
import maplibregl from "maplibre-gl";
import CloseSvg from "../../assets/svg/close.svg";
import SearchImageIcon from "../../assets/svg/search-image.svg";
import SearchInfoIcon from "../../assets/svg/search-info.svg";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import {
  changeShowOrderTasking,
  setPolygonOrderTasking,
} from "../../store/reducers/orderSlice";
import Api from "./nominatim";
interface IProps {
  drawType?: DrawType;
  onChangeDrawType: (type: DrawType) => void;
  onShowFilter: () => void;
  // setClickFilter: any;
  clickFilter: boolean;
  type?: any;
}

export default function Header(props: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let activeFeature = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  const loadingGlobal = useSelector(
    (state: RootState) => state.appCommonSlice.loadingGlobal
  );
  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );
  const allProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );
  const typeImage = useSelector(
    (state: RootState) => state.mapTasking.typeImage
  );

  const valueCheckAOI = useSelector(
    (state: RootState) => state.mapTasking.valueCheckAOI
  );
  console.log("valueCheckAOI", valueCheckAOI);

  const map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  const [valueSearch, setValueSearch] = useState<any>();
  const [dataAdd, setDataAdd] = useState<any>([]);
  const [visibleSearch, setVisibleSearch] = useState(false);

  const onChangeDrawType = (type: DrawType) => {
    props.onChangeDrawType(type);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
  };
  const handleSearch = async () => {
    dispatch(setFlagSearch(true));

    dispatch(resetProviderData());
    dispatch(resetState());

    dispatch(changeShowDrawer(true));
    dispatch(changeLeftDrawerType("Search results"));
    dispatch(setIsSearching(true));
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.RectangleDrawIcon />
          <span>Rectangle</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.RECTANGLE),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.PolygonDrawIcon />
          <span>Polygon</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.POLYGON),
    },

    {
      key: "4",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.SimpleSelectIcon />
          <span>None</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.NONE),
    },
    {
      type: "divider",
    },
    {
      key: "5",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <img src={LayerSvg} />
          <span>Histories</span>
        </div>
      ),
      onClick: () => {
        dispatch(changeBottomDrawerType("History list"));
        dispatch(changeShowBottomDrawer(true));
      },
    },
  ];

  const renderCursor = () => {
    let a = "pointer";
    if (props?.type === "map-tasking" && !activeFeature && !activeFeature?.id) {
      a = "not-allowed";
    }
    return a;
  };

  const renderHandle = async () => {
    if (valueSearch?.length > 0) {
      dispatch(setLoadingGlobal(true));
      let resGeocode = await Api.geocode(valueSearch);
      // let resLenient = await axios.get(
      //   "https://tera-api.geohub.ai/loc/search",
      //   {
      //     params: {
      //       lenient: true,
      //       q: valueSearch,
      //       text: valueSearch,
      //     },
      //     withCredentials: false,
      //   }
      // );

      // let res = await axios.get("https://tera-api.geohub.ai/api/v1/search", {
      //   params: {
      //     q: valueSearch,
      //     text: valueSearch,
      //   },
      //   withCredentials: false,
      // });

      // let a = resGeocode?.data?.concat(resLenient?.data?.features ?? []);
      let a = resGeocode?.data;
      setDataAdd(a ?? []);
      setVisibleSearch(true);

      dispatch(setLoadingGlobal(false));
    }
  };

  useEffect(() => {
    dispatch(setClickFilter(false));
  }, [location]);

  const renderAdd = (tags: any) => {
    let label = "",
      address = "",
      city = "";

    try {
      for (const tag of tags) {
        if (Array.isArray(tag) && tag.length === 2) {
          const key = tag[0].toLowerCase();
          const value = tag[1];

          if (key === "name") {
            label = value;
          } else if (key === "addr:housenumber") {
            address += (address ? ", " : "") + value;
          } else if (key === "addr:street") {
            address += (address ? ", " : "") + value;
          } else if (key === "addr:suburb") {
            address += (address ? ", " : "") + value;
          } else if (key === "addr:city") {
            city = value;
          }
        }
      }
    } catch (error) {
      console.error("Error processing tags:", error);
    }

    // Xây dựng chuỗi trả về
    let result = label;
    if (label && address) {
      result += ` - ${address}`;
    } else if (address) {
      result += address;
    }
    if (city) {
      result += `, ${city}`;
    }

    return result;
  };

  const handleSearchImage = (urgent?: boolean) => {
    if (props?.type !== "map-tasking") {
      // onShowFilter();
      // return;
      handleSearch();
    } else {
      if (activeFeature && activeFeature?.id) {
        dispatch(changeShowOrderTasking(true));
        dispatch(setPolygonOrderTasking(selectedPolygon));
        dispatch(changeLeftDrawerType(""));
        setTimeout(() => {
          if (!!urgent) {
            navigate("/tasking-order?urgent=true");
            return;
          }
          navigate("/tasking-order");
        }, 1000);
      }
    }
  };
  const checkTypeImageIncludeProvider = (typeImage: string) => {
    let listProviderMatch = allProviders?.filter((itemF: any) =>
      itemF?.metadatas?.some(
        (itemMF: any) =>
          itemMF?.name === "IMAGE_TYPE_SEARCH" &&
          itemF?.enabled_archive &&
          itemMF?.value === typeImage
      )
    );
    return listProviderMatch;
  };

  const [flagNormalTasking, setFlagNormalTasking] = useState(false);

  const handCheckDisableNormalTasking = () => {
    if (props?.type !== "map-tasking") {
      setFlagNormalTasking(
        checkTypeImageIncludeProvider(typeImage)?.length <= 0
      );
    } else {
      if (props?.loading) {
        setFlagNormalTasking(true);
      } else {
        if (activeFeature?.properties?.errorMessage) {
          setFlagNormalTasking(true);
        } else {
          if (orderedProvider?.length > 0) {
            setFlagNormalTasking(false);
          } else {
            setFlagNormalTasking(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    handCheckDisableNormalTasking();
  }, [activeFeature, orderedProvider, props?.loading, allProviders]);

  //! render
  return (
    <div
      className="flex justify-between w-full"
      onClick={() => {
        if (visibleSearch) {
          setVisibleSearch(false);
        }
      }}
    >
      <div className="block">
        <CommonComponents.InputUI
          className="w-[343px] h-9 !px-2 border-b-0 ml-4"
          placeholder="Search for place or coordinates..."
          value={valueSearch}
          onChange={handleChangeSearch}
          onKeyDown={(e: any) => {
            if (e.key === "Enter" && valueSearch?.length > 0) {
              renderHandle(); // Gọi hàm renderHandle khi nhấn Enter
            }
          }}
          disabled={loadingGlobal}
          suffix={
            <div
              className="w-fit div-one-center shadow-hover"
              onClick={renderHandle}
              style={{ fontSize: "12px", cursor: renderCursor() }}
            >
              <div className="flex items-center ">
                <Spin
                  className="mr-2"
                  spinning={loadingGlobal}
                  indicator={<LoadingOutlined spin />}
                />

                {valueSearch?.length > 0 && (
                  <img
                    src={CloseSvg}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValueSearch("");
                      setVisibleSearch(false);
                      setDataAdd([]);
                    }}
                    style={{ marginRight: "8px", width: "12px" }}
                  />
                )}
                <img src={SearchInfoIcon} className="size-3 -rotate-90" />
              </div>
            </div>
          }
        />
        {dataAdd?.length > 0 && visibleSearch && (
          <div
            className="absolute left-4 bg-common-ink100 h-fit max-h-[300px] overflow-auto w-[343px] px-1"
            style={{ color: "white" }}
          >
            {(() => {
              const elements = [];
              for (const [index, data] of dataAdd?.entries()) {
                elements.push(
                  <div
                    key={index}
                    className="p-2 cursor-pointer hover:bg-common-ink400"
                    onClick={() => {
                      if (data?.lat && data?.lng) {
                        new maplibregl.Marker()
                          .setLngLat([data?.lng, data?.lat])
                          .addTo(map);

                        map.flyTo({
                          center: [data?.lng, data?.lat],
                          zoom: 15,
                          essential: true,
                        });
                        setValueSearch(renderAdd(data?.tags));
                        setVisibleSearch(false);
                      } else {
                        new maplibregl.Marker()
                          .setLngLat([data?.lon, data?.lat])
                          .addTo(map);

                        map.flyTo({
                          center: [data?.lon, data?.lat],
                          zoom: 15,
                          essential: true,
                        });
                        setValueSearch(data?.name);
                        setVisibleSearch(false);
                      }
                    }}
                  >
                    <p style={{ color: "white" }}>
                      {data?.tags ? renderAdd(data?.tags) : data?.name}
                    </p>{" "}
                  </div>
                );
              }
              return elements;
            })()}
          </div>
        )}

        {dataAdd?.length === 0 && visibleSearch && (
          <div
            className="absolute left-4 bg-common-ink100 h-fit max-h-[300px] overflow-auto w-[343px] px-1"
            style={{ color: "white" }}
          >
            <p className="p-[10px]"> No data</p>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 !rounded-[3px] mr-[10px]">
        {loadingGlobal && (
          <svg
            className="animate-spin mr-6"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              transform="rotate(45 12 12)"
              stroke="#565656"
              stroke-width="3"
            />
            <path
              d="M19.0708 19.0711C15.1656 22.9763 8.83395 22.9763 4.92871 19.0711C1.02347 15.1658 1.02347 8.8342 4.92871 4.92896"
              stroke="#60CDFF"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        )}

        <Dropdown menu={{ items }} trigger={["click"]}>
          <ButtonIconComp
            className="!h-8 !w-[30px] !min-w-[30px] !rounded flex items-center"
            item={{
              title: (
                <div className="flex items-center justify-center gap-1">
                  <div>Area of Interest</div>
                  <CommonIcons.ArrowDownIcon />
                </div>
              ),
              iconNode: (
                <div className="flex items-center">
                  {props.drawType === DrawType.RECTANGLE ? (
                    <CommonIcons.RectangleDrawIcon />
                  ) : null}
                  {props.drawType === DrawType.POLYGON ? (
                    <CommonIcons.PolygonDrawIcon />
                  ) : null}
                  {!props.drawType && <CommonIcons.RectangleDrawIcon />}
                </div>
              ),
              classTitle: "text-sm",
            }}
          ></ButtonIconComp>
        </Dropdown>

        <ButtonIconComp
          className="!h-8 !w-[30px] !min-w-[30px] !rounded !bg-common-primary500 flex items-center !cursor-pointer"
          item={{
            icon: props?.type !== "map-tasking" ? SearchImageIcon : null,
            title:
              props?.type === "map-tasking" ? "Normal Tasking" : "Search Image",
            classTitle: "!text-black text-sm",
          }}
          onClick={() => {
            if (
              props?.type !== "map-tasking" &&
              checkTypeImageIncludeProvider(typeImage)?.length <= 0
            ) {
              return;
            }
            handleSearchImage();
          }}
          disabled={flagNormalTasking}
        />
        {props?.type === "map-tasking" && (
          <ButtonIconComp
            className={`!h-8 !w-[30px] !min-w-[30px] !rounded !bg-[#08c385] flex items-center !cursor-pointer `}
            item={{
              // icon: props?.type !== "map-tasking" ? SearchImageIcon : null,
              title: "Urgent Tasking",
              classTitle: "!text-black text-sm",
            }}
            onClick={() => {
              if (
                props?.type !== "map-tasking" &&
                checkTypeImageIncludeProvider(typeImage)?.length <= 0
              ) {
                return;
              }
              handleSearchImage(true);
            }}
            disabled={
              flagNormalTasking ||
              valueCheckAOI?.progTypeNames?.includes("ONEDAY")
            }
          />
        )}
      </div>
    </div>
  );
}
