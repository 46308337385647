import { Button, Divider, Dropdown, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CommonIcons from "../../../assets/icons";
import CloseSvg from "../../../assets/svg/dismiss.svg";
import { changeLeftDrawerType } from "../../../store/reducers/drawerSlice";
import { ChangeActionActiveRight } from "../../../store/reducers/statusAction";
import ButtonIconComp from "../../MapTasking/components/ButtonIcon";
import BaseMap from "./Basemap";

import { useEffect } from "react";
import { buttonTheme } from "../../../themes/commonTheme";
import {
  CoordinateDisplayType,
  MouseCoordinateElementIds,
} from "../../../enums";
import { setCoordinateDisplayType } from "../../../store/reducers/mapTaskingSlice";
import {
  displayMouseCoordinate,
  getCurrentMouseCoordinate,
} from "../../../helpers/map";
import { RootState } from "../../../store/store";

interface IProps {
  hidden: boolean;
}
const SettingBasemap = (props: IProps) => {
  const coordinateDisplayType = useSelector(
    (state: RootState) => state.mapTasking.coordinateDisplayType
  );
  //! state

  const { hidden } = props;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: CoordinateDisplayType.DD,
      onClick: () => onChangeCoordinateDisplayType(CoordinateDisplayType.DD),
    },
    {
      key: "2",
      label: CoordinateDisplayType.DMS,
      onClick: () => onChangeCoordinateDisplayType(CoordinateDisplayType.DMS),
    },
  ];

  const dispatch = useDispatch();
  //! function
  const handleClose = () => {
    dispatch(changeLeftDrawerType(""));
    dispatch(ChangeActionActiveRight(""));
  };

  const onChangeCoordinateDisplayType = (type: CoordinateDisplayType) => {
    if (coordinateDisplayType === type) {
      return;
    }
    dispatch(setCoordinateDisplayType(type));
    displayMouseCoordinate(
      MouseCoordinateElementIds.LNG_ID,
      MouseCoordinateElementIds.LAT_ID,
      coordinateDisplayType,
      type,
      getCurrentMouseCoordinate(
        MouseCoordinateElementIds.LNG_ID,
        MouseCoordinateElementIds.LAT_ID
      )
    );
  };

  //! effect

  useEffect(() => {
    dispatch(ChangeActionActiveRight("Setting"));

    return () => {
      dispatch(ChangeActionActiveRight(""));
    };
  }, []);
  //! render

  return (
    <div className={`w-[460px] h-full p-2 overflow-x-auto`}>
      <div className="flex justify-between items-center">
        <div className="text-white text-sm font-semibold flex items-center gap-1 ">
          <CommonIcons.SettingIcon />
          <p>Map Settings</p>
        </div>

        <ButtonIconComp
          item={{
            name: "Close",

            icon: CloseSvg,
            description: "Close",
          }}
          className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          onClick={() => handleClose()}
        />
      </div>

      <Divider className="bg-common-line my-2" />

      <Dropdown
        menu={{ items: items }}
        trigger={["click"]}
        className="w-full mb-3 mt-3"
      >
        <Button
          icon={<CommonIcons.ArrowDownIcon />}
          iconPosition="end"
          className={`${buttonTheme.className.standard} !text-xs !h-7 !min-w-[74px] flex justify-between`}
        >
          <span id={MouseCoordinateElementIds.TYPE_ID}>
            {coordinateDisplayType}
          </span>
        </Button>
      </Dropdown>

      <div className="text-white p-2">
        <p className="text-base mb-4">Base map</p>

        <BaseMap />
      </div>
    </div>
  );
};

export default SettingBasemap;
