import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProviderConfig } from "../../services/providerConfigs/providerConfigInterface";
import { IResponsePage } from "../../types/common";
import { ERoleAccessControl } from "../../enums";
import { IGlobalConfig } from "../../services/globalConfig/interfaces/IGlobalConfig.interface";
import { getMetakey } from "../../services/metaKeys/metaKeyService";

interface IAppCommonState {
  currentURLPath?: string;
  providerConfigStore?: IResponsePage<IProviderConfig>;
  loadingGlobal?: boolean;
  showOrderTasking?: boolean;

  showPaging?: boolean;
  showFilter?: boolean;
  showSort?: boolean;
  globalConfig?: IGlobalConfig;
  whoami?: any;
  groups?: string[];
  publicConfig?: any;
  roleUsing?: string[];
  showListImagesSearch?: boolean;
  metaKey?: any;
  totalPage?: any;
  pageNumber?: any;
  pageSize?: any;
  dataStatusOrder: any;
}

const initialState: IAppCommonState = {
  currentURLPath: undefined,
  providerConfigStore: {},
  loadingGlobal: false,
  showOrderTasking: false,
  showPaging: false,
  showFilter: false,
  showSort: false,
  globalConfig: undefined,
  whoami: {
    active: true,
    authenticator_assurance_level: "aal1",
    identity: {
      id: "a9977123-df23-4aca-b162-b19c62c841c4",
      traits: {
        email: "admin@geohub.vn",
        name: {
          first: "EOM system",
          last: "Administrator",
        },
        address: "Thanh Xuan",
        phonenumber: "0962458211",
      },
    },
  },
  groups: [],
  publicConfig: {},
  roleUsing: [],
  showListImagesSearch: false,
  metaKey: [],
  totalPage: 0,
  pageNumber: 0,
  pageSize: 8,
  dataStatusOrder: [],
};

export const appCommonSlice = createSlice({
  name: "app-common-state",
  initialState,
  reducers: {
    setMetaKey: (state, action: PayloadAction<any>) => {
      state.metaKey = action.payload;
    },
    setDataStatusOrder: (state, action: PayloadAction<any>) => {
      state.dataStatusOrder = action.payload;
    },
    setCurrentURLPath: (state, action: PayloadAction<string | undefined>) => {
      state.currentURLPath = action.payload;
    },

    setProviderConfigStore: (
      state,
      action: PayloadAction<IResponsePage<IProviderConfig>>
    ) => {
      state.providerConfigStore = action.payload;
    },

    setLoadingGlobal: (state, action: PayloadAction<boolean>) => {
      state.loadingGlobal = action.payload;
    },

    setShowOrderTasking: (state, action: PayloadAction<boolean>) => {
      state.showOrderTasking = action.payload;
    },

    setShowPaging: (state, action) => {
      state.showPaging = action.payload;
    },

    setShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },

    setShowSort: (state, action) => {
      state.showSort = action.payload;
    },
    setGlobalConfig: (state, action) => {
      state.globalConfig = action.payload;
    },
    setWhoAmI: (state, action) => {
      state.whoami = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
      const roleUsing = () => {
        if (action.payload?.includes(ERoleAccessControl.admin))
          return ["admin"];
        if (action.payload?.includes(ERoleAccessControl.user)) return ["user"];
        if (action.payload?.length < 0) return ["user"];
      };

      state.roleUsing = action.payload?.includes(ERoleAccessControl.admin)
        ? ["admin"]
        : action.payload?.includes(ERoleAccessControl.user)
        ? ["user"]
        : ["user"];
    },
    setRoleUsing: (state, action: PayloadAction<string[]>) => {
      state.roleUsing = action.payload;
    },
    setPublicConfig: (state, action) => {
      state.publicConfig = action.payload;
    },

    changeShowListImagesSearch: (state, action) => {
      state.showListImagesSearch = action.payload;
    },

    setTotalPage: (state, action) => {
      state.totalPage = action.payload;
    },

    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },

    resetStateFooter: (state, action) => {
      state.showFilter = false;
      state.showPaging = false;
      state.showSort = false;

      state.totalPage = 0;
      state.pageNumber = 0;
      state.pageSize = 8;
    },
  },
});

export const getMetakeyAsync = createAsyncThunk(
  "app-common-state/getMetakey",
  async (payload) => {
    try {
      const response = await getMetakey(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const {
  setCurrentURLPath,
  setProviderConfigStore,
  setLoadingGlobal,
  setShowOrderTasking,
  setShowPaging,
  setShowSort,
  setShowFilter,
  setGlobalConfig,
  setWhoAmI,
  setGroups,
  setPublicConfig,
  changeShowListImagesSearch,
  setRoleUsing,
  setMetaKey,
  resetStateFooter,
  setTotalPage,
  setPageNumber,
  setDataStatusOrder,
} = appCommonSlice.actions;

export default appCommonSlice.reducer;
