import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonIcons from "../../assets/icons";
import ManageProjectIcon from "../../assets/svg/MyProject/icon-menu.svg";
import ReportSvg from "../../assets/svg/Report/icon-menu.svg";
import MenuMapSvg from "../../assets/svg/menu-map.svg";
import ExploreSvg from "../../assets/svg/Explore.svg";
import BranchSvg from "../../assets/svg/iconBranch.svg";
import { ERoleAccessControl } from "../../enums";
import { baseRoutes } from "../../router/RouterPage";
import { RootState } from "../../store/store";
import MenuItem from "./MenuItem";

import LogoSvg from "../../assets/svg/logo.svg";
import useToggle from "../../hooks/useToggle";
import CommonComponents from "../Commons";
import SwitchRole from "./SwitchRole";
import OrderSvg from "../../assets/svg/Order.svg";
import NotificationSvg from "../../assets/svg/notification.svg";
import ProfileSvg from "../../assets/svg/profile-circle.svg";
import {
  changeLeftDrawerType,
  changeLeftDrawerTypeBefore,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";

import switchRoleSvg from "../../assets/svg/switchRole.svg";
import ArchiveOrder from "../../assets/svg/mangae-archive-order.svg";
import { Divider } from "antd";
import TaskingMapSvg from "../../assets/svg/tasking-map.svg";
import TaskingOrder from "../../assets/svg/order-tasking.svg";

export interface IChildrenTooltip {
  label: string;
  url: string;
}

export default function MenuBar({
  roleUsing,
}: {
  roleUsing: string[] | undefined;
}) {
  //! state
  const { expanded } = useSelector((state: RootState) => state.layoutSlice);
  const groups = useSelector((state: RootState) => state.appCommonSlice.groups);
  const publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );
  const { arrIdImage } = useSelector((state: RootState) => state.cartSlice);
  const { leftDrawerType, leftDrawerTypeBefore } = useSelector(
    (state: RootState) => state.drawerSlice
  );
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<null | string>(null);
  const { open, shouldRender, toggle } = useToggle();
  const classIcon = "!w-[28px] !h-[28px]";

  const menuExistingImage = (
    <div className="flex flex-col gap-1">
      {roleUsing?.includes(ERoleAccessControl.user) && (
        <MenuItem
          icon={<img src={ExploreSvg} className={classIcon} />}
          label="Explore Existing Images"
          url={baseRoutes.map.path}
          activeKey={activeKey}
          indexKey={"1"}
          setActiveKey={() => {
            setActiveKey("1");
          }}
          tooltipMain="Explore Existing Images"
        />
      )}

      {/* {roleUsing?.includes(ERoleAccessControl.user) && (
        <MenuItem
          icon={<img src={ArchiveOrder} className={classIcon} />}
          label="Order Existing Image"
          url={baseRoutes.order.children.managementOrder.path}
          activeKey={activeKey}
          indexKey={"10"}
          setActiveKey={() => {
            setActiveKey("10");
          }}
          tooltipMain="Order Existing Image"
        />
      )} */}

      <MenuItem
        icon={<img src={ArchiveOrder} className={classIcon} />}
        label="Order Existing Images"
        url={baseRoutes.order.children.managementOrder.path}
        activeKey={activeKey}
        indexKey={"10"}
        setActiveKey={() => {
          setActiveKey("10");
        }}
        tooltipMain="Order Existing Images"
      />
      {roleUsing?.includes(ERoleAccessControl.user) && (
        <Divider style={{ background: "#353B4F", margin: "10px 0" }} />
      )}

      {roleUsing?.includes(ERoleAccessControl.user) && (
        <MenuItem
          icon={<img src={TaskingMapSvg} className={classIcon} />}
          label="Explore Tasking Orders"
          url={baseRoutes.tasking.explore}
          activeKey={activeKey}
          indexKey={"11"}
          setActiveKey={() => {
            setActiveKey("11");
          }}
          tooltipMain="Explore Tasking Orders"
        />
      )}

      {/* {roleUsing?.includes(ERoleAccessControl.user) && (
        <MenuItem
          icon={<img src={ArchiveOrder} className={classIcon} />}
          label="Tasking Orders"
          url={baseRoutes.order.children.tasking.path}
          activeKey={activeKey}
          indexKey={"12"}
          setActiveKey={() => {
            setActiveKey("12");
          }}
          tooltipMain="Tasking Orders"
        />
      )} */}

      <MenuItem
        icon={<img src={TaskingOrder} className={classIcon} />}
        label="Tasking Orders"
        url={baseRoutes.order.children.tasking.path}
        activeKey={activeKey}
        indexKey={"12"}
        setActiveKey={() => {
          setActiveKey("12");
        }}
        tooltipMain="Tasking Orders"
      />

      {roleUsing?.includes(ERoleAccessControl.admin) && (
        <MenuItem
          icon={<img src={BranchSvg} className={classIcon} />}
          label="Branch"
          url={baseRoutes.providerBranch}
          activeKey={activeKey}
          indexKey={"2"}
          setActiveKey={() => {
            setActiveKey("2");
          }}
          tooltipMain="Branch"
        />
      )}

      {roleUsing?.includes(ERoleAccessControl.admin) && (
        <MenuItem
          icon={<img src={MenuMapSvg} className={classIcon} />}
          label="BaseMap"
          url={baseRoutes.basemapSetting}
          activeKey={activeKey}
          indexKey={"3"}
          setActiveKey={() => {
            setActiveKey("3");
          }}
          tooltipMain="BaseMap"
        />
      )}
    </div>
  );

  const bottomMenu = (
    <div className="flex flex-col gap-1">
      {roleUsing?.includes(ERoleAccessControl.user) && (
        <MenuItem
          icon={<img src={ManageProjectIcon} className={classIcon} />}
          label="My Project"
          url={baseRoutes.myProject.path}
          activeKey={activeKey}
          indexKey={"7"}
          setActiveKey={() => {
            setActiveKey("7");
          }}
          tooltipMain="My Project"
        />
      )}

      <MenuItem
        icon={<img src={ReportSvg} className={classIcon} />}
        label="Dashboard"
        url={baseRoutes.report.path}
        activeKey={activeKey}
        indexKey={"6"}
        setActiveKey={() => {
          setActiveKey("6");
        }}
        tooltipMain="Dashboard"
      />
      {!roleUsing?.includes(ERoleAccessControl.admin) && (
        <MenuItem
          icon={<img src={OrderSvg} className={classIcon} />}
          label={"Cart"}
          total={arrIdImage?.length}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          tooltipMain="Cart"
          onClick={() => {
            if (leftDrawerType === "cart") {
              if (leftDrawerTypeBefore !== "") {
                dispatch(changeLeftDrawerType(leftDrawerTypeBefore));
                dispatch(changeShowDrawer(true));
              } else {
                dispatch(changeLeftDrawerType(""));
                dispatch(changeShowDrawer(false));
              }
            } else {
              dispatch(changeLeftDrawerTypeBefore(leftDrawerType));
              dispatch(changeShowDrawer(true));
              dispatch(changeLeftDrawerType("cart"));
            }
          }}
        />
      )}

      <MenuItem
        icon={<img src={NotificationSvg} className={classIcon} />}
        label="Notification"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        tooltipMain="Notification"
        onClick={() => {
          if (leftDrawerType === "notification") {
            dispatch(changeLeftDrawerType(""));
            dispatch(changeShowDrawer(false));
          } else {
            dispatch(changeShowDrawer(true));
            dispatch(changeLeftDrawerType("notification"));
          }
        }}
      />
      <MenuItem
        icon={<img src={ProfileSvg} className={classIcon} />}
        label="Account"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        acrossUrl={publicConfig?.REACT_APP_ENDPOINT_ACCOUNT}
        tooltipMain="Account"
      />

      {groups?.includes(ERoleAccessControl.admin) && (
        <div onClick={() => toggle()}>
          <MenuItem
            icon={<img src={switchRoleSvg} className={classIcon} />}
            label="Switch Role Admin"
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            tooltipMain="Switch Role Admin"
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`h-full bg-common-sbackground1 flex flex-col ${
        expanded ? "w-[268px]" : "w-[64px]"
      }`}
    >
      <div className="flex-none flex items-center gap-3 pl-2 pr-4 py-4 h-[56px]">
        <img src={LogoSvg} className="h-[40px] w-[40px] min-w-[40px]" />
      </div>
      <div className="px-1">
        <Divider
          style={{
            background: "#353B4F",
            marginBottom: "10px ",
            marginTop: "0",
          }}
        />
      </div>

      <div className="grow flex flex-col justify-between px-1 pb-2">
        <div className="grow flex flex-col">
          {menuExistingImage}

          {/* {!expanded && <Divider className="bg-common-primary500 my-1" />}
          {menuTasking} */}
        </div>

        {bottomMenu}
      </div>

      {shouldRender && (
        <CommonComponents.ModalUI
          open={open}
          title="Switch Role"
          toggle={toggle}
          handleConfirm={() => null}
          hiddenFooter
        >
          <SwitchRole toggle={toggle} />
        </CommonComponents.ModalUI>
      )}
    </div>
  );
}
