import { ConfigProvider } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ESttausCode } from "./enums";
import { loadStaticConfigs } from "./helpers/common";
import PageLayout from "./layouts/PageLayout";
import NotificationProvider from "./providers/NotificationProvider";
import RouterPage from "./router/RouterPage";
import {
  getMetakeyAsync,
  setDataStatusOrder,
  setMetaKey,
} from "./store/reducers/appCommonSlice";
import { getImgsAsync, setArrIdImage } from "./store/reducers/cartSlice";
import {
  changeTypeImage,
  getProviderAsync,
  setAllProvider,
  setArrAllProviders,
  setArrProviders,
  setProviderId,
} from "./store/reducers/mapTaskingSlice";
import { antdTheme } from "./themes/antdTheme";
import { ToastContainer, toast } from "react-toastify";

axios.defaults.withCredentials = true;

function App() {
  //! state
  const dispatch = useDispatch();
  // const { data: dataGlobalConfig, isLoading: loadingGlobalConfig } =
  //   useGetGlobalConfig();
  //! function

  const getCart = async () => {
    // @ts-ignore
    const res = await dispatch(getImgsAsync({})).unwrap();

    if (res?.status === ESttausCode.OK) {
      let images = res?.data?.data?.images ?? [];

      let arr: any = [];

      images?.forEach((itemM: any) => {
        const updatedFeatures = itemM?.images?.map((feature: any) => ({
          ...feature,
          provider_id: itemM.provider_id,
          provider_name: itemM.provider_name,
          color_provider_name: itemM?.color_provider_name ?? "",
        }));

        arr = arr.concat(updatedFeatures);
      });

      dispatch(
        setArrIdImage(
          arr.map(
            (itemM: any) =>
              itemM?.feature?.properties[
                `${itemM.provider_name.toLowerCase()}:id`
              ]
          )
        )
      );
    }
  };

  const getMetaKeyAndProvider = async () => {
    // @ts-ignore
    let res = await dispatch(getMetakeyAsync({ page: 0, size: 1000 })).unwrap();
    dispatch(setMetaKey(res?.data?.data?.content_page ?? []));

    let typeImage = res?.data?.data?.content_page?.filter(
      (itemF: any) => itemF?.key === "IMAGE_TYPE_SEARCH"
    )?.[0]?.value;

    dispatch(changeTypeImage(typeImage));

    dispatch(
      setDataStatusOrder(
        res?.data?.data?.content_page?.filter(
          (itemF: any) => itemF?.key === "ORDER_STATUS"
        )
      )
    );

    // @ts-ignore
    let resProvider = await dispatch(getProviderAsync()).unwrap();

    dispatch(setAllProvider(resProvider?.data?.data?.content_page ?? []));

    dispatch(
      setArrAllProviders(
        resProvider?.data?.data?.content_page?.filter(
          (item: any) => item?.actived
        )
      )
    );

    let providers = resProvider?.data?.data?.content_page
      ?.filter((item: any) => item?.actived && item?.enabled_archive)
      ?.filter((itemF: any) =>
        itemF?.metadatas?.some(
          (itemMF: any) =>
            itemMF?.name === "IMAGE_TYPE_SEARCH" && itemMF?.value === typeImage
        )
      );

    dispatch(setArrProviders(providers ?? []));
    dispatch(setProviderId(providers?.[0]?.id)); /////////
  };

  useEffect(() => {
    loadStaticConfigs({ dispatch: dispatch });
    getCart();
    getMetaKeyAndProvider();
  }, []);

  return (
    <ConfigProvider theme={antdTheme}>
      <BrowserRouter>
        <NotificationProvider />
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          theme="light"
          pauseOnHover={false}
        />
        <PageLayout>
          <RouterPage></RouterPage>
        </PageLayout>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
