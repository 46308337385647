import React from "react";
import CardManagementComp from "../Commons/CardManagement";
import { useNavigate } from "react-router-dom";
import CommonComponents from "../Commons";
import EditSvg from '../../assets/svg/edit.svg'

const ListSchemaComp = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      {props?.data?.map((item: any, ind: any) => (
        <CardManagementComp
          key={ind}
          data={{
            title: item?.name,
            des: item?.type,
          }}
          actions={[
            <CommonComponents.TooltipUI title="Update">
              <img
                alt="EditSvg"
                src={EditSvg}
                onClick={() => {
                  navigate(`/provider-branch/2/list-schema/${item?.id}/edit`, {
                    state: {
                      item: item,
                    },
                  });
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
          onClick={() => {
            navigate(`/provider-branch/2/list-schema/${item?.id}/edit`, {
              state: {
                item: item,
              },
            });
          }}
        />
      ))}
    </>
  );
};

export default ListSchemaComp;
