import { Button, Form, Input, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonComponents from "../../../components/Commons";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { baseRoutes } from "../../../router/RouterPage";
import providerConfigService from "../../../services/providerConfigs/providerConfigService";
import { changeDataBreadcrumb } from "../../../store/reducers/layoutSlice";
import { typeToken } from "../[id]";
import useFormValidation from "../../../hooks/useFormValidation";
import { HexColorPicker } from "react-colorful";
import RefreshSvg from "../../../assets/svg/refresh.svg";
import {
  setArrAllProviders,
  setArrProviders,
  setProviderId,
} from "../../../store/reducers/mapTaskingSlice";
import { RootState } from "../../../store/store";
import defaultImage from "../../../assets/images/default.png";
import { showError, showSuccess } from "../../../helpers/toast";

interface IInitialValues {
  name: string;
  description: string;
  actived?: boolean;
  type_token: string;
}
const CreateProvider = () => {
  //! state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { provider } = useFormValidation();
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = useState<string>();
  let typeImage = useSelector((state: RootState) => state.mapTasking.typeImage);
  const initialValues: IInitialValues = {
    name: "",
    description: "",
    type_token: "",
  };
  //! function
  const checkFileSize = (file: File) => {
    const isLt2M = file?.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isLt2M;
  };

  const uploadFile = async (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    checkFileSize(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result as any);
    };
  };
  const handleCancel = () => {
    navigate(baseRoutes.providerBranch);
  };

  const handleSubmit = async () => {
    let values: IInitialValues = form.getFieldsValue();

    try {
      setLoading(true);
      const body = {
        name: values.name,
        description: values.description,
        type_token: values.type_token,
        metadatas: [
          {
            key: "LOGO",
            value: imageUrl,
            name: "string",
            label: "string",
            type: "string",
            description: "string",
          },
          {
            key: "COLOR_NAME",
            value: color ?? "",
            name: "COLOR_NAME",
            label: "COLOR_NAME",
            type: "string",
            description: "COLOR_NAME",
          },
        ],
      };

      const result = await providerConfigService.postProvider(body);
      if (
        result.status === ESttausCode.OK ||
        result.status === ESttausCode.CREATED
      ) {
        showSuccess("Add new provider successfully");

        // @ts-ignore
        let resProvider = await dispatch(getProviderAsync()).unwrap();

        dispatch(
          setArrAllProviders(
            resProvider?.data?.data?.content_page?.filter(
              (item: any) => item?.actived
            )
          )
        );

        let providers = resProvider?.data?.data?.content_page
          ?.filter((item: any) => item?.actived)
          ?.filter((itemF: any) =>
            itemF?.metadatas?.some(
              (itemMF: any) =>
                itemMF?.name === "IMAGE_TYPE_SEARCH" &&
                itemMF?.value === typeImage
            )
          );

        dispatch(setArrProviders(providers ?? []));

        dispatch(setProviderId(providers?.[0]?.id));
        navigate(baseRoutes.providerBranch);
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      setLoading(false);
    }
  };
  //! effect
  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { title: "Providers", path: baseRoutes.providerBranch },
        { title: "Create" },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  //! render
  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2">
          <div className="flex justify-end gap-2">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel "
              onClick={handleCancel}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              htmlType="submit"
              className="h-[28px]"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              Save
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <Form
        initialValues={initialValues}
        form={form}
        className="div-form-small"
      >
        <CommonComponents.FormFieldUI
          name="name"
          rules={provider.name()}
          component={
            <CommonComponents.InputUI
              required
              label="Provider name"
              placeholder="Provider name"
            />
          }
        />

        <CommonComponents.FormFieldUI
          name="description"
          component={
            <CommonComponents.TextAreaUI
              label="Description"
              placeholder="Description"
            />
          }
        />

        <CommonComponents.FormFieldUI
          name="type_token"
          component={
            <CommonComponents.SelectUI
              options={typeToken}
              label="Type Token"
              placeholder="Select type token"
            />
          }
        />
        <div className="flex w-full gap-8">
          <div className="w-1/5 border border-gray-700 rounded-md flex flex-col justify-center items-center">
            <img
              src={imageUrl ?? defaultImage}
              alt="change avatar"
              className="size-[100px] object-contain"
            />

            <div className="w-[100px] ">
              <label
                htmlFor="file-upload"
                className="custom-file-upload div-btn-upload !flex !flex-col !items-center !justify-center !text-center"
              >
                Upload
              </label>
              <input
                id="file-upload"
                type="file"
                name="file"
                onChange={uploadFile}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>

          <div className="w-4/5">
            <CommonComponents.FormFieldUI
              noStyle
              name="primary_color"
              component={
                <>
                  <HexColorPicker
                    className="!w-full"
                    color={color}
                    onChange={(val: any) => {
                      setColor(val);
                      form.setFieldValue("primary_color", val);
                    }}
                  />
                  <CommonComponents.InputUI
                    className="mt-2"
                    value={color}
                    suffix={<img src={RefreshSvg} />}
                  />
                </>
              }
            />
          </div>
        </div>

        {/* <CommonComponents.FormFieldUI
          name="actived"
          label="Active"
          valuePropName="checked"
          component={<Switch />}
        /> */}

        {/* <CommonComponents.FormAntdDebugs /> */}
      </Form>
    </ContentLayoutManagement>
  );
};

export default CreateProvider;
