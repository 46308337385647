import { Editor } from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonComponents from "../../components/Commons";
import LoadingUI from "../../components/Commons/Loading";
import { ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { showError, showSuccess } from "../../helpers/toast";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { baseRoutes } from "../../router/RouterPage";
import basemapService from "../../services/basemap/basemapService";
import useGetBasemap from "../../services/basemap/hooks/useGetBasemap";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";

const SettingDataBaseMap = () => {
  //! state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  const { data: dataBaseMap, isLoading } = useGetBasemap();
  const [loadingSave, setLoadingSave] = useState(false);
  //! function
  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
    // editor.getAction("editor.action.formatDocument").run();
  }

  const handleSave = async () => {
    if (editorRef.current) {
      const body = editorRef?.current.getValue();

      try {
        JSON.parse(body);
      } catch (e) {
        showError("Format invalid JSON");

        return;
      }

      try {
        setLoadingSave(true);
        const results = await basemapService.PostBasemap({
          configs: JSON.parse(body),
        });
        if (results.status == ESttausCode.OK || ESttausCode.CREATED) {
          showSuccess("BaseMap update successfully");
        }
      } catch (error) {
        showError(getErrorMsg(error));
      } finally {
        setLoadingSave(false);
      }
    }
  };
  const handleCancel = () => {
    navigate(baseRoutes.basemapSetting);
  };
  //! effect

  // useEffect(() => {
  //   if (dataBaseMap?.data?.configs) {
  //     setTimeout(() => {
  //       editorRef?.current.getAction("editor.action.formatDocument").run();
  //     }, 300);
  //   }
  // }, [isLoading]);
  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        { title: "Settings", path: baseRoutes.basemapSetting },
        { title: "BaseMap" },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  //! render
  const renderBodyUI = () => {
    if (isLoading) {
      return <LoadingUI />;
    }
    return (
      <Editor
        height="80vh"
        defaultLanguage="json"
        value={JSON.stringify(dataBaseMap?.data?.configs, null, 2)}
        // defaultValue={JSON.stringify(dataBaseMap?.data?.configs)}
        onMount={handleEditorDidMount}
      />
    );
  };
  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2">
          <div className="flex justify-end gap-2">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel "
              onClick={handleCancel}
              disabled={loadingSave}
              loading={loadingSave}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              htmlType="submit"
              className="h-[28px]"
              disabled={loadingSave}
              loading={loadingSave}
              onClick={handleSave}
            >
              Save
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        {renderBodyUI()}
      </div>
    </ContentLayoutManagement>
  );
};

export default SettingDataBaseMap;
