import React from "react";

export default function PolygonDrawIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99987 1.82715L1.79297 6.60591L4.50849 14.1723H11.4912L14.2068 6.60591L7.99987 1.82715Z"
        stroke="white"
      />
      <path
        d="M9.46148 2.59473C9.46148 3.32073 8.95882 3.92673 8.29251 4.06473C8.19899 4.08273 8.09963 4.09473 8.00027 4.09473C7.19368 4.09473 6.53906 3.42273 6.53906 2.59473C6.53906 2.49273 6.55075 2.39073 6.56829 2.29473C6.70272 1.61073 7.29305 1.09473 8.00027 1.09473C8.80686 1.09473 9.46148 1.76673 9.46148 2.59473Z"
        fill="#272D3C"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5005 6.84473C15.5005 7.57073 14.9979 8.17673 14.3316 8.31473C14.2381 8.33273 14.1387 8.34473 14.0393 8.34473C13.2327 8.34473 12.5781 7.67273 12.5781 6.84473C12.5781 6.74273 12.5898 6.64073 12.6073 6.54473C12.7418 5.86073 13.3321 5.34473 14.0393 5.34473C14.8459 5.34473 15.5005 6.01673 15.5005 6.84473Z"
        fill="#272D3C"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.42241 6.84473C3.42241 7.57073 2.91976 8.17673 2.25345 8.31473C2.15993 8.33273 2.06057 8.34473 1.96121 8.34473C1.15462 8.34473 0.5 7.67273 0.5 6.84473C0.5 6.74273 0.511689 6.64073 0.529224 6.54473C0.663655 5.86073 1.25398 5.34473 1.96121 5.34473C2.76779 5.34473 3.42241 6.01673 3.42241 6.84473Z"
        fill="#272D3C"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.87945 13.4751C5.87945 14.2011 5.37679 14.8071 4.71048 14.9451C4.61696 14.9631 4.5176 14.9751 4.41824 14.9751C3.61165 14.9751 2.95703 14.3031 2.95703 13.4751C2.95703 13.3731 2.96872 13.2711 2.98625 13.1751C3.12069 12.4911 3.71101 11.9751 4.41824 11.9751C5.22482 11.9751 5.87945 12.6471 5.87945 13.4751Z"
        fill="#272D3C"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0396 13.4751C13.0396 14.2011 12.5369 14.8071 11.8706 14.9451C11.7771 14.9631 11.6778 14.9751 11.5784 14.9751C10.7718 14.9751 10.1172 14.3031 10.1172 13.4751C10.1172 13.3731 10.1289 13.2711 10.1464 13.1751C10.2808 12.4911 10.8712 11.9751 11.5784 11.9751C12.385 11.9751 13.0396 12.6471 13.0396 13.4751Z"
        fill="#272D3C"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
