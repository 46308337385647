import { Tooltip } from "antd";

const ButtonIconComp = (props: any) => {
  return (
    <Tooltip title={props?.item?.description} arrow={false}>
      <div
        className={
          "btn-action-item " +
          (props?.className ? props?.className : "") +
          (props?.item?.title ? " div-one-center" : "") +
          (props?.disabled ? " btn-disabled" : "") +
          (props?.item?.active ? " !bg-[#4CC2FF]" : "")
        }
        onClick={() => {
          if (props?.item?.onClick && !props?.disabled) {
            props?.item?.onClick();
            return;
          } else if (props?.onClick && !props?.disabled) {
            props?.onClick();
          }
        }}
      >
        <span className="flex gap-1">
          {props?.item?.icon && (
            <img src={props?.item?.icon} className={props?.classNameImage} />
          )}
          {props?.item?.title && (
            <span style={{ color: "white", fontSize: "12px" }}>
              {props?.item?.title}
            </span>
          )}
        </span>
      </div>
    </Tooltip>
  );
};

export default ButtonIconComp;
