import { Collapse, Divider, Form, Space, Spin } from "antd";
import axios from "axios";
import { CollapseProps } from "rc-collapse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CommonIcons from "../../../assets/icons";
import ApplySvg from "../../../assets/svg/apply-black.svg";
import RejectSvg from "../../../assets/svg/dismiss-black.svg";
import EditSvg from "../../../assets/svg/edit-black.svg";
import SendSvg from "../../../assets/svg/send-black.svg";
import CommonComponents from "../../../components/Commons";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import PolygonInfo from "../../../components/MapTasking/PolygonInfo";
import { ERoleAccessControl, ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import { showError, showSuccess } from "../../../helpers/toast";
import useToggle from "../../../hooks/useToggle";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import orderService from "../../../services/order/order.service";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  clearFeatures,
  setArrFeatures,
} from "../../../store/reducers/mapTaskingSlice";
import {
  getDetailOrderTaskingByAdminAsync,
  getDetailOrderTaskingByUerAsync,
  sendQuotationToUserAsync,
  submitOrderAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { BuyerInfomationTaskingOrderAdmin } from "./[UpdateTaskingOrderAdmin]";
import { RenderHeader, renderInfoOrder } from "./ultis";
import { checkImageUploadS3 } from "../ArchiveOrder/ultis";

const optionsUnit = [
  {
    value: "VND",
    label: "VND",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
];

const InfoTaskingOrderUserPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    open: openConfirmReject,
    shouldRender: shouldRenderConfirmReject,
    toggle: toggleConfirmReject,
  } = useToggle();

  const {
    open: openConfirmAccept,
    shouldRender: shouldRenderUpdateConfirmAccept,
    toggle: toggleUpdateConfirmAccept,
  } = useToggle();
  const {
    open: openReason,
    toggle: toggleReason,
    shouldRender: shouldRenderReason,
  } = useToggle();

  const {
    open: openConfirmSubmit,
    shouldRender: shouldRenderUpdateConfirmSubmit,
    toggle: toggleUpdateConfirmSubmit,
  } = useToggle();

  const {
    open: openSendQuotationToUser,
    toggle: toggleSendQuotationToUser,
    shouldRender: shouldRenderSendQuotationToUser,
  } = useToggle();

  let publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );
  const dataStatusOrder = useSelector(
    (state: RootState) => state.appCommonSlice.dataStatusOrder
  );

  let groups = useSelector((state: RootState) => state.appCommonSlice.groups);
  const roleAdmin = CheckRoleAccessControlAdmin();

  const [data, setData] = useState<any>({});

  const [itemsPolygonPoint, setItemsPolygonPoint] = useState<any>([]);
  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});
  const [idReject, setIdReject] = useState<number | null>(null);
  const [reason, setReason] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const itemsInfoUser: CollapseProps["items"] = [
    {
      key: "1",
      label: "Buyer's information",
      children: (
        <BuyerInfomationTaskingOrderAdmin
          whoami={dataUser}
          buyerInfo={data?.buyer}
        />
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    const flagAdmin = roleAdmin?.includes(ERoleAccessControl.admin)
      ? true
      : false;

    let res = !flagAdmin
      ? // @ts-ignore
        await dispatch(getDetailOrderTaskingByUerAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getDetailOrderTaskingByAdminAsync(id)).unwrap();

    if (flagAdmin && res?.data?.data?.user_id !== "anonymousUser") {
      axios
        .get(
          `${publicConfig?.REACT_APP_API_KARATOS}/oa/admin/identities/${res?.data?.data?.user_id}`,
          {
            withCredentials: true,
          }
        )
        .then((data) => {
          setDataUser(data.data);
        });
    }

    setItemsInfoOder([
      {
        key: "1",
        label: "Order's Information",
        children: renderInfoOrder(res?.data?.data, roleAdmin, "tasking"),
      },
    ]);

    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="!cursor-pointer"
              onClick={() => navigate("/order/management-tasking")}
            >
              Tasking Orders
            </span>
          ),
        },
        { title: res?.data?.data?.name },
      ])
    );

    let selectedPolygon = {
      id: uuidv4(),
      type: "Feature",
      geometry:
        res?.data?.data?.order_details?.[0]?.request_order?.geometry ??
        res?.data?.data?.order_details?.[0]?.request_order?.aoi?.[0]?.geometry,
      properties:
        res?.data?.data?.order_details?.[0]?.request_order?.properties ?? {},
      name: "Polygon 01",
    };

    setItemsPolygonPoint([
      {
        key: "1",
        label: "Polygon points",
        children: <PolygonInfo selectedPolygon={selectedPolygon} />,
      },
    ]);

    setData(res?.data?.data ?? []);

    if (
      res?.data?.data?.order_details?.[0]?.orderMetadata &&
      res?.data?.data?.order_details?.[0]?.orderMetadata?.length > 0
    ) {
      let newArrPolygonImages =
        res?.data?.data?.order_details?.[0]?.orderMetadata?.map(
          (itemM: any, index: any) => ({
            ...itemM,
            id: index,
            name: `Polygon ${index}`,
          })
        );

      dispatch(setArrFeatures(newArrPolygonImages));
    }

    if (
      roleAdmin?.includes(ERoleAccessControl.admin) &&
      res?.data?.data?.order_details?.[0]?.is_submited &&
      res?.data?.data?.status?.name === "ORDER_REVIEWED"
    ) {
      if (res?.data?.data?.order_details?.length > 0) {
        const initialValues: any = {};
        res?.data?.data?.order_details?.forEach((item: any) => {
          if (item?.actual_price) {
            initialValues[item?.id] = {
              price: item?.actual_price?.total_price ?? 0,
              unit: item?.actual_price?.unit ?? "",
            };
          }
        });

        // Đặt giá trị khởi tạo cho form
        form.setFieldsValue(initialValues);
      }
    }

    setLoading(false);
  };
  const handleConfirmReject = async () => {
    try {
      let result;
      if (roleAdmin?.includes(ERoleAccessControl.admin)) {
        result = await orderService.ChangeStatusOrderByAdmin(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_REJECTED"
          )?.[0]?.id,
          {
            note: reason,
          }
        );
      } else {
        result = await orderService.ChangeStatusOrder(
          Number(idReject),
          dataStatusOrder?.filter(
            (itemF: any) => itemF?.name === "ORDER_CANCELED"
          )?.[0]?.id,
          {
            note: reason,
          }
        );
      }
      if (result.status === ESttausCode.OK) {
        toggleReason();
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleConfirmReject();
      setIdReject(null);
    }
  };
  const handleAcceptOrder = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(idReject),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_REVIEWED"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleUpdateConfirmAccept();
      setIdReject(null);
    }
  };

  const handleSubmitOrder = async () => {
    try {
      setLoading(true);
      if (
        data?.order_details?.[0]?.provider?.name?.toLowerCase() === "capella"
      ) {
        // @ts-ignore
        let res = await dispatch(
          // @ts-ignore
          submitOrderAsync({
            order_detail_id: data?.order_details?.[0]?.id,
          })
        ).unwrap();

        if (res.status === ESttausCode.OK) {
          showSuccess("Successfully");

          navigate(`/order/management-tasking/${id}/submit`, {
            state: {
              data: data,
            },
          });
        } else {
          showError(getErrorMsg(res?.message ?? "Error"));
        }
      } else if (
        data?.order_details?.[0]?.provider?.name?.toLowerCase() === "oneatlas"
      ) {
        navigate(`/order/management-tasking/${id}/submit`, {
          state: {
            data: data,
          },
        });
      }
    } catch (error: any) {
      showError(getErrorMsg(error));

      setLoading(false);
    } finally {
      toggleUpdateConfirmSubmit();
      setLoading(false);
    }
  };

  const {
    open: openConfirmPrice,
    toggle: toggleConfirmPrice,
    shouldRender: shouldConfirmPrice,
  } = useToggle();

  const formatValues = (values: Record<string, any>) => {
    return Object.entries(values)
      .filter(([key, _]) => key !== "oneatlas") // Bỏ qua trường "oneatlas"
      .map(([key, value]) => ({
        order_detail_id: parseInt(key, 10),
        actual_total_price: parseFloat(value.price || 0),
        actual_unit: value.unit || "",
      }));
  };

  const handleSendQuotationToUser = async () => {
    // admin confirm reviewed
    try {
      // @ts-ignore
      let res = await dispatch(
        // @ts-ignore
        sendQuotationToUserAsync({
          orderId: id,
          data: formatValues(form.getFieldsValue()),
        })
      ).unwrap();
      if (res.status === ESttausCode.OK) {
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleSendQuotationToUser();
      setIdReject(null);
    }
  };

  useEffect(() => {
    getData();
  }, [id, groups]);

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-tasking/:id"));

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(clearFeatures());
    };
  }, []);

  const handleConfirmPrice = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_CONFIRMED_QUOTATION"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleConfirmPrice();
      setIdReject(null);
    }
  };

  const {
    open: openCloseOrder,
    toggle: toggleCloseOrder,
    shouldRender: shouldCloseOrder,
  } = useToggle();

  const handleCloseOrder = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_CLOSED"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleCloseOrder();
      setIdReject(null);
    }
  };

  const {
    open: openSendOrderUser,
    toggle: toggleSendOrderUser,
    shouldRender: shouldSendOrderUser,
  } = useToggle();

  const handleSendOrderUser = async () => {
    // admin confirm reviewed
    try {
      const result = await orderService.ChangeStatusOrderByAdmin(
        Number(id),
        dataStatusOrder?.filter(
          (itemF: any) => itemF?.name === "ORDER_REVIEWING"
        )?.[0]?.id,
        {
          note: "",
        }
      );
      if (result.status === ESttausCode.OK) {
        showSuccess("Successfully");
        getData();
      }
    } catch (error) {
      showError(getErrorMsg(error));
    } finally {
      toggleSendOrderUser();
      setIdReject(null);
    }
  };

  console.log(checkImageUploadS3(data), "checkImageUploadS3(data)");

  return (
    <Form form={form}>
      <ContentLayoutManagement
        header={
          <div className="flex gap-2 justify-end mr-6">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] "
              onClick={() => navigate("/order/management-tasking")}
            >
              Cancel
            </CommonComponents.ButtonUI>
            {roleAdmin?.includes(ERoleAccessControl.user) &&
              data?.status?.name === "ORDER_CREATED" && (
                <>
                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px] !bg-[#FF6161] btn-save hover:!bg-[#FF6161]"
                    icon={<img src={RejectSvg} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdReject(Number(id));
                      // toggleConfirmReject();
                      toggleReason();
                    }}
                  >
                    Cancel Order
                  </CommonComponents.ButtonUI>

                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px]  hover:!bg-common-primary500 btn-save"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/order/management-tasking/${id}/update`, {
                        state: {
                          item: data,
                        },
                      });
                    }}
                    icon={<img src={EditSvg} />}
                  >
                    Edit
                  </CommonComponents.ButtonUI>

                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px]  hover:!bg-common-primary500 btn-save"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSendOrderUser();
                    }}
                    icon={<img src={SendSvg} />}
                  >
                    Send order
                  </CommonComponents.ButtonUI>
                </>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              data?.status?.name === "ORDER_REVIEWING" && (
                <>
                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px] !bg-[#FF6161] btn-save hover:!bg-[#FF6161]"
                    icon={<img src={RejectSvg} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdReject(Number(id));
                      // toggleConfirmReject();
                      toggleReason();
                    }}
                  >
                    Reject
                  </CommonComponents.ButtonUI>

                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px]  hover:!bg-common-primary500 btn-save"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/order/management-tasking/${id}/update`, {
                        state: {
                          item: data,
                        },
                      });
                    }}
                    icon={<img src={EditSvg} />}
                  >
                    Edit
                  </CommonComponents.ButtonUI>

                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px]  hover:!bg-common-primary500 btn-save"
                    icon={<img src={ApplySvg} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdReject(Number(id));
                      toggleUpdateConfirmAccept();
                    }}
                  >
                    Confirm review
                  </CommonComponents.ButtonUI>
                </>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              (data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                "capella" ||
                data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                  "oneatlas") &&
              data?.status?.name === "ORDER_REVIEWED" &&
              !data.order_details[0].is_submited && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
                  onClick={async () => {
                    toggleUpdateConfirmSubmit();
                  }}
                  icon={<img src={SendSvg} />}
                  iconPosition="end"
                >
                  Request quotation
                </CommonComponents.ButtonUI>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              (data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                "capella" ||
                data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                  "oneatlas") &&
              data?.order_details?.[0]?.is_submited &&
              data?.status?.name === "ORDER_REVIEWED" &&
              data?.order_details?.[0]?.is_submited && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-[27px] btn-save"
                  // onClick={async () => {
                  //   navigate(`/order/management-tasking/${id}/submit`, {
                  //     state: {
                  //       data: data,
                  //     },
                  //   });
                  // }}

                  onClick={() => {
                    toggleSendQuotationToUser();
                  }}
                  icon={<img src={SendSvg} />}
                  iconPosition="end"
                >
                  Send quotation to user
                </CommonComponents.ButtonUI>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              (data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                "capella" ||
                data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                  "oneatlas") &&
              data?.order_details?.[0]?.is_sent &&
              !data?.order_details?.[0]?.is_image_download_mode_auto && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-[27px] btn-save"
                  onClick={async () => {
                    navigate(`/order/${id}/upload-data`, {
                      state: {
                        data: data,
                      },
                    });
                  }}
                  // icon={<img src={SendSvg} />}
                  // iconPosition="end"
                >
                  Upload data
                </CommonComponents.ButtonUI>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              data?.order_details?.[0]?.provider?.name?.toLowerCase() ===
                "oneatlas" &&
              data?.order_details?.[0]?.is_submited &&
              data?.status?.name === "ORDER_PROVIDER_STATUS_IN_PROGRESS" && (
                <CommonComponents.ButtonUI
                  // commonType="standard"
                  className="!text-xs !h-[27px]"
                  onClick={async () => {
                    navigate(`/order/${id}/upload-data`, {
                      state: {
                        data: data,
                      },
                    });
                  }}
                  // icon={<img src={SendSvg} />}
                  // iconPosition="end"
                >
                  Upload data
                </CommonComponents.ButtonUI>
              )}

            {roleAdmin?.includes(ERoleAccessControl.user) &&
              data?.status?.name === "ORDER_WAIT_FOR_CONFIRMING_QUOTATION" && (
                <>
                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px] !bg-[#FF6161] btn-save hover:!bg-[#FF6161]"
                    icon={<img src={RejectSvg} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdReject(Number(id));
                      // toggleConfirmReject();
                      toggleReason();
                    }}
                  >
                    Cancel quotation
                  </CommonComponents.ButtonUI>
                  <CommonComponents.ButtonUI
                    className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdReject(Number(id));
                      // toggleConfirmReject();
                      toggleConfirmPrice();
                    }}
                    icon={<img src={ApplySvg} />}
                  >
                    Confirm quotation
                  </CommonComponents.ButtonUI>
                </>
              )}

            {data?.status?.name === "ORDER_CONFIRMED_QUOTATION" &&
              roleAdmin?.includes(ERoleAccessControl.admin) &&
              data?.order_details?.[0]?.is_submited &&
              !data?.order_details?.[0]?.is_sent && (
                <CommonComponents.ButtonUI
                  className="!text-xs !h-7 hover:!bg-common-primary500 bg-common-primary500 !text-black btn-save"
                  onClick={async () => {
                    navigate(`/order/management-tasking/${id}/submit`, {
                      state: {
                        data: data,
                      },
                    });
                  }}
                  icon={<img src={SendSvg} />}
                >
                  Send order to provider
                </CommonComponents.ButtonUI>
              )}

            {roleAdmin?.includes(ERoleAccessControl.admin) &&
              data?.status?.name === "ORDER_PROCESSING" &&
              !checkImageUploadS3(data) && (
                <>
                  <CommonComponents.ButtonUI
                    className="!text-xs !h-[27px]  hover:!bg-common-primary500 btn-save"
                    icon={<img src={ApplySvg} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setIdReject(Number(id));
                      toggleCloseOrder();
                    }}
                  >
                    Close order
                  </CommonComponents.ButtonUI>
                </>
              )}
          </div>
        }
      >
        <Spin spinning={loading}>
          <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
            <div
              className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2"
              style={{ height: "calc(100vh - 56px - 56px - 60px)" }}
            >
              {RenderHeader({
                data: data,
                roleAdmin: roleAdmin?.includes(ERoleAccessControl.admin),
              })}

              {roleAdmin?.includes(ERoleAccessControl.admin) && (
                <div className="div-collapse-pol-point-cus mt-6 ">
                  <Collapse
                    bordered={false}
                    expandIconPosition="end"
                    items={itemsInfoUser}
                    defaultActiveKey={["1"]}
                    onChange={() => {}}
                    className="!p-0"
                    expandIcon={({ isActive }) => (
                      <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                        <CommonIcons.ChevronUpIcon />
                      </div>
                    )}
                  />
                </div>
              )}
              {roleAdmin?.includes(ERoleAccessControl.admin) &&
                data?.order_details?.[0]?.is_submited && (
                  <div className="mt-8 mb-8">
                    <b className="mb-4 text-white" style={{ fontSize: "16px" }}>
                      Order summary
                    </b>
                    <div
                      style={{ background: "rgba(44, 50, 67, 1)" }}
                      className="p-4 gap-2 flex flex-col mt-4 rounded-sm"
                    >
                      <div className="flex flex-row justify-between items-center p-2">
                        <span className="text-gray-500">Status order:</span>
                        <span
                          style={{
                            color:
                              data?.order_details?.[0]?.status?.description,
                          }}
                        >
                          {data?.order_details?.[0]?.status?.value}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between items-center p-2">
                        <span className="text-gray-500">Provider's price</span>
                        <span style={{ color: "#00D1FF" }}>
                          {data?.order_details?.[0]?.order_cost?.total_price}{" "}
                          {data?.order_details?.[0]?.order_cost?.unit}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between items-center p-2">
                        <span className="text-gray-500">Vega's price</span>
                        <span>
                          <CommonComponents.FormFieldUI
                            noStyle
                            name={[data?.order_details?.[0]?.id, "price"]}
                            component={
                              <Space.Compact className="items-center">
                                <CommonComponents.InputUI
                                  defaultValue={
                                    data?.order_details?.[0]?.actual_price
                                      ?.total_price
                                  }
                                  placeholder={`Enter Vega's price`}
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input here!",
                                    },
                                  ]}
                                  className="w-[200px] !h-8 !mb-0 !bg-[#3E4353] !border-b-0"
                                  classNameDiv="!mb-0"
                                  // value={watchPrice}
                                  disabled={
                                    data?.status?.name === "ORDER_REVIEWED"
                                      ? false
                                      : true
                                  }
                                  type="number"
                                />
                                <Divider
                                  type="vertical"
                                  style={{
                                    background: "#575F77",
                                    margin: "10px 0",
                                  }}
                                />
                                <CommonComponents.FormFieldUI
                                  name={[
                                    data?.order_details?.[0]?.id ??
                                      data?.order_details?.[0]?.actual_price,
                                    "unit",
                                  ]}
                                  noStyle
                                  component={
                                    <CommonComponents.SelectUI
                                      data={optionsUnit}
                                      defaultValue={
                                        data?.order_details?.[0]?.actual_price
                                          ?.unit
                                      }
                                      className="!w-[80px] !bg-[#3E4353] !rounded-l-none custom-select-unit"
                                      disabled={
                                        data?.status?.name === "ORDER_REVIEWED"
                                          ? false
                                          : true
                                      }
                                    />
                                  }
                                />
                              </Space.Compact>
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}

              {roleAdmin?.includes(ERoleAccessControl.user) &&
                data?.order_details?.[0]?.actual_price?.total_price && (
                  <div className="mt-8 mb-8">
                    <b className="mb-4 text-white" style={{ fontSize: "16px" }}>
                      Order summary
                    </b>
                    <div
                      style={{ background: "rgba(44, 50, 67, 1)" }}
                      className="p-4 gap-2 flex flex-col mt-4"
                    >
                      <div className="flex flex-row justify-between items-center">
                        <span className="text-gray-500">Total price</span>
                        <span style={{ color: "#00D1FF" }}>
                          {data?.order_details?.[0]?.actual_price?.total_price}{" "}
                          {data?.order_details?.[0]?.actual_price?.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              <div
                className={
                  `div-collapse-pol-point-cus ` +
                  (roleAdmin?.includes(ERoleAccessControl.admin)
                    ? " my-4"
                    : " mt-6 mb-4")
                }
              >
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsInfoOder}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  className="!p-0"
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>

              <div className="div-collapse-pol-point-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsPolygonPoint}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  className="!p-0"
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            </div>
            <div
              className="w-1/2"
              style={{ height: "calc(100vh - 56px - 56px - 60px)" }}
            >
              <MapTaskingPlugin
                showOnConfirm={true}
                activePolygon={
                  data?.order_details?.[0]?.request_order?.geometry ??
                  data?.order_details?.[0]?.request_order?.aoi?.[0]?.geometry ??
                  {}
                }
              />
            </div>
          </div>

          {shouldRenderReason && (
            <CommonComponents.ModalUI
              title={
                roleAdmin?.includes(ERoleAccessControl.user) ?? "Note status"
              }
              open={openReason}
              toggle={toggleReason}
              handleConfirm={() => {
                if (reason?.trim().length > 0) {
                  toggleConfirmReject();
                }
              }}
              disabled={reason?.trim().length === 0}
            >
              <ReasonCancelReject reason={reason} setReasson={setReason} />
            </CommonComponents.ModalUI>
          )}

          {shouldRenderConfirmReject && (
            <CommonComponents.ModalUI
              toggle={toggleConfirmReject}
              title="Confirm"
              open={openConfirmReject}
              handleConfirm={handleConfirmReject}
            >
              <p>
                {roleAdmin?.includes(ERoleAccessControl.user)
                  ? "Are you sure you want to cancel this order?"
                  : "Are you sure you want to reject this order?"}
              </p>
            </CommonComponents.ModalUI>
          )}

          {shouldRenderUpdateConfirmAccept && (
            <CommonComponents.ModalUI
              toggle={toggleUpdateConfirmAccept}
              title="Accept Order"
              open={openConfirmAccept}
              handleConfirm={handleAcceptOrder}
            >
              <p>Are you sure you want to accept this order?</p>
            </CommonComponents.ModalUI>
          )}
        </Spin>

        {shouldRenderUpdateConfirmSubmit && !loading && (
          <CommonComponents.ModalUI
            toggle={toggleUpdateConfirmSubmit}
            title="Submit Order"
            open={openConfirmSubmit}
            handleConfirm={handleSubmitOrder}
          >
            <p>Are you sure you want to submit this order to provider?</p>
          </CommonComponents.ModalUI>
        )}

        {shouldRenderSendQuotationToUser && (
          <CommonComponents.ModalUI
            title={"Send order's quotation to user"}
            open={openSendQuotationToUser}
            toggle={toggleSendQuotationToUser}
            handleConfirm={handleSendQuotationToUser}
          >
            <p>Are you sure you want to send order's quotation to user?</p>
          </CommonComponents.ModalUI>
        )}

        {shouldConfirmPrice && (
          <CommonComponents.ModalUI
            title={"Send order's quotation to user"}
            open={openConfirmPrice}
            toggle={toggleConfirmPrice}
            handleConfirm={handleConfirmPrice}
          >
            <p>Are you sure you want to confirm price's this order?</p>
          </CommonComponents.ModalUI>
        )}

        {shouldCloseOrder && (
          <CommonComponents.ModalUI
            title={"Close order"}
            open={openCloseOrder}
            toggle={toggleCloseOrder}
            handleConfirm={handleCloseOrder}
          >
            <p>Are you sure you want to close this order?</p>
          </CommonComponents.ModalUI>
        )}

        {shouldSendOrderUser && (
          <CommonComponents.ModalUI
            title={"Close order"}
            open={openSendOrderUser}
            toggle={toggleSendOrderUser}
            handleConfirm={handleSendOrderUser}
          >
            <p>Are you sure you want to send this order to admin?</p>
          </CommonComponents.ModalUI>
        )}
      </ContentLayoutManagement>
    </Form>
  );
};

export default InfoTaskingOrderUserPage;

const ReasonCancelReject = ({
  setReasson,
  reason,
}: {
  setReasson: any;
  reason: string;
}) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    return () => {
      setReasson("");
    };
  }, []);
  return (
    <div>
      <CommonComponents.TextAreaUI
        onChange={(event: any) => {
          setReasson(event.target.value);
        }}
        label="Reason"
        placeholder="Enter reason ..."
        onBlur={() => {
          setTouched(true);
        }}
      />
      {touched && reason?.trim()?.length <= 0 && (
        <p className="text-red-500 text-xs mt-1">Reason is required</p>
      )}
    </div>
  );
};
