import { Card } from "antd";
import MyDataSvg from "../../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import CommonComponents from "..";

const { Meta } = Card;

const CardManagementComp = (props: any) => {
  return (
    <Card
      className="card-default "
      cover={
        <CommonComponents.TooltipUI
          title={props.data?.status ? String(props.data?.status?.value) : ""}
        >
          <div className="relative">
            <img
              alt="example"
              src={props.data?.imgSrc ?? MyDataSvg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = props?.data?.errorImage ?? MyDataSvg;
              }}
            />

            {props.data?.status && (
              <div
                className={`absolute -bottom-[1px] right-0 size-3 rounded-full border-2 border-common-ink300`}
                style={{
                  background: props.data?.status?.description,
                }}
              />
            )}
          </div>
        </CommonComponents.TooltipUI>
      }
      actions={props?.actions?.filter((item: any) => item !== false)}
      onClick={props?.onClick ? () => props?.onClick() : () => {}}
    >
      <Meta
        className="card-default-body"
        title={<p className="text-one-line">{props?.data?.title}</p>}
        description={props.data?.des}
      />
      {props?.additionalInfo && (
        <div className="div-additional-info-card">{props?.additionalInfo}</div>
      )}
    </Card>
  );
};

export default CardManagementComp;
