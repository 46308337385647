import "./loading.css";

const LoadingUI = ({ className }: any) => {
  return (
    <div className="flex justify-center items-center h-[80vh]">
      <div className={`loader `}></div>
    </div>
  );
};

export default LoadingUI;
