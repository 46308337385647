import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonComponents from "../../../components/Commons";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { setLoadingGlobal } from "../../../store/reducers/appCommonSlice";
// import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { Form } from "antd";
import { ESttausCode } from "../../../enums";
import { getErrorMsg } from "../../../helpers/common";
import { showError, showSuccess } from "../../../helpers/toast";
import { changeDataBreadcrumb } from "../../../store/reducers/layoutSlice";
import { addBlockCodeByProviderAsync } from "../../../store/reducers/providerSlice";
import { RootState } from "../../../store/store";

const customTheme = {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      token: "comment",
      foreground: "#5d7988",
      fontStyle: "italic",
    },
    { token: "constant", foreground: "#e06c75" },
  ],
  colors: {
    "editor.background": "#21252b",
  },
};

const NewBlockCodePage = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { idProvider } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.allProvider
  );
  const [value, setValue] = useState<any>(undefined);

  const [flag, setFlag] = useState<boolean>(true);

  // useEffect(() => {
  //   // @ts-ignore
  //   monaco.editor.defineTheme("my-custom-theme", customTheme);
  //   monaco.editor.setTheme("my-custom-theme");
  // }, []);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          title: `Providers ${
            arrAllProviders?.filter(
              (itemF: any) => itemF?.id === Number(idProvider)
            )?.[0]?.name
          }`,
          path: "provider-branch",
        },

        {
          title: `List block code`,
          path: `${idProvider}/list-block-code`,
        },
        {
          title: "Add new",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, [arrAllProviders, idProvider]);

  const handleEditorChange = (value: any, event: any) => {
    setValue(value);
    setFlag(false);
  };

  const handleUpdate = async () => {
    try {
      dispatch(setLoadingGlobal(true));

      let pay = {
        ...form.getFieldsValue(),
        provider_id: Number(idProvider),
        code_runtime: btoa(unescape(encodeURIComponent(value))),
      };
      // @ts-ignore
      let res = await dispatch(addBlockCodeByProviderAsync(pay)).unwrap();

      if (res.status === ESttausCode.OK || res.status === ESttausCode.CREATED) {
        showSuccess("Add new block code successfully");

        navigate(`/provider-branch/${Number(idProvider)}/list-block-code`);
      }

      dispatch(setLoadingGlobal(false));
    } catch (error) {
      showError(getErrorMsg(error));

      dispatch(setLoadingGlobal(false));
    }
  };

  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2">
          <div className="flex justify-end gap-2">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel "
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              onClick={handleUpdate}
              style={{ background: "#60CDFF" }}
              className="h-[28px] !bg-[#60CDFF]"
              disabled={flag}
            >
              Save
            </CommonComponents.ButtonUI>
          </div>
        </div>
      }
    >
      <Form
        form={form}
        initialValues={{
          type_runtime: "python",
        }}
      >
        <div className="relative w-full h-full p-6 bg-content-default">
          <div className="flex gap-2 w-full">
            <CommonComponents.FormFieldUI
              className="!w-full"
              name="key"
              rules={[
                {
                  required: true,
                  message: `Key is required`,
                },
              ]}
              component={
                <CommonComponents.InputUI
                  required
                  label="Key"
                  placeholder="Enter key"
                  className="div-dropdown !w-full"
                />
              }
            />

            <CommonComponents.FormFieldUI
              className="!w-full"
              name="type_runtime"
              rules={[
                {
                  required: true,
                  message: `Type code is required`,
                },
              ]}
              component={
                <CommonComponents.SelectUI
                  required
                  label="Type code"
                  placeholder="Select type code"
                  className="div-dropdown !w-full"
                  data={[{ label: "python", value: "python" }]}
                  nameValue="value"
                />
              }
            />
          </div>

          <Editor
            height="80vh"
            defaultLanguage="python"
            value={value}
            onChange={handleEditorChange}
            onMount={() => dispatch(setLoadingGlobal(false))}
            theme="my-custom-theme"
          />
        </div>
      </Form>
    </ContentLayoutManagement>
  );
};

export default NewBlockCodePage;
