import bbox from "@turf/bbox";
import area from "@turf/area";

export function centroid(poly: { coordinates: Array<any> }) {
  var c = [0, 0];
  var ring = poly?.coordinates?.[0];
  for (let i = 0; i < ring?.length - 1; i++) {
    c[0] +=
      (ring[i][0] + ring[i + 1][0]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    c[1] +=
      (ring[i][1] + ring[i + 1][1]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
  }
  var a = areaFunc(poly);
  c[0] /= a * 6;
  c[1] /= a * 6;
  return c;
}

export function areaFunc(poly: { coordinates: Array<any> }) {
  var s = 0.0;
  var ring = poly?.coordinates?.[0];
  for (let i = 0; i < ring?.length - 1; i++) {
    s += ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1];
  }
  return 0.5 * s;
}

// init layer of items collection
export function initItemsCollectionLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId, {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_fill_hover",
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "#fed766", // if selected true,
        "#A300EF33",
      ],
      "fill-opacity": 0.4,
    },
  });

  maplibre.addLayer({
    id: sourceId + "_fill_click",
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        "#00E676",
        "#A300EF33", // if selected true,
      ],
      "fill-opacity": 0.9,
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_outline",
    type: "line",
    source: sourceId,
    layout: {},
    paint: {
      "line-color": "#CC00FF",
      "line-width": 2,
    },
  });
}

// init hover layer of items collection
export function initHoverLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId + "_hover", {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_hover_fill",
    type: "fill",
    source: sourceId + "_hover",
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "#fed766", // if selected true,
        "#00E676",
      ],
      "fill-opacity": 0.4,
    },
  });

  maplibre.addLayer({
    id: sourceId + "_hover_fill",
    type: "fill",
    source: sourceId + "_click",
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        "#fed766", // if selected true,
        "#00E676",
      ],
      "fill-opacity": 0.4,
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_hover_outline",
    type: "line",
    source: sourceId + "_hover",
    layout: {},
    paint: {
      "line-color": "#fed766",
      "line-width": 2,
    },
  });
}

// init cart layer of items collection
export function initCartLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId + "_cart", {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_cart_fill",
    type: "fill",
    source: sourceId + "_cart",
    layout: {},
    paint: {
      "fill-color": "#00E676 ",
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.5,
        0.25,
      ],
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_cart_outline",
    type: "line",
    source: sourceId + "_cart",
    layout: {},
    paint: {
      "line-color": "#00E676 ",
      "line-width": 2,
    },
  });
}

export function initTextlayer(maplibre: maplibregl.Map, sourceId: any) {
  maplibre.addSource("mapbox-gl-draw-text-source", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  // Add a symbol layer
  maplibre.addLayer({
    id: "mapbox-gl-draw-text",
    type: "symbol",
    source: "mapbox-gl-draw-text-source",
    layout: {
      // get the title name from the source's "title" property
      "text-field": ["get", "displayText"],
      "text-offset": [0, -2.5],
      "text-anchor": "top",
      "text-size": 14,
      "text-justify": "center",
    },
    paint: {
      "text-color": "#FFC300",
      "text-halo-color": "black",
      "text-halo-width": 2,
      "text-halo-blur": 1,
    },
  });
}

const calculateBBox = (coordinates: any) => {
  const flatCoords = coordinates.flat(Infinity); // Flatten tất cả các tọa độ
  const lons = flatCoords.filter((_: any, index: any) => index % 2 === 0); // Tách giá trị kinh độ
  const lats = flatCoords.filter((_: any, index: any) => index % 2 !== 0); // Tách giá trị vĩ độ

  return [
    Math.min(...lons), // Min kinh độ
    Math.min(...lats), // Min vĩ độ
    Math.max(...lons), // Max kinh độ
    Math.max(...lats), // Max vĩ độ
  ];
};

export function updateFeaturesText(map: any, features: Array<any>) {
  if (
    map &&
    Object.keys(map).length &&
    map.getSource("mapbox-gl-draw-text-source")
  ) {
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (features && features?.length) {
      let index = 0;
      features.forEach((f) => {
        if (f) {
          index++;
          // const extent = bbox(f);
          const extent = calculateBBox(f?.geometry?.coordinates);
          const outArea = area(f);
          let output;
          if (outArea > 10000) {
            output = Math.round((outArea / 1000000) * 100) / 100 + " " + "km²";
          } else {
            output = Math.round(outArea * 100) / 100 + " " + "m2";
          }

          if (extent && extent.length == 4) {
            //@ts-ignore
            geojson["features"].push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [extent[0], extent[3]],
              },
              properties: {
                displayText: f?.name
                  ? f?.name + " ~" + output
                  : "Polygon " + index + " ~" + output,
              },
            });
          }
        }
      });
    }

    map.getSource("mapbox-gl-draw-text-source").setData(geojson);
  }
}

export function resetDrawMode(map: any, draw: any) {
  map.getCanvas().style.cursor = "";
  if (draw) draw?.changeMode("simple_select");
}

export function changeColor(
  features: Array<any>,
  color: string,
  colorOutLine: string,
  draw: any
) {
  if (features && features?.length > 0) {
    features?.forEach((f) => {
      if (draw && f) {
        // return
        draw?.setFeatureProperty(f?.id, "portColor", color);

        draw?.setFeatureProperty(f?.id, "lineColor", colorOutLine);

        let feat: any = draw.get(f?.id);
        draw?.add(feat);
      }
    });
  }
}

export function changeSourceDataHover(data: any, map: any, sourceId: any) {
  if (map && Object.keys(map).length && map.getSource(sourceId + "_hover")) {
    // @ts-ignore
    map.getSource(sourceId + "_hover").setData(data);
  }
}

export function onMouseEnterItem(item: any, map: any, sourceId: any) {
  let val: any = { type: "FeatureCollection" };
  let properties = item?.properties
    ? { ...item?.properties }
    : item?.metadata
    ? { ...item?.metadata }
    : {};
  properties.id = properties?.id
    ? properties?.id
    : item?.id
    ? item?.id
    : item?.image_id;

  properties.collection = item?.collection ?? "";
  val.features = [
    {
      id: 1,
      type: item?.type ? item?.type : "Feature",
      geometry: item?.geometry ?? item?.geom,
      properties,
    },
  ];

  //@ts-ignore
  changeSourceDataHover(val, map, sourceId);
}
export function onMouseLeaveItem(item: any, map: any, sourceId: any) {
  //@ts-ignore
  changeSourceDataHover(
    {
      type: "FeatureCollection",
      features: [],
    },
    map,
    sourceId
  );
}

export function panToAndExtend(e: any, item: any, map: any) {
  e?.stopPropagation();
  let centerPoint = centroid(item?.geometry);

  map.panTo(centerPoint);
}
