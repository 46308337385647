import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addBlockCodeByProvider, addSchemaByProvider, getBlockCode, getSchemaByProvider, updateBlockCodeByProvider, updateSchemaByProvider } from "../../services/providerServices";

interface IProviderState {}

const initialState: IProviderState = {};


export const getBlockCodeAsync = createAsyncThunk(
  "provider/getBlockCode",
  async (payload) => {
    try {
      const response = await getBlockCode(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const getSchemaAsync = createAsyncThunk(
  "provider/getSchema",
  async (payload) => {
    try {
      const response = await getSchemaByProvider(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);


export const updateBlockCodeByProviderAsync = createAsyncThunk(
  "provider/updateBlockCodeByProvider",
  async (payload) => {
    try {
      const response = await updateBlockCodeByProvider(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);


export const addBlockCodeByProviderAsync = createAsyncThunk(
  "provider/addBlockCodeByProvider",
  async (payload) => {
    try {
      const response = await addBlockCodeByProvider(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const updateSchemaByProviderAsync = createAsyncThunk(
  "provider/updateSchemaByProvider",
  async (payload) => {
    try {
      const response = await updateSchemaByProvider(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const addSchemaByProviderAsync = createAsyncThunk(
  "provider/addSchemaByProvider",
  async (payload) => {
    try {
      const response = await addSchemaByProvider(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {},
});

export const {} = providerSlice.actions;

export default providerSlice.reducer;
