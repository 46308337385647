import React, { useEffect, useState } from "react";
import CommonComponents from "../../Commons";
import { Radio, Slider } from "antd";
import { DefaultFormatDateTime } from "../../../enums";

// properties ui schema object has many attributes {delivery:{
//     "type": "object",
//     "properties": {
//         "type": {
//             "label": "Type",
//             "type": "string",
//             "enum": [
//                 "network"
//             ]
//         }
//     },
//     "required": [
//         "type"
//     ]
// }}

export const convertDynamicData = (data: any): any => {
  if (Array.isArray(data)) {
    // Nếu là mảng, xử lý từng phần tử
    return data.map((item) => convertDynamicData(item));
  } else if (typeof data === "object" && data !== null) {
    const keys = Object.keys(data);

    // Nếu có key duy nhất và key đó trùng với tên của object con, unwrap
    if (keys.length === 1 && typeof data[keys[0]] === "object") {
      const innerKey = keys[0];
      const innerValue = data[innerKey];

      // Nếu giá trị của key duy nhất là một object hoặc mảng, đưa vào mảng
      if (typeof innerValue === "object" && !Array.isArray(innerValue)) {
        return [convertDynamicData(innerValue)];
      }
    }

    // Xử lý từng key-value trong object
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        return [key, convertDynamicData(value)];
      })
    );
  } else {
    // Trả về giá trị cơ bản
    return data;
  }
};

export function convertToNestedFormat(data: any, parentKey?: string): any {
  if (Array.isArray(data)) {
    // Nếu mảng chỉ có một phần tử, chuyển thành đối tượng
    if (data.length === 1) {
      return { [parentKey || "objects"]: convertToNestedFormat(data[0]) };
    }
    // Nếu mảng có nhiều phần tử, giữ nguyên dạng mảng
    return {
      [parentKey || "objects"]: data.map((item) => convertToNestedFormat(item)),
    };
  } else if (typeof data === "object" && data !== null) {
    // Nếu là object, duyệt qua từng thuộc tính và chuyển đổi
    const result: any = {};
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        result[key] = convertToNestedFormat(value, key); // Truyền key cha để xử lý mảng
      } else if (typeof value === "object" && value !== null) {
        result[key] = convertToNestedFormat(value, key);
      } else {
        result[key] = value;
      }
    }
    return result;
  }
  return data; // Giá trị đơn giản (string, number, boolean, v.v.)
}

export function convertToNestedFormatTasking(
  data: any,
  parentKey?: string
): any {
  if (Array.isArray(data)) {
    // Luôn lấy phần tử đầu tiên của mảng
    if (data.length > 0) {
      return convertToNestedFormat(data[0], parentKey);
    }
    return {}; // Trả về đối tượng rỗng nếu mảng không có phần tử
  } else if (typeof data === "object" && data !== null) {
    // Nếu là object, duyệt qua từng thuộc tính và chuyển đổi
    const result: any = {};
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        result[key] = convertToNestedFormat(value, key); // Truyền key cha để xử lý mảng
      } else if (typeof value === "object" && value !== null) {
        result[key] = convertToNestedFormat(value, key);
      } else {
        result[key] = value;
      }
    }
    return result;
  }
  return data; // Giá trị đơn giản (string, number, boolean, v.v.)
}

export function convertNotificationsToObject(data: any): any {
  if (data.notifications && Array.isArray(data.notifications)) {
    // Lấy phần tử đầu tiên của mảng notifications
    const firstNotification = data.notifications[0];
    data.notifications = firstNotification;
  }

  // Duyệt đệ quy qua các thuộc tính con
  for (const key in data) {
    if (typeof data[key] === "object" && data[key] !== null) {
      convertNotificationsToObject(data[key]);
    }
  }

  return data;
}

export const parseKey = (key: string) => {
  if (key.includes("_")) {
    const arrayKey = key.split("_");
    const capitalizedKey = arrayKey
      .map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(" ");
    return capitalizedKey;
  } else {
    const formattedText = key.replace(/([a-z])([A-Z])/g, "$1 $2");
    return (
      formattedText.charAt(0).toUpperCase() +
      formattedText.slice(1).toLowerCase()
    );
  }
};

export const checkTextIsDate = (text: any) => {
  // Try creating a new Date object with the input string
  if (typeof text !== "string") {
    return false;
  }
  const date = new Date(text);

  // Check if the date is valid
  return !isNaN(date.getTime());
};
