import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CommonIcons from "../../assets/icons";
import DeleteSvg from "../../assets/svg/delete.svg";
import InfoSvg from "../../assets/svg/info.svg";
import {
  changeLeftDrawerType,
  changeShowDrawer,
  resetBottomDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeActiveFeature,
  setSelected,
} from "../../store/reducers/mapTaskingSlice";
import { changeShowOrderTasking } from "../../store/reducers/orderSlice";
import { ChangeActionActiveFooter } from "../../store/reducers/statusAction";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";
import { convertGeoJSONToSVG } from "./components/ultis";
import {
  deleteSeclectedPolygon,
  PolygonItem,
  removeAllFeatures,
} from "./ultis";
import ButtonIconComp from "./components/ButtonIcon";
import CloseSvg from "../../assets/svg/dismiss.svg";

const PolygonListTaskingComp = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );
  const showDrawer = useSelector(
    (state: RootState) => state.drawerSlice.showDrawer
  );

  const draw = useSelector((state: RootState) => state.mapTasking.draw);

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  // useSelector((state: RootState) => state.mapTasking.sourceId) + "_tasking";

  const maplibreGL = useSelector(
    (state: RootState) => state.mapTasking.maplibreGL
  );

  const featuresDraw = useSelector(
    (state: RootState) => state.mapTasking.features
  );

  const handleOrderTasking = () => {
    dispatch(changeShowOrderTasking(true));
    navigate("/tasking-order");
  };

  const changeSelectedPolygon = (f: any) => {
    dispatch(changeActiveFeature(f));

    dispatch(setSelected([]));
  };

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.deltaY !== 0) {
      event.currentTarget.scrollLeft += event.deltaY;
      event.preventDefault();
    }
  };

  return (
    <div hidden={props?.hidden} className="w-full h-full p-3">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <ButtonIconComp
            item={{
              name: "Info",
              icon: InfoSvg,
              description: `Information ${selectedPolygon?.name}`,
            }}
            className={
              "!h-7 !w-[30px] !min-w-[30px] !rounded " +
              (leftDrawerType === "Info AOI" ? " !bg-[#004E72]" : "")
            }
            onClick={() => {
              dispatch(changeShowDrawer(!showDrawer));
              dispatch(changeLeftDrawerType(showDrawer ? "" : "Info AOI"));
            }}
            disabled={!selectedPolygon?.id}
          />
          {/* 
          <Tooltip title="Order tasking">
            <CommonComponents.ButtonUI
              icon={<img src={TaskingSvg} />}
              commonType="standard"
              disabled={!selectedPolygon?.id}
              onClick={() => {
                handleOrderTasking();
              }}
            ></CommonComponents.ButtonUI>
          </Tooltip> */}
          <ButtonIconComp
            item={{
              name: "Remove",
              icon: DeleteSvg,
              description: `Remove ${selectedPolygon?.name}`,
            }}
            className={"!h-7 !w-[30px] !min-w-[30px] !rounded "}
            onClick={() =>
              deleteSeclectedPolygon({
                dispatch: dispatch,
                draw: draw,
                activeFeature: selectedPolygon,
                sourceId: sourceId,
                maplibreGL: maplibreGL,
              })
            }
            disabled={!selectedPolygon?.id}
          />

          {/* <CommonComponents.ButtonUI
            icon={<img src={DeleteSvg} />}
            commonType="standard"
            className="!text-xs !h-7"
            onClick={() =>
              deleteSeclectedPolygon({
                dispatch: dispatch,
                draw: draw,
                activeFeature: selectedPolygon,
                sourceId: sourceId,
                maplibreGL: maplibreGL,
              })
            }
            disabled={!selectedPolygon?.id}
          ></CommonComponents.ButtonUI> */}
        </div>

        <div className="flex items-center gap-2">
          <ButtonIconComp
            item={{
              name: "Remove all",
              icon: DeleteSvg,
              title: "Remove all",
            }}
            className={"!h-7 !w-[100px] !min-w-[100px] !rounded "}
            onClick={() =>
              removeAllFeatures({
                dispatch: dispatch,
                draw: draw,
                sourceId: sourceId,
                maplibreGL: maplibreGL,
              })
            }
            disabled={featuresDraw?.length === 0}
          />

          <ButtonIconComp
            item={{
              name: "Close",

              icon: CloseSvg,
              description: "Close",
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            onClick={() => {
              dispatch(ChangeActionActiveFooter("Polygon"));
              dispatch(resetBottomDrawer());
            }}
          />
        </div>
      </div>

      <div className="w-full flex flex-col gap-2 mt-4">
        <div className="flex items-center gap-1 text-sm font-semibold text-common-white">
          <CommonIcons.FolderIcon />
          My Polygon
        </div>

        <div
          className="w-full flex items-center gap-5 overflow-y-hidden pb-3"
          onWheel={handleScroll}
        >
          {featuresDraw?.map((f: any, index: number) => {
            const svgImageUrl = f ? convertGeoJSONToSVG(f, 50, 50) : "";
            return (
              <PolygonItem
                selected={selectedPolygon?.id === f?.id}
                key={index}
                img={svgImageUrl}
                icon={<CommonIcons.RectangleDrawIcon />}
                label={f?.name ?? "Polygon " + (index + 1)}
                onClick={() => changeSelectedPolygon(f)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PolygonListTaskingComp;
