import { capitalizeFirstLetter } from "../../../helpers/common";

export const BuyerInfomation = ({
  whoami,
  valueForm,
}: {
  whoami?: any;
  valueForm?: any;
}) => {
  const data = {
    ...whoami?.identity?.traits,
    ...valueForm?.production,
  };

  const RenderValue = (key: string, value: any) => {
    if (key === "name" && typeof value === "object") {
      return `${value?.last ?? ""} ${value?.first ?? ""}`.trim();
    }
    return value ?? "";
  };

  const RenderInfoBuyer = () => {
    return Object.entries(data).map(([key, value]: any) => (
      <p
        className="flex justify-between"
        key={key}
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des !mr-1">{capitalizeFirstLetter(key)}: </span>
        <span className="text-right">{RenderValue(key, value)}</span>
      </p>
    ));
  };

  return <div className="!text-white">{RenderInfoBuyer()}</div>;
};
export const OrderInfomation = ({ valueForm }: { valueForm?: any }) => {
  const data = {
    ...valueForm?.production,
  };

  const renderOrderInfomation = () => {
    return Object.entries(data).map(([key, value]: any) => (
      <p className="flex justify-between text-sm text-white" key={key}>
        <span className="text-des !mr-1">{capitalizeFirstLetter(key)}: </span>
        <span className="text-right">{value ?? ""}</span>
      </p>
    ));
  };

  return <div className="!text-white">{renderOrderInfomation()}</div>;
};

export const convertToGeoJSON = (data: any) => {
  // Chuyển geometry từ dạng WKT sang GeoJSON
  const parseGeometry = (geometry: any) => {
    if (geometry.startsWith("POLYGON")) {
      const coordinates = geometry
        .match(/\(\((.+)\)\)/)[1] // Lấy phần tọa độ bên trong
        .split(",") // Tách các cặp tọa độ
        .map((point: any) => point.trim().split(" ").map(Number)); // Chuyển thành mảng số [lng, lat]
      return {
        type: "Polygon",
        coordinates: [coordinates], // GeoJSON yêu cầu mảng chứa mảng tọa độ
      };
    }
    throw new Error("Unsupported geometry type");
  };

  // Chuyển center từ dạng WKT sang GeoJSON
  const parseCenter = (center: any) => {
    if (center.startsWith("POINT")) {
      const coordinates = center
        .match(/\((.+)\)/)[1] // Lấy tọa độ bên trong
        .split(" ")
        .map(Number); // Chuyển thành mảng số [lng, lat]
      return {
        type: "Point",
        coordinates, // GeoJSON yêu cầu mảng tọa độ
      };
    }
    throw new Error("Unsupported center type");
  };

  // Xử lý geometry và center
  const geometryGeoJSON = parseGeometry(data.geometry);
  const centerGeoJSON = parseCenter(data.center);

  return { geometryGeoJSON, centerGeoJSON };
};
