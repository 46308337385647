import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ManagementOrderComp from "../../components/ManageOrder/ManagementOrder";
import {
  resetStateFooter,
  setShowFilter,
  setShowPaging,
} from "../../store/reducers/appCommonSlice";
import { resetAllDrawer } from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";

const ManagementOrderPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-order"));
    dispatch(changeDataBreadcrumb([{ title: "Existing Image Orders" }]));

    dispatch(resetAllDrawer());

    dispatch(setShowPaging(true));

    dispatch(setShowFilter(true));

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(resetAllDrawer());
      dispatch(resetStateFooter({}));
    };
  }, []);

  return (
    <div>
      <ManagementOrderComp />
    </div>
  );
};

export default ManagementOrderPage;
