import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import { DefaultFormatDateTime } from "../../../enums";

interface IProps extends DatePickerProps {
  className?: string;
  label?: string;
  required?: boolean;
}

export default function DatePickerUI({
  className,
  format = DefaultFormatDateTime.DATE_FORMAT,
  label,
  required,
  ...res
}: IProps) {
  return (
    <>
      {label && (
        <p className="mb-1 text-white text-sm">
          {label}
          {required && <span className="text-common-red500">{` *`}</span>}
        </p>
      )}
      <DatePicker
        {...res}
        format={format}
        variant="filled"
        className={`border-0 border-b rounded-t-sm rounded-b-sm !shadow-none bg-common-ink400 ${
          className ? " " + className : ""
        } [&_div.ant-picker-input_span.ant-picker-suffix]:text-common-white [&_div.ant-picker-input_span.ant-picker-clear]:text-common-white`}
      ></DatePicker>
    </>
  );
}
