import React from "react";
import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";

const { TextArea } = Input;

interface IProps extends TextAreaProps {
  className?: string;
  label?: string;
  classNameDiv?: string;
  required?: boolean;
}

export default function TextAreaUI({
  className,
  label,
  classNameDiv,
  required,
  ...res
}: IProps) {
  return (
    <div className={`${classNameDiv}`}>
      {label && (
        <p className="mb-1 text-white text-sm">
          {label}
          {required && <span className="text-common-red500">{` *`}</span>}
        </p>
      )}
      <TextArea
        {...res}
        className={`!border-0 !border-b  rounded-[3px] !shadow-none !bg-common-ink400 ${
          className ? className : ""
        }`}
      />
    </div>
  );
}
