import axios from "axios";
import { apis } from "../constants/apis";
import httpService from "./httpService";

export async function getSchemaByProvider(payload: any) {
  return httpService.get(`-flow/api/v1/schema/${payload?.provider_id}`);
}

export async function updateSchemaByProvider(payload: any) {
  return httpService.put(
    `-flow/api/v1/schema/${payload?.schema_id}`,
    JSON.parse(payload?.val)
  );
}

export async function addSchemaByProvider(payload: any) {
  return httpService.post(`-flow/api/v1/schema`, payload?.val);
}

export async function getBlockCode(payload: any) {
  return httpService.get(`/api/v1/processing-code-blocks`, {
    params: payload,
  });
}

export async function updateBlockCodeByProvider(payload: any) {
  return httpService.put(
    `/api/v1/processing-code-blocks/${payload?.id}`,
    payload?.val
  );
}

export async function addBlockCodeByProvider(payload: any) {
  return httpService.post(`/api/v1/processing-code-blocks`, payload);
}
