import { Radio, RadioChangeEvent } from "antd";
import { useState } from "react";
import { IRadioItem } from "../../../types/common";

interface IProps {
  radios: any;
  valueChecked?: string | number;
  setValueChecked?: (e: RadioChangeEvent) => void;
  label?: string;
}
const RadioUI = (props: IProps) => {
  //! state
  const { radios, valueChecked, setValueChecked, label } = props;

  //! function

  //! render
  const renderRadioItem = () => {
    return radios?.map((item: any, index: any) => {
      return (
        <Radio
          value={item.value}
          key={index}
          title={item.title}
          className="text-white"
        >
          {item.title}
        </Radio>
      );
    });
  };
  return (
    <>
      {label && <p className="mb-1 text-white text-sm">{label}</p>}
      <Radio.Group
        onChange={setValueChecked}
        value={valueChecked}
        className="text-white"
      >
        {renderRadioItem()}
      </Radio.Group>
    </>
  );
};

export default RadioUI;
