import React, { useEffect, useState } from "react";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { useDispatch, useSelector } from "react-redux";
import { changeDataBreadcrumb } from "../../../store/reducers/layoutSlice";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSchemaAsync } from "../../../store/reducers/providerSlice";
import { setLoadingGlobal } from "../../../store/reducers/appCommonSlice";
import { RootState } from "../../../store/store";
import NoData from "../../../components/NoData";
import ListSchemaComp from "../../../components/ManageProvider/ListSchema";
import CommonComponents from "../../../components/Commons";
import ButtonIconComp from "../../../components/MapTasking/components/ButtonIcon";
import CommonIcons from "../../../assets/icons";
import AddSvg from "../../../assets/svg/add-new.svg";

const ListSchemaPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const item = location.state?.item;
  const { idProvider } = useParams();

  const loadingGlobal = useSelector(
    (state: RootState) => state.appCommonSlice.loadingGlobal
  );

  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.allProvider
  );

  const navigate = useNavigate()

  const [data, setData] = useState([]);

  const getData = async () => {
    dispatch(setLoadingGlobal(true));
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getSchemaAsync({ provider_id: item?.id ?? Number(idProvider) })
    ).unwrap();

    setData(res?.data?.data ?? []);

    dispatch(setLoadingGlobal(false));
  };

  useEffect(() => {
    getData();
  }, [idProvider]);

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          title: `Providers ${
            arrAllProviders?.find(
              (itemF: any) => itemF?.id === Number(idProvider)
            )?.name
          }`,
          path: "/provider-branch",
        },
        {
          title: "List schema",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));

      dispatch(resetAllDrawer());
    };
  }, [arrAllProviders]);

  return (
    <ContentLayoutManagement
      header={
        <div className="flex justify-end px-3">
          <CommonComponents.TooltipUI title="Add new" placement="left">
            <ButtonIconComp
              item={{
                description: "Add new schema",
                iconNode: (
                  <div className="text-white">
                    <CommonIcons.PlusIcon />
                  </div>
                ),
              }}
              onClick={() =>
              {
                navigate(`/provider-branch/${Number(idProvider)}/new-schema`);
              }}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded div-one-center"
              style={{ background: "none" }}
            />
          </CommonComponents.TooltipUI>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        {!data?.length && !loadingGlobal && <NoData />}
        {data.length > 0 && <ListSchemaComp data={data} />}
      </div>
    </ContentLayoutManagement>
  );
};

export default ListSchemaPage;
