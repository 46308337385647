import React from "react";

export default function FolderIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.27841 1.5C1.41754 1.5 0.75 2.22204 0.75 3.07625V12.3821C0.75 13.2358 1.41754 13.9583 2.27841 13.9578H8L13.6309 13.958C14.2284 13.958 14.7129 13.4736 14.7129 12.876V8.06807L14.713 4.62704C14.713 3.77392 14.0454 3.05133 13.1846 3.05133H7.91515L6.07794 1.61375C5.98377 1.54003 5.86795 1.50001 5.74874 1.5H2.27841ZM13.6389 7.16016V6.20275H1.82407V12.3821C1.82407 12.6713 2.04426 12.8745 2.27841 12.8745H8.07994L8.08071 12.876H12.6309C13.1831 12.876 13.6309 12.4283 13.6309 11.876V7.16016H13.6389ZM1.82407 3.07571V5.11942H13.6389V4.62704C13.6389 4.33779 13.4187 4.13467 13.1846 4.13467H7.73148C7.61227 4.13465 7.49645 4.09464 7.40228 4.02092L5.56507 2.58333H2.27841C2.04426 2.58333 1.82407 2.78646 1.82407 3.07571Z"
        fill="white"
      />
    </svg>
  );
}
