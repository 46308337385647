import React, { useEffect, useState } from "react";
import Step3 from "../../BookingImage/Step3";
import { Collapse } from "antd";
import { renderInfoOrder } from "../../../pages/order/ManageTaskingOrder/ultis";
import CommonIcons from "../../../assets/icons";
import PolygonInfo from "../../MapTasking/PolygonInfo";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import Step4 from "../../BookingImage/Step4";

const CheckInfoOrderComp = (props: any) => {
  const [polygon, setPolygon] = useState<any>();

  const roleAdmin = CheckRoleAccessControlAdmin();

  useEffect(() => {
    if (props?.data?.id && props?.type === "tasking") {
      setPolygon({
        id: `geometry-taskinig-${props?.data?.id}`,
        geometry: props?.data?.order_details?.[0]?.request_order?.geometry
          ? props?.data?.order_details?.[0]?.request_order?.geometry
          : props?.data?.order_details?.[0]?.request_order?.aoi?.[0]?.geometry
          ? props?.data?.order_details?.[0]?.request_order?.aoi?.[0]?.geometry
          : {},

        type: "Feature",
        name: `geometry-taskinig-${props?.data?.id}`,
      });
    }
  }, [props?.data]);
  const renderOrderArchiveInfo = (provider: any) => {
    switch (provider?.name?.toLowerCase()) {
      case "planet":
        return (
          <Step3
            form={props?.form}
            dataImages={props?.data?.images ?? []}
            initialValues={props?.data?.request_order?.products}
            readOnly
            map={props?.map}
            sourceId={props?.sourceId}
            onClick={(idImage: any) => {
              props?.setAOI((prevAOI: any) => {
                const exists = prevAOI.some(
                  (itemF: any) => itemF.id === idImage
                );
                if (exists) {
                  return prevAOI.filter((itemF: any) => itemF.id !== idImage);
                } else {
                  const newItem = props?.data?.images?.find(
                    (itemF: any) => itemF.id === idImage
                  );
                  return newItem ? [...prevAOI, newItem] : prevAOI;
                }
              });
            }}
          />
        );

      case "oneatlas":
        return (
          <Step4
            form={props?.form}
            dataImages={props?.data?.images}
            total={props?.data?.images?.length}
            dataProvider={provider}
            map={props?.map}
            sourceId={props?.sourceId}
            readOnly
            initialValues={props?.data?.request_order?.optionsPerProductType?.[0]?.options.concat(
              {
                key: "product_type_id",
                value:
                  props?.data?.request_order?.optionsPerProductType?.[0]
                    ?.productTypeId ??
                  props?.data?.request_order?.optionsPerProductType?.[0]
                    ?.product_type_id,
              }
            )}
            onClick={(idImage: any) => {
              props?.setAOI((prevAOI: any) => {
                const exists = prevAOI.some(
                  (itemF: any) => itemF.id === idImage
                );
                if (exists) {
                  return prevAOI.filter((itemF: any) => itemF.id !== idImage);
                } else {
                  const newItem = props?.data?.images?.find(
                    (itemF: any) => itemF.id === idImage
                  );
                  return newItem ? [...prevAOI, newItem] : prevAOI;
                }
              });
            }}
            showCardImages={false}
          />
        );

      default:
        return <p></p>;
    }
  };

  return (
    <div className="w-full" style={{ ...props?.style }}>
      {props?.data?.type.toLowerCase() === "tasking" ? (
        <>
          <div className="div-collapse-pol-point-cus mb-4">
            <Collapse
              bordered={false}
              expandIconPosition="end"
              items={[
                {
                  key: "1",
                  label: "Order's Information",
                  children: renderInfoOrder(props?.data, roleAdmin, "tasking"),
                },
              ]}
              defaultActiveKey={["1"]}
              onChange={() => {}}
              className="!p-0"
              expandIcon={({ isActive }) => (
                <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                  <CommonIcons.ChevronUpIcon />
                </div>
              )}
            />
          </div>
          {polygon?.id && (
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={[
                  {
                    key: "1",
                    label: "Polygon points",
                    children: <PolygonInfo selectedPolygon={polygon} />,
                  },
                ]}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
          )}
        </>
      ) : (
        renderOrderArchiveInfo(props?.data?.provider)
      )}
    </div>
  );
};

export default CheckInfoOrderComp;
