export const dataResolution = [
  {
    title: "Ultra Hight ≤ 15cm per pixel",
    areaSize: [],
    // value: [0, 15],
    value: "0, 15",
  },
  {
    title: "Super Hight > 15cm ≤ 30cm per pixel",
    areaSize: [25, 162.6],
    // value: [16, 30],
    value: "16, 30",
  },
  {
    title: "Very Hight > 30cm < 50cm per pixel",
    areaSize: [25, 2000],
    // value: [31, 50],
    value: "31, 50",
  },
  {
    title: "Hight > 50cm ≤ 1m per pixel",
    areaSize: [25, 500],
    // value: [51, 100],
    value: "51, 100",
  },
  {
    title: "Medium > 1m ≤ 5m per pixel",
    areaSize: [],
    // value: [101, 500],
    value: "101, 500",
  },
];
