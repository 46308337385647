import { ERoleAccessControl } from "../enums";
import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";
import Map from "../pages/maptasking/Explore";
import ManagementOrderPage from "../pages/order/ManagementOrder";

const SwitchLayout = () => {
  const roleAdmin = CheckRoleAccessControlAdmin();
  if (roleAdmin?.includes(ERoleAccessControl.admin))
    return <ManagementOrderPage />;

  return (
    <div>
      <Map />
    </div>
  );
};

export default SwitchLayout;
