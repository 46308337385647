import { Divider } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ERoleAccessControl } from "../../../enums";
import { capitalizeFirstLetter } from "../../../helpers/common";
import { RootState } from "../../../store/store";
import { parseKey } from "../../../components/ManageOrder/AdminOrderToProvider/ultis";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

export const classWrap = `flex p-2 transition-all ease-in-out duration-300 
hover:bg-slate-800/30 rounded-md
hover:shadow-lg hover:shadow-slate-700/20`;

export const classValue = `basis-1/2 text-right line-clamp-3 hover:line-clamp-none 
transition-all ease-in-out duration-300 
max-h-20 hover:max-h-96 
transform `;
export const classLable = `basis-1/2 text-des `;
export const renderInfoOrder = (
  props: any,
  roleAdmin?: any,
  type?: any,
  resultPage?: boolean
) => {
  const renderOptions = () => {
    return props?.options?.map((item: any, index: number) => {
      return (
        <p key={index} hidden={!item?.value} className="flex justify-between ">
          <span className="text-des">{capitalizeFirstLetter(item?.key)}:</span>
          <span className="text-right">
            {item?.value.replace(/^"|"$/g, "")}
          </span>
        </p>
      );
    });
  };

  console.log("object", props);

  return (
    <div className="text-white flex flex-col gap-2 font-normal">
      <p className={classWrap}>
        <span className={classLable}>Order Name</span>
        <span className={classValue}>{props?.name}</span>
      </p>
      {props?.reason && (
        <p className={classWrap}>
          <span className={classLable}>Reason order</span>
          <span className={classValue}>{props?.reason} </span>
        </p>
      )}
      {props?.order_details?.[0]?.actual_price?.total_price !== null &&
        // !!resultPage &&
        type === "tasking" && (
          <p className={classWrap}>
            <span className={classLable}>Total price</span>
            <span
              className={`${classValue}`}
              style={{ color: "rgb(0, 209, 255)" }}
            >
              {props?.order_details?.[0]?.actual_price?.total_price}
              {` ${props?.order_details?.[0]?.actual_price?.unit}`}
            </span>
          </p>
        )}
      {props?.status_histories?.[0]?.note && (
        <p className={classWrap}>
          <span className={classLable}>
            {`Reason ${props?.status?.value?.toLowerCase()}`}
            {/* {roleAdmin?.includes(ERoleAccessControl.admin)
              ? "Reason reject "
              : "Reason cancel "} */}
          </span>
          <span className={classValue}>
            {props?.status_histories?.[0]?.note}
          </span>
        </p>
      )}
      {props?.description && (
        <p className={classWrap}>
          <span className={classLable}>Description</span>
          <span className={classValue}>{props?.description}</span>
        </p>
      )}
      {props?.sensor && (
        <p className={classWrap}>
          <span className={classLable}>Sensor</span>
          <span className={classValue}>{props?.sensor}</span>
        </p>
      )}
      {props?.plan && (
        <p className={classWrap}>
          <span className={classLable}>Plan</span>
          <span className={classValue}>{props?.plan}</span>
        </p>
      )}
      {/* <p className="flex justify-between">
        <span className="text-des">Project:</span>
        <span className="text-right">
          {" "}
          {props?.project?.name ?? props?.project?.id}
        </span>
      </p> */}
      {props?.start_date_request && (
        <p className={classWrap}>
          <span className={classLable}>Start request</span>
          <span className={classValue}> {props?.start_date_request}</span>
        </p>
      )}
      {props?.end_date_request && (
        <p className={classWrap}>
          <span className={classLable}>End request</span>
          <span className={classValue}> {props?.end_date_request}</span>
        </p>
      )}
      {renderOptions()}
      {type === "tasking" &&
        props?.order_details?.[0]?.request_order?.properties &&
        props?.order_details?.[0]?.provider?.name.toLowerCase() ===
          "capella" && (
          <>
            <Divider style={{ background: "#545C77", margin: "10px 0" }} />
            {props?.order_details?.[0]?.order_cost?.total_price && (
              <p className="flex justify-between">
                <span className="text-des">Total price</span>
                <span className="text-right">
                  {" "}
                  {props?.order_details?.[0]?.order_cost?.total_price}
                </span>
              </p>
            )}
            {renderObject(props?.order_details?.[0]?.request_order?.properties)}
          </>
        )}
      {type === "tasking" &&
        props?.order_details?.[0]?.request_order?.optionsPerProductType?.[0]
          ?.options &&
        props?.order_details?.[0]?.provider?.name.toLowerCase() ===
          "oneatlas" && (
          <>
            <Divider style={{ background: "#545C77", margin: "10px 0" }} />
            <p className="flex justify-between p-2">
              <span className="text-des">Mission</span>
              <span className="text-right"> {props?.mission}</span>
            </p>
            {props?.order_details?.[0]?.request_order?.optionsPerProductType?.[0]?.options?.map(
              (itemM: any) => (
                <p className="flex justify-between p-2">
                  <span className="text-des">{parseKey(itemM?.key)}</span>
                  <span className="text-right">
                    {itemM?.value ? itemM?.value?.toString() : "---"}
                  </span>
                </p>
              )
            )}
            {props.order_details?.[0].request_order.items?.[0].properties && (
              <>
                {props.order_details?.[0].request_order.items?.[0].properties?.map(
                  (itemM: any) => {
                    if (
                      itemM?.key === "acquisitionStartDate" ||
                      itemM?.key === "acquisitionEndDate" ||
                      itemM?.key === "orderDeadline"
                    ) {
                      return (
                        <p className="flex justify-between p-2">
                          <span className="text-des">
                            {parseKey(itemM?.key)}
                          </span>
                          <span className="text-right">
                            {moment(itemM?.value).format("HH:mm DD/MM/YYYY")}
                          </span>
                        </p>
                      );
                    } else if (itemM?.key !== "acqPeriod") {
                      return (
                        <p className="flex justify-between p-2">
                          <span className="text-des">
                            {parseKey(itemM?.key)}
                          </span>
                          <span
                            className={`text-right ${
                              typeof itemM?.value === "boolean" &&
                              !itemM?.value &&
                              "text-red-500 flex gap-1"
                            } ${
                              typeof itemM?.value === "boolean" &&
                              itemM?.value &&
                              "text-green-500 flex gap-1"
                            }`}
                          >
                            {itemM?.value === false ? (
                              <CloseCircleOutlined />
                            ) : null}
                            {itemM?.value === true ? (
                              <CheckCircleOutlined />
                            ) : null}
                            {itemM?.value?.toString()}
                          </span>
                        </p>
                      );
                    } else {
                      let from = moment(itemM?.value?.split(" ")?.[0]).format(
                        "HH:mm DD/MM/YYYY"
                      );
                      let to = moment(itemM?.value?.split(" ")?.[1]).format(
                        "HH:mm DD/MM/YYYY"
                      );
                      return (
                        <p className="flex justify-between p-2">
                          <span className="text-des">
                            {parseKey(itemM?.key)}
                          </span>
                          <span className="text-right">
                            {from} - {to}
                          </span>
                        </p>
                      );
                    }
                  }
                )}
              </>
            )}

            {type === "tasking" && props?.order_details?.[0]?.is_submited && (
              <>
                <p className="flex justify-between p-2">
                  <span className="text-des">Delivery type</span>
                  <span className="text-right">
                    {props?.order_details?.[0]?.request_order?.delivery?.type}
                  </span>
                </p>
                <p className="flex justify-between p-2">
                  <span className="text-des">End user name</span>
                  <span className="text-right">
                    {
                      props?.order_details?.[0]?.request_order?.endUsers?.[0]
                        ?.endUserName
                    }
                  </span>
                </p>
                <p className="flex justify-between p-2">
                  <span className="text-des">End user country</span>
                  <span className="text-right">
                    {
                      props?.order_details?.[0]?.request_order?.endUsers?.[0]
                        ?.country
                    }
                  </span>
                </p>
                <p className="flex justify-between p-2">
                  <span className="text-des">Notification</span>
                  <span className="text-right">
                    {
                      props?.order_details?.[0]?.request_order
                        ?.notifications?.[0]?.name
                    }
                  </span>
                </p>
              </>
            )}
          </>
        )}
    </div>
  );
};

export const RenderHeader = (props: any) => {
  // theem check neu admin thi status hien thi khac
  let location = useLocation();
  const arrAllProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  let idProvider =
    props?.data?.provider?.id ?? props?.data?.order_details?.[0]?.provider?.id;

  const colorTagBranch = arrAllProviders
    ?.find((item: any) => item?.id === idProvider)
    ?.metadatas?.find((itemF: any) => itemF?.key === "COLOR_NAME")?.value;

  return (
    <div className="flex justify-between items-center text-center">
      <div className="flex">
        <p style={{ fontSize: 16, fontWeight: 600 }}>
          Status:{" "}
          {props?.data?.type === "TASKING" && (
            <span
              style={{
                color: props?.data?.status
                  ? props?.data?.status?.description
                  : "white",
              }}
            >
              {props?.data?.status ? props?.data?.status?.value : ""}
            </span>
          )}
          {props?.data?.type === "ARCHIVE" && (
            <span
              style={{
                color: props?.data?.status?.description ?? "white",
              }}
            >
              {props?.data?.status.value ?? ""}
            </span>
          )}
        </p>
        {props?.data?.priority?.value && (
          <>
            <span className="text-des mx-2">|</span>
            <span style={{ color: props?.data?.priority?.description }}>
              {props?.data?.priority?.value}
            </span>
          </>
        )}

        {(props?.data?.provider?.name ||
          props?.data?.order_details?.[0]?.provider?.id) &&
          location.pathname.includes("/order/management-tasking") && (
            <>
              <span className="text-des mx-2">|</span>
              <div
                className={` rounded-sm h-[20px]  px-1 py-[2px] flex flex-col justify-center items-center`}
                style={{
                  color: "white",
                  fontSize: "12px",
                  background: colorTagBranch ?? "#004E72",
                }}
              >
                {capitalizeFirstLetter(
                  props?.data?.provider?.name ||
                    props?.data?.order_details?.[0]?.provider?.name
                )}
              </div>
            </>
          )}
      </div>
      {/* {props?.data?.type === "TASKING" &&
        props?.roleAdmin &&
        props?.data?.order_details?.[0]?.is_submited && ( // chuyen ve review thi moi co tien
          <div>
            <p>{props?.data?.order_details?.[0]?.order_cost?.total_price}</p>
          </div>
        )} */}
    </div>
  );
};

export const convertToKeyValueList = (obj: any) => {
  if (obj) {
    const result = [];
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        // Nếu value là một mảng, giữ nguyên
        result.push({ key, value });
      } else if (typeof value === "object" && value !== null) {
        // Nếu value là một object, stringify nó
        result.push({ key, value: JSON.stringify(value) });
      } else {
        // Các giá trị khác (số, chuỗi, boolean, null, undefined)
        result.push({ key, value });
      }
    }
    return result;
  }
  return [];
};

export function convertToObject(keyValueArray: any) {
  return keyValueArray?.reduce((acc: any, item: any) => {
    // Kiểm tra nếu value là chuỗi JSON, parse nó về object
    try {
      acc[item.key] = JSON.parse(item.value);
    } catch (e) {
      acc[item.key] = item.value; // Nếu không phải JSON, giữ nguyên giá trị
    }
    return acc;
  }, {});
}

export const renderObject = (obj: any) => {
  return Object.entries(obj).map(([key, value]: any) => {
    if (typeof value === "object" && !Array.isArray(value)) {
      // Nếu là object, đệ quy render
      return (
        <p className="flex justify-left" key={key}>
          <span className="text-des">{parseKey(key)}:</span>
          <span className="pl-3 w-full">{renderObject(value)}</span>
        </p>
      );
    } else if (Array.isArray(value)) {
      // Nếu là mảng
      return (
        <p className="flex justify-between" key={key}>
          <span className="text-des">{parseKey(key)}:</span>
          <span className="text-right">[{value.join(", ")}]</span>
        </p>
      );
    } else {
      // Nếu là giá trị thường
      return (
        <p className="flex justify-between" key={key}>
          <span className="text-des">{parseKey(key)}:</span>
          <span className="text-right">{value.toString()}</span>
        </p>
      );
    }
  });
};
