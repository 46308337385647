import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTotalPage } from "../../../store/reducers/appCommonSlice";
import { IResponse, IResponsePage } from "../../../types/common";
import { IOrderArchivedUser } from "../interfaces/IOrder.interface";
import orderService from "../order.service";

const useGetOrderAdmin = (enabled = true, filter: any) => {
  const dispatch = useDispatch();
  const [data, setData] =
    useState<IResponse<IResponsePage<IOrderArchivedUser>>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return orderService.GetOrderAdmin({
      page: filter.pageNumber,
      size: filter.pageSize,
      status_id: filter?.status_id,
      sort: filter?.sort,
      start_date: filter?.start_date,
      end_date: filter?.end_date,
      email: filter?.email,
    });
  }, [filter]);

  const transformResponse = useCallback(
    (response: AxiosResponse<IResponse<IResponsePage<IOrderArchivedUser>>>) => {
      if (response) {
        setData(response.data);
        dispatch(setTotalPage(response?.data?.data?.total_page));
      }
    },
    []
  );

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetOrderAdmin;
